import { Mesh, MeshStandardMaterial, Object3D } from 'three';

import { Colors } from './Colors.js';
import { Metals } from './Metals.js';
import { AssetLoader } from './AssetLoader.js';
import { Color, Line, Trim } from './Enums.js';

export class Materials {
  #colors;
  #metals;

  /** @param {AssetLoader} assets */
  constructor(assets) {
    this.#colors = new Colors(assets);
    this.#metals = new Metals(assets);
  }

  /**
   * Set all the trim parts to the selected trim
   * @param {string} trim
   * @param {Object3D[]} trimParts
   */
  changeTrim(trim, trimParts) {
    let trimMaterial;

    switch (trim) {
      case Trim.brass:
        trimMaterial = this.#metals.brassTrim;
        break;
      case Trim.brushedSS:
        trimMaterial = this.#metals.brushedSSTrim;
        break;
      case Trim.chrome:
        trimMaterial = this.#metals.chromeTrim;
        break;
      case Trim.nickel:
        trimMaterial = this.#metals.nickelTrim;
        break;
      default:
        trimMaterial = this.#metals.brassTrim;
        break;
    }

    trimParts.forEach(
      (/** @type {Mesh} */ part) => (part.material = trimMaterial)
    );
  }

  /**
   * Set all the porcelain enamal parts to the selected color
   * @param {string} color
   * @param {Object3D[]} colorParts
   */
  changeColor(color, colorParts) {
    let colorMaterial;

    switch (color) {
      case Color.anthracite:
        colorMaterial = this.#colors.anthracite;
        break;
      case Color.ardoise:
        colorMaterial = this.#colors.ardoise;
        break;
      case Color.armor:
        colorMaterial = this.#colors.armor;
        break;
      case Color.britishRacingGreen:
        colorMaterial = this.#colors.britishRacingGreen;
        break;
      case Color.burgundyRed:
        colorMaterial = this.#colors.burgundyRed;
        break;
      case Color.chantilly:
        colorMaterial = this.#colors.chantilly;
        break;
      case Color.chocolat:
        colorMaterial = this.#colors.chocolat;
        break;
      case Color.coralBlue:
        colorMaterial = this.#colors.coralBlue;
        break;
      case Color.delftBlue:
        colorMaterial = this.#colors.delftBlue;
        break;
      case Color.englishCreme:
        colorMaterial = this.#colors.englishCreme;
        break;
      case Color.faience:
        colorMaterial = this.#colors.faience;
        break;
      case Color.frangipane:
        colorMaterial = this.#colors.frangipane;
        break;
      case Color.frenchBlue:
        colorMaterial = this.#colors.frenchBlue;
        break;
      case Color.griotte:
        colorMaterial = this.#colors.griotte;
        break;
      case Color.ivory:
        colorMaterial = this.#colors.ivory;
        break;
      case Color.mandarine:
        colorMaterial = this.#colors.mandarine;
        break;
      case Color.marronGlace:
        colorMaterial = this.#colors.marronGlace;
        break;
      case Color.matteBlack:
        colorMaterial = this.#colors.matteBlack;
        break;
      case Color.olive:
        colorMaterial = this.#colors.olive;
        break;
      case Color.plum:
        colorMaterial = this.#colors.plum;
        break;
      case Color.portugueseBlue:
        colorMaterial = this.#colors.portugueseBlue;
        break;
      case Color.provenceYellow:
        colorMaterial = this.#colors.provenceYellow;
        break;
      case Color.roseQuartz:
        colorMaterial = this.#colors.roseQuartz;
        break;
      case Color.shinyBlack:
        colorMaterial = this.#colors.shinyBlack;
        break;
      case Color.stainlessSteel:
        colorMaterial = this.#colors.stainlessSteel;
        break;
      case Color.terracotta:
        colorMaterial = this.#colors.terracotta;
        break;
      case Color.tilleul:
        colorMaterial = this.#colors.tilleul;
        break;
      case Color.vertSilice:
        colorMaterial = this.#colors.vertSilice;
        break;
      case Color.vertSologne:
        colorMaterial = this.#colors.vertSologne;
        break;
      case Color.white:
        colorMaterial = this.#colors.white;
        break;
      default:
        colorMaterial = this.#colors.burgundyRed;
        break;
    }

    this.changeMaterial(colorMaterial, colorParts);
  }

  /**
   * Set the selected meshes to the selected material
   * @param {MeshStandardMaterial} material
   * @param {Object3D[]} parts
   */
  changeMaterial(material, parts) {
    parts.forEach((/** @type {Mesh} */ part) => (part.material = material));
  }

  changeKnobDialColor(color, line, dials) {
    let colorMaterial;
    const brownDials = [
      Color.chantilly,
      Color.englishCreme,
      Color.faience,
      Color.ivory,
      Color.roseQuartz,
      Color.tilleul,
      Color.vertSilice,
      Color.white,
    ];

    if (line === Line.moderne) {
      colorMaterial = this.#colors.blackBody;
    } else if (brownDials.includes(color)) {
      colorMaterial = this.#colors.brownDial;
    } else if (color === Color.stainlessSteel) {
      colorMaterial = this.#colors.matteBlack;
    } else {
      colorMaterial = this.#colors.beigeDial;
    }

    dials.forEach((dial) => {
      if (dial.isMesh) dial.material = colorMaterial;
    });
  }

  applyStainlessSteelMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.stainlessSteel));
  }

  applySSRangeTopMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.ssRangeTop));
  }

  applyGalvanizedSteelMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.galvanizedSteel));
  }

  applyBrassMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.brassTrim));
  }

  applyChromeMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.chromeTrim));
  }

  applyBrushedSSMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.brushedSSTrim));
  }

  applyEnamelCastIronMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.enamelCastIron));
  }

  applyMatteBlackMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#colors.matteBlack));
  }

  applyBlackBodyMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#colors.blackBody));
  }

  applyBurgundyRedMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#colors.burgundyRed));
  }

  applyWhiteMaterial(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#colors.white));
  }

  applyBurnerMaterials(burners) {
    burners.forEach((burner) => {
      burner.cap.material = this.#metals.brassCap;
      burner.bowl.material = this.#metals.castAluminum;
      burner.blackRing.material = this.#colors.blackBody;
    });
  }

  applyThermocoupleMaterials(thermocouple) {
    thermocouple.inner.material = this.#metals.brassCap;
    thermocouple.outer.material = this.#colors.blackBody;
  }

  applyIgnitorMaterials(ignitor) {
    ignitor.inner.material = this.#metals.brassCap;
  }

  applyAmberLightMaterial(light) {
    light.material = this.#colors.amberLight;
  }

  applyRedLightMaterial(light) {
    light.material = this.#colors.redLight;
  }

  applyLacancheLogoMaterial(logoPlate) {
    logoPlate.material = this.#metals.logoPlate;
  }
}
