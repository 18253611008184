import { Line, Model } from './Enums';

export class Url {
  #hash;
  #params;
  #url;

  constructor() {
    this.#url = new URL(window.location.href);
    this.#params = new URLSearchParams(this.#url.search);
    this.#hash = this.#url.hash;
  }

  /**
   * Is this a screenshot request?
   * @returns {boolean}
   */
  isScreenshot() {
    return this.#params.get('screenshot') === 'true';
  }

  /**
   * Is this running on the production server?
   * @returns {boolean}
   */
  isProduction() {
    return this.#url.hostname === '3d.frenchranges.com';
  }

  /**
   * The requested range model in the Configurator URL.
   * Check both the query string and the hash.
   * @returns {?string}
   */
  requestedRange() {
    let model;

    if (!this.#hash.startsWith('#model/') && !this.#params.get('model')) {
      return null;
    }

    if (this.#params.get('model')) {
      model = this.#params.get('model');
    } else if (this.#hash.startsWith('#model/')) {
      model = this.#hash.substring(7);
      if (model.includes('-')) model = model.split('-')[0];
    }

    if (!Object.values(Model).includes(model)) return null;

    return model;
  }

  /**
   * Extract the configured range from the configuration slug.
   * @returns {?object}
   */
  configuredRange() {
    // /#summary/cormatin-clas-clas-mb-br-elcon--unsure-36
    if (!this.#hash.startsWith('#summary/')) return null;
    const slug = this.#hash.substring(9);
    const parts = slug.split('-');
    if (parts.length < 9) return null;

    const [
      model,
      line,
      top,
      color,
      trim,
      leftOven,
      rightOven,
      gas,
      height,
      leftCupboard,
      rightCupboard,
      option1,
      option2,
      option3,
      option4,
    ] = parts;

    return {
      model: model || null,
      line: line || null,
      top: top || null,
      color: color || null,
      trim: trim || null,
      leftOven: leftOven || null,
      rightOven: rightOven || null,
      gas: gas || null,
      height: height || null,
      leftCupboard: leftCupboard || null,
      rightCupboard: rightCupboard || null,
      option1: option1 || null,
      option2: option2 || null,
      option3: option3 || null,
      option4: option4 || null,
    };
  }

  /**
   * The requested range line in the Configurator URL.
   * Check both the query string and the hash.
   * @returns {?string}
   */
  requestedLine() {
    let line;

    if (!this.#hash.startsWith('#model/') && !this.#params.get('line')) {
      return null;
    }

    if (this.#params.get('line')) {
      line = this.#params.get('line');
    } else if (this.#hash.startsWith('#model/') && this.#hash.includes('-')) {
      line = this.#hash.split('-')[1];
    }

    if (line === 'moderne') line = 'mod';

    if (!Object.values(Line).includes(line)) return null;

    return line;
  }
}
