import {
  Burner,
  Model,
  OptionSpace,
  Placement,
  RangeTop,
} from '../../shared/Enums';

const threeFeuxClassique = [
  { [Burner.eighteenK]: 1 },
  { [Burner.fiveK]: 1 },
  { [Burner.elevenK]: 1 },
];
const threeFeuxClassiqueRev = [
  { [Burner.fiveK]: 1 },
  { [Burner.elevenK]: 1 },
  { [Burner.eighteenK]: 1 },
];
const threeFeuxTraditional = [
  { [Burner.traditionalSimmerPlate]: 1 },
  { [Burner.fiveK]: 1 },
  { [Burner.elevenK]: 1 },
];
const threeFeuxTraditionalRev = [
  { [Burner.fiveK]: 1 },
  { [Burner.elevenK]: 1 },
  { [Burner.traditionalSimmerPlate]: 1 },
];
const fourFeuxClassique = [
  { [Burner.elevenK]: 2 },
  { [Burner.fiveK]: 1 },
  { [Burner.fifteenK]: 1 },
];
const sixFeuxClassique = [
  { [Burner.elevenK]: 3 },
  { [Burner.fiveK]: 2 },
  { [Burner.fifteenK]: 1 },
];
const fiveFeuxClassique = [
  { [Burner.elevenK]: 2 },
  { [Burner.eighteenK]: 1 },
  { [Burner.fiveK]: 1 },
  { [Burner.fifteenK]: 1 },
];
const fiveFeuxTraditional = [
  { [Burner.elevenK]: 2 },
  { [Burner.traditionalSimmerPlate]: 1 },
  { [Burner.fiveK]: 1 },
  { [Burner.fifteenK]: 1 },
];
const sixFeuxClassiqueRev = [
  { [Burner.elevenK]: 3 },
  { [Burner.fiveK]: 2 },
  { [Burner.fifteenK]: 1 },
];

export const stockBurners = {
  [Model.beaune]: {
    [RangeTop.classique]: sixFeuxClassique,
    [RangeTop.classiqueRev]: sixFeuxClassiqueRev,
  },
  [Model.bussy]: {
    [RangeTop.classique]: sixFeuxClassique,
    [RangeTop.classiqueRev]: sixFeuxClassiqueRev,
  },
  [Model.chagny1400left]: {
    [RangeTop.classique]: fiveFeuxClassique,
    [RangeTop.traditional]: fiveFeuxTraditional,
  },
  [Model.chagny1400right]: {
    [RangeTop.classique]: fiveFeuxClassique,
    [RangeTop.traditional]: fiveFeuxTraditional,
  },
  [Model.chagny1800]: {
    [RangeTop.classique]: fiveFeuxClassique,
    [RangeTop.traditional]: fiveFeuxTraditional,
  },
  [Model.chagny]: {
    [RangeTop.classique]: fiveFeuxClassique,
    [RangeTop.traditional]: fiveFeuxTraditional,
  },
  [Model.chambertin]: {
    [RangeTop.classique]: threeFeuxClassiqueRev,
    [RangeTop.traditional]: threeFeuxTraditionalRev,
    [RangeTop.fourFeux]: fourFeuxClassique,
  },
  [Model.chassagne]: {
    [RangeTop.classique]: threeFeuxClassique,
    [RangeTop.traditional]: threeFeuxTraditional,
    [RangeTop.fourFeux]: fourFeuxClassique,
  },
  [Model.citeaux]: {
    [RangeTop.classique]: threeFeuxClassique,
    [RangeTop.traditional]: threeFeuxTraditional,
    [RangeTop.fourFeux]: fourFeuxClassique,
  },
  [Model.cluny]: {
    [RangeTop.classique]: fiveFeuxClassique,
    [RangeTop.traditional]: fiveFeuxTraditional,
  },
  [Model.cluny1400left]: {
    [RangeTop.classique]: fiveFeuxClassique,
    [RangeTop.traditional]: fiveFeuxTraditional,
  },
  [Model.cluny1400right]: {
    [RangeTop.classique]: fiveFeuxClassique,
    [RangeTop.traditional]: fiveFeuxTraditional,
  },
  [Model.cluny1800]: {
    [RangeTop.classique]: fiveFeuxClassique,
    [RangeTop.traditional]: fiveFeuxTraditional,
  },
  [Model.cormatin]: {
    [RangeTop.classique]: threeFeuxClassique,
    [RangeTop.traditional]: threeFeuxTraditional,
    [RangeTop.fourFeux]: fourFeuxClassique,
  },
  [Model.fontenay]: {
    [RangeTop.classique]: threeFeuxClassique,
    [RangeTop.traditional]: threeFeuxTraditional,
    [RangeTop.fourFeux]: fourFeuxClassique,
  },
  [Model.rully]: {
    [RangeTop.classique]: threeFeuxClassique,
    [RangeTop.traditional]: threeFeuxTraditional,
    [RangeTop.fourFeux]: fourFeuxClassique,
  },
  [Model.savigny]: {
    [RangeTop.classique]: threeFeuxClassiqueRev,
    [RangeTop.traditional]: threeFeuxTraditionalRev,
    [RangeTop.fourFeux]: fourFeuxClassique,
  },
  [Model.saulieu]: {
    [RangeTop.classique]: threeFeuxClassique,
    [RangeTop.traditional]: threeFeuxTraditional,
    [RangeTop.fourFeux]: fourFeuxClassique,
  },
  [Model.sully]: {
    [RangeTop.classique]: threeFeuxClassique,
    [RangeTop.traditional]: threeFeuxTraditional,
    [RangeTop.fourFeux]: fourFeuxClassique,
  },
  [Model.sully1800left]: {
    [RangeTop.classique]: threeFeuxClassique,
    [RangeTop.traditional]: threeFeuxTraditional,
    [RangeTop.fourFeux]: fourFeuxClassique,
  },
  [Model.sully1800right]: {
    [RangeTop.classique]: threeFeuxClassique,
    [RangeTop.traditional]: threeFeuxTraditional,
    [RangeTop.fourFeux]: fourFeuxClassique,
  },
  [Model.sully2200]: {
    [RangeTop.classique]: threeFeuxClassique,
    [RangeTop.traditional]: threeFeuxTraditional,
    [RangeTop.fourFeux]: fourFeuxClassique,
  },
  [Model.volnay]: {
    [RangeTop.classique]: fiveFeuxClassique,
    [RangeTop.traditional]: fiveFeuxTraditional,
  },
  [Model.vougeot]: {
    [RangeTop.classique]: fiveFeuxClassique,
    [RangeTop.traditional]: fiveFeuxTraditional,
  },
};

export const optionPlacements = {
  [Model.beaune]: {},
  [Model.bussy]: {},
  [Model.chagny]: {},
  [Model.chagny1400left]: {
    [Placement.l1]: OptionSpace.option1,
  },
  [Model.chagny1400right]: {
    [Placement.r1]: OptionSpace.option1,
  },
  [Model.chagny1800]: {
    [Placement.l1]: OptionSpace.option1,
    [Placement.r1]: OptionSpace.option2,
  },
  [Model.chambertin]: {
    [Placement.r1]: OptionSpace.option1,
  },
  [Model.chassagne]: {
    [Placement.l1]: OptionSpace.option1,
  },
  [Model.citeaux]: {
    [Placement.l1]: OptionSpace.option1,
    [Placement.r1]: OptionSpace.option2,
  },
  [Model.cluny]: {},
  [Model.cluny1400left]: {
    [Placement.l1]: OptionSpace.option1,
  },
  [Model.cluny1400right]: {
    [Placement.r1]: OptionSpace.option1,
  },
  [Model.cluny1800]: {
    [Placement.l1]: OptionSpace.option1,
    [Placement.r1]: OptionSpace.option2,
  },
  [Model.cormatin]: {},
  [Model.fontenay]: {
    [Placement.l1]: OptionSpace.option1,
    [Placement.r1]: OptionSpace.option2,
  },
  [Model.rully]: {},
  [Model.saulieu]: {
    [Placement.l1]: OptionSpace.option1,
  },
  [Model.savigny]: {
    [Placement.r1]: OptionSpace.option1,
  },
  [Model.sully]: {
    [Placement.l1]: OptionSpace.option1,
    [Placement.r1]: OptionSpace.option2,
  },
  [Model.sully1800left]: {
    [Placement.l1]: OptionSpace.option1,
    [Placement.l2]: OptionSpace.option2,
    [Placement.r1]: OptionSpace.option3,
  },
  [Model.sully1800right]: {
    [Placement.l1]: OptionSpace.option1,
    [Placement.r1]: OptionSpace.option2,
    [Placement.r2]: OptionSpace.option3,
  },
  [Model.sully2200]: {
    [Placement.l1]: OptionSpace.option1,
    [Placement.l2]: OptionSpace.option2,
    [Placement.r1]: OptionSpace.option3,
    [Placement.r2]: OptionSpace.option4,
  },
  [Model.volnay]: {},
  [Model.vougeot]: {},
};
