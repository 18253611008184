import { Object3D } from 'three';
import { AssetLoader } from '../shared/AssetLoader';
import { Materials } from '../shared/Materials';
import { LacancheLogo } from '../shared/SharedParts';
import { BaseHood } from './BaseHood';
import { MeshName } from '../shared/Enums';

export class Hood1300 extends BaseHood {
  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   * @param {LacancheLogo} sharedParts
   * @param {string} color
   */
  constructor(assets, materials, sharedParts, color) {
    super(assets, materials, sharedParts);
    this.bodyColor = color;
    this.hood.name = 'hood-1300';
  }

  async init() {
    await this.loadModels(this.assets.hood1300Data);
    this.applyMaterials();

    this.#addMAVBaffles();
    this.#addMAVLights();
    this.#addMAVKnob();

    this.addVAHLights(0.28);

    this.hideHighDuctCovers();
    this.changeColor(this.bodyColor);
  }

  #addMAVBaffles() {
    // Add baffle plates left to right
    const mavBaffle = this.mavInsert.getObjectByName(MeshName.mavBaffleFilter);
    mavBaffle.translateX(-0.254);

    for (let i = 1; i <= 2; i++) {
      const baffle = mavBaffle.clone();
      baffle.translateX(i * 0.254375);
      this.mavInsert.add(baffle);
    }
  }

  #addMAVLights() {
    const offsetX = 0.284;

    // Add lights from left to right
    const mavLight = this.mavInsert.getObjectByName(MeshName.mavLedLens);
    mavLight.translateX(-offsetX).translateZ(-0.002);

    for (let i = 1; i <= 2; i++) {
      const light = mavLight.clone();
      light.translateX(i * offsetX);
      this.mavInsert.add(light);
    }
  }

  #addMAVKnob() {
    const mavKnob = this.mavInsert.getObjectByName(MeshName.mavKnob);
    mavKnob.position.z += -0.002;

    const rightKnob = mavKnob.clone();
    rightKnob.translateX(0.24);

    this.mavInsert.add(rightKnob);
  }
}
