import { Group } from 'three';

import { BaseKnobs } from '../shared/BaseKnobs';
import { KnobModels } from '../shared/KnobModels';

import { Burner, Oven, Position, Region } from '../../shared/Enums';
import { Materials } from '../../shared/Materials';
import { State } from '../../shared/State';

export class Knobs extends BaseKnobs {
  /**
   * The knobs that selected but not necessarily displayed (yet) on the range
   * @type {Object.<string, Object.<string, Object.<string, ?string>>>}
   */
  selectedKnobs = {
    left: {
      baseLeftBurnerFront: {
        type: Burner.gasBurner,
        position: Position.leftFront,
      },
      baseLeftBurnerBack: {
        type: Burner.gasBurner,
        position: Position.leftBack,
      },
      baseCenterBurner: {
        type: Burner.gasBurner,
        position: Position.right,
      },
      baseRightBurnerBack: {
        type: null,
        position: Position.rightBack,
      },
      baseRightBurnerFront: {
        type: null,
        position: Position.rightFront,
      },
      oven: {
        type: Oven.convection,
        position: null,
      },
    },
    farRight: {
      optionBack: {
        type: null,
        position: Position.back,
      },
      optionFront: {
        type: null,
        position: Position.front,
      },
      optionCenter: {
        type: null,
        position: Position.back,
      },
      cupboard: {
        type: Oven.rightVertConv,
        position: null,
      },
    },
  };

  /**
   * @param {KnobModels} knobModels
   * @param {Group} range
   * @param {State} state
   * @param {Materials} materials
   */
  constructor(knobModels, range, state, materials) {
    super(knobModels, range, state, materials);

    this.updateLeftKnobs();
    this.updateFarRightKnobs();
  }

  threeFeuxBaseTop() {
    this.selectedKnobs.left.baseLeftBurnerFront.type = Burner.gasBurner;
    this.selectedKnobs.left.baseLeftBurnerBack.type = Burner.gasBurner;
    this.selectedKnobs.left.baseCenterBurner.type = Burner.gasBurner;
    this.selectedKnobs.left.baseRightBurnerBack.type = null;
    this.selectedKnobs.left.baseRightBurnerFront.type = null;

    this.updateLeftKnobs();
  }

  fourFeuxBaseTop() {
    this.selectedKnobs.left.baseLeftBurnerFront.type = Burner.gasBurner;
    this.selectedKnobs.left.baseLeftBurnerBack.type = Burner.gasBurner;
    this.selectedKnobs.left.baseCenterBurner.type = null;
    this.selectedKnobs.left.baseRightBurnerBack.type = Burner.gasBurner;
    this.selectedKnobs.left.baseRightBurnerFront.type = Burner.gasBurner;

    this.updateLeftKnobs();
  }

  /**
   * Change the main oven to gas or electric convection
   * @param {string} ovenType
   */
  changeLeftOven(ovenType) {
    this.selectedKnobs.left.oven.type = ovenType;
    this.updateLeftKnobs();
  }

  /** Update the knobs on the range's left */
  updateLeftKnobs() {
    this.updateKnobRegion(Region.left, {
      maxKnobs: 5,
      offset: 0.54,
      gap: 0.11,
    });
  }

  /** Update the knobs on the range's right */
  updateFarRightKnobs() {
    this.updateKnobRegion(Region.farRight, {
      maxKnobs: 3,
      offset: 1.2,
      gap: 0.1,
    });
  }
}
