import { Group, Object3D } from 'three';
import * as common from './common.js';
import { MeshName } from '../../shared/Enums';

/**
 * Various external (common) parts of the Cluny 1400 (left) range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
export function parts(meshes) {
  // Cupboard Door
  const cupboardDoor = meshes.getObjectByName(MeshName.cupboardDoor).clone();
  cupboardDoor.name = MeshName.cupboardDoor;
  cupboardDoor.position.x = -0.502;

  // Cupboard Vent Cover
  const cupboardVentCover = meshes
    .getObjectByName(MeshName.cupboardVentCoverGroup)
    .clone();
  cupboardVentCover.translateX(0.402);

  // Cooktop Hinge Plates
  const leftCooktopHingePlate = meshes
    .getObjectByName(MeshName.leftCooktopHingePlate)
    .clone();
  leftCooktopHingePlate.translateX(-0.2);

  const rightCooktopHingePlate = meshes
    .getObjectByName(MeshName.rightCooktopHingePlate)
    .clone();
  rightCooktopHingePlate.translateX(0.2);

  return [
    cupboardDoor,
    cupboardVentCover,
    ...common.clunyOvenDoorsAndDrawers(meshes, 0.2),
    leftCooktopHingePlate,
    rightCooktopHingePlate,
  ];
}

/**
 * All the classique trim parts for a Cluny 1400 (left) range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName(MeshName.towelBarleftSupport)
    .clone();
  towelBarSupportLeft.position.x = -0.665;

  const towelBarSupportMidLeft = meshes
    .getObjectByName(MeshName.towelBarMidLeftSupport)
    .clone();
  towelBarSupportMidLeft.position.x = -0.28;

  const towelBarSupportRight = meshes
    .getObjectByName(MeshName.towelBarRightSupport)
    .clone();
  towelBarSupportRight.position.x = 0.665;

  // Cupboard Door Bar Supports
  const cupboardDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  cupboardDoorBarSupportLeft.name = MeshName.cupboardBarLeftSupport;
  cupboardDoorBarSupportLeft.position.x = -0.614;
  const cupboardDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  cupboardDoorBarSupportRight.name = MeshName.cupboardBarRightSupport;
  cupboardDoorBarSupportRight.position.x = -0.39;

  // Cupboard Door Bar
  const cupboardDoorBar = meshes
    .getObjectByName(MeshName.classiqueCupboardDoorBar)
    .clone();
  cupboardDoorBar.position.x = -0.501;

  return trim.add(
    towelBarSupportLeft,
    towelBarSupportMidLeft,
    towelBarSupportRight,
    cupboardDoorBarSupportLeft,
    cupboardDoorBarSupportRight,
    cupboardDoorBar,
    ...common.clunyOvenClassiqueTrim(meshes, 0.2)
  );
}

/**
 * All the moderne trim parts for a Cluny 1400 (left) range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
export function moderneTrim(meshes) {
  const trim = new Group();

  // Cupboard Door Bar
  const cupboardDoorBar = meshes
    .getObjectByName(MeshName.moderneCupboardDoorBar)
    .clone();
  cupboardDoorBar.position.x = -0.502;

  // Left Cupboard Bar Supports
  const cupboardDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  cupboardDoorBarSupportLeft.position.x = -0.62;
  const cupboardDoorBarSupportRight = cupboardDoorBarSupportLeft.clone();
  cupboardDoorBarSupportRight.position.x = -0.39;

  return trim.add(
    cupboardDoorBar,
    cupboardDoorBarSupportLeft,
    cupboardDoorBarSupportRight,
    ...common.clunyOvenModerneTrim(meshes, 0.2)
  );
}
