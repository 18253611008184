import { Color, Scene } from 'three';
import { Url } from '../shared/Url';

/**
 * @param {Url} url
 * @returns {Scene}
 */
export function createScene(url) {
  const scene = new Scene();

  if (url.isScreenshot()) {
    scene.background = new Color('rgb(255, 255, 255)');
  } else {
    scene.background = new Color('rgb(255, 250, 241)');
  }

  return scene;
}
