import { Camera } from 'three';
import {
  Burner,
  Color,
  Cupboard,
  Gas,
  Height,
  Line,
  Model,
  Oven,
  RangeTop,
  Spacer,
  Trim,
} from './Enums.js';

/**
 * The configured state of the Lacanche range. This includes the chosen line,
 * range top, trim, color, and optional burners
 */
export class State {
  // This is the initial state of the Lacanche range

  /**
   * The line of the range (classique or moderne)
   * @type {string}
   */
  model = Model.sully;

  /**
   * The line of the range (classique or moderne)
   * @type {string}
   */
  line = Line.classique;

  /**
   * The style of the range top (classique, traditional, or 4-feux)
   * @type {string}
   */
  top = RangeTop.classique;

  /**
   * The trim of the range (brass, brushedSS, chrome, or nickel)
   * @type {string}
   */
  trim = Trim.brass;

  /**
   * The color of the range (too many to list)
   * @type {string}
   */
  color = Color.burgundyRed;

  /**
   * The type of left oven (gas, electric, or electric convection)
   * @type {?string}
   */
  leftOven = Oven.gas;

  /**
   * The type of left oven (gas, electric, or electric convection)
   * @type {?string}
   */
  rightOven = Oven.convection;

  /**
   * The size of small broiling oven (grande or petite)
   * @type {?string}
   */
  petiteOven = null;

  /**
   * The type of left cupboard (warming or storage)
   * @type {?string}
   */
  leftCupboard = null;

  /**
   * The type of right cupboard (warming or storage)
   * @type {?string}
   */
  rightCupboard = null;

  /**
   * The position of the back spacer (raised or flush)
   * @type {string}
   */
  spacer = Spacer.raised;

  /**
   * The number of storage drawers (0, 1, or 2)
   * @type {number}
   */
  storageDrawers = 2;

  /**
   * The first (leftmost) optional "burner" for the range top
   * @type {?string}
   */
  option1 = null;

  /**
   * The second optional "burner" for the range top
   * @type {?string}
   */
  option2 = null;

  /**
   * The third optional "burner" for the range top (only available on Sully 1800 and 2200)
   * @type {?string}
   */
  option3 = null;

  /**
   * The fourth optional "burner" for the range top (only available on Sully 2200)
   * @type {?string}
   */
  option4 = null;

  /**
   * The gas type of the range
   * @type {string}
   */
  gas = Gas.notSure;

  /**
   * The height of the range
   * @type {string}
   */
  height = Height.notSure;

  /**
   * Is this a range created from a configuration URL?
   * @type {boolean}
   */
  isConfigured = false;

  /**
   * The camera used to view the scene
   * @type {Camera}
   */
  camera;

  /**
   * Set up the state of the range from a previously configured range
   *
   * @param {object} configuredRange
   * @param {string} configuredRange.model
   * @param {string} configuredRange.line
   * @param {string} configuredRange.top
   * @param {string} configuredRange.trim
   * @param {string} configuredRange.color
   * @param {string} configuredRange.leftOven
   * @param {string} configuredRange.rightOven
   * @param {string} configuredRange.gas
   * @param {string} configuredRange.height
   * @param {string} configuredRange.leftCupboard
   * @param {string} configuredRange.rightCupboard
   * @param {string} configuredRange.option1
   * @param {string} configuredRange.option2
   * @param {string} configuredRange.option3
   * @param {string} configuredRange.option4
   */
  setState(configuredRange) {
    this.isConfigured = true;

    if (Object.values(Model).includes(configuredRange.model)) {
      this.model = configuredRange.model;
    } else {
      this.model = Model.sully;
      return;
    }

    if (Object.values(Line).includes(configuredRange.line)) {
      this.line = configuredRange.line;
    }

    if (Object.values(RangeTop).includes(configuredRange.top)) {
      this.top = configuredRange.top;
    }

    if (Object.values(Trim).includes(configuredRange.trim)) {
      this.trim = configuredRange.trim;
    }

    if (Object.values(Color).includes(configuredRange.color)) {
      this.color = configuredRange.color;
    }

    if (Object.values(Oven).includes(configuredRange.leftOven)) {
      this.leftOven = configuredRange.leftOven;
    }

    if (Object.values(Oven).includes(configuredRange.rightOven)) {
      this.rightOven = configuredRange.rightOven;
    }

    if (Object.values(Gas).includes(configuredRange.gas)) {
      this.gas = configuredRange.gas;
    }

    if (Object.values(Height).includes(configuredRange.height)) {
      this.height = configuredRange.height;
    }

    if (Object.values(Cupboard).includes(configuredRange.leftCupboard)) {
      this.leftCupboard = configuredRange.leftCupboard;
    }

    if (Object.values(Cupboard).includes(configuredRange.rightCupboard)) {
      this.rightCupboard = configuredRange.rightCupboard;
    }

    if (Object.values(Burner).includes(configuredRange.option1)) {
      this.option1 = configuredRange.option1;
    }

    if (Object.values(Burner).includes(configuredRange.option2)) {
      this.option2 = configuredRange.option2;
    }

    if (Object.values(Burner).includes(configuredRange.option3)) {
      this.option3 = configuredRange.option3;
    }

    if (Object.values(Burner).includes(configuredRange.option4)) {
      this.option4 = configuredRange.option4;
    }
  }
}
