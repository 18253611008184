import { EquirectangularReflectionMapping, Scene } from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

// @ts-ignore
import hdrFile from '/assets/envmap/art_studio_1k.hdr?url';
// import hdrFile from '/assets/envmap/modern_bathroom_1k.hdr?url';
// import hdrFile from '/assets/envmap/MR_INT-001_NaturalStudio_NAD.hdr?url';

/**
 * Load the environment map which provides most of the lighting in the scene.
 * @param {Scene} scene
 */
export function loadEnvMap(scene, callback) {
  new RGBELoader().load(hdrFile, function (texture) {
    texture.mapping = EquirectangularReflectionMapping;
    scene.environment = texture;
    scene.environmentIntensity = 0.8;
    scene.environmentRotation.set(0, 0, 0);
    callback();
  });
}
