import { Group, Object3D } from 'three';

import { Knobs } from './Knobs.js';

import { RangeParts } from '../parts/RangeParts.js';
import { BaseRange } from '../shared/BaseRange.js';
import { KnobModels } from '../shared/KnobModels.js';
import { RangeTopOptions } from '../shared/RangeTopOptions.js';

import { AssetLoader } from '../../shared/AssetLoader.js';
import { Materials } from '../../shared/Materials.js';
import { LacancheLogo } from '../../shared/SharedParts.js';
import { State } from '../../shared/State.js';
import {
  LabelPosition,
  LabelWidth,
  Line,
  MeshName,
  Oven,
  RangeTop,
} from '../../shared/Enums.js';

export class Cormatin extends BaseRange {
  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   * @param {RangeParts} rangeParts
   * @param {LacancheLogo} sharedParts
   * @param {RangeTopOptions} rangeTopOptions
   * @param {KnobModels} knobModels
   * @param {State} state
   */
  constructor(
    assets,
    materials,
    rangeParts,
    sharedParts,
    rangeTopOptions,
    knobModels,
    state
  ) {
    super(assets, materials, rangeParts, rangeTopOptions, state);

    this.state.leftCupboard = null;
    if (!this.state.isConfigured) {
      this.state.leftOven = Oven.convection;
    }
    this.state.rightOven = null;
    this.state.rightCupboard = null;
    this.state.option1 = null;
    this.state.option2 = null;
    this.state.option3 = null;
    this.state.option4 = null;
    this.state.storageDrawers = 1;

    this.classiqueTrim = rangeParts.cormatinClassiqueTrim();
    this.moderneTrim = rangeParts.cormatinModerneTrim();
    this.knobs = new Knobs(knobModels, this.range, this.state, this.materials);

    const logo = this.#createLogo(sharedParts);

    this.range.add(this.baseBurners, logo);

    this.#setUIFeatures();
    this.#setLabelPositions();
    this.addRangeLabels();
  }

  /**
   * Load the 3D models and apply materials and shadows to them
   */
  async init() {
    await this.#loadModels();
    this.applyMaterials();
    this.setupShadows();
  }

  /**
   * Change the range top style to classique, traditional, or four-feux
   * @param {string} rangeTop
   * @param {boolean} [assembleTop] Ignored parameter (used for other ranges)
   */
  changeRangeTop(rangeTop, assembleTop = true) {
    this.baseBurners.clear();

    switch (rangeTop) {
      case RangeTop.classique: {
        this.add18KBurner(-0.3015, this.baseBurners);
        this.add11And5KBurners(0.054, this.baseBurners);
        this.addGrateLeftSpacerTabs(0.054, this.baseBurners);
        this.knobs.threeFeuxBaseTop();
        break;
      }

      case RangeTop.traditional: {
        this.addTradPlate(-0.298, this.baseBurners);
        this.add11And5KBurners(0.054, this.baseBurners);
        this.addGrateLeftSpacerTabs(0.054, this.baseBurners);
        this.knobs.threeFeuxBaseTop();
        break;
      }

      case RangeTop.fourFeux:
        this.add11KBurners(-0.363, this.baseBurners);
        this.add15And5KBurners(0.053, this.baseBurners);
        this.add4FeuxSpacerGrate(0.0015, this.baseBurners);
        this.knobs.fourFeuxBaseTop();
        break;
    }
  }

  /**
   * All the trim parts of the range that can be brass, brushed stainless steel, chrome, or nickel
   * @returns {Object3D[]}
   */
  trimParts() {
    return [
      this.classiqueTrim.getObjectByName(MeshName.towelBarleftSupport),
      this.classiqueTrim.getObjectByName(MeshName.towelBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.ovenDoorBarLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.ovenDoorBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.drawerLeftPull),
      this.classiqueTrim.getObjectByName(MeshName.drawerRightPull),
      this.range.getObjectByName(MeshName.logoBorder),
    ];
  }

  /**
   * All the parts of the range that are porcelain enamel
   * @returns {Object3D[]}
   */
  colorParts() {
    return [
      this.range.getObjectByName(MeshName.ovenDoor),
      this.range.getObjectByName(MeshName.storageDrawer),
    ];
  }

  /**
   * All the parts of the range that are stainless steel
   * @returns {Object3D[]}
   */
  stainlessSteelParts() {
    return [
      this.range.getObjectByName(MeshName.rangeTop700),
      this.range.getObjectByName(MeshName.backSpacer),
      this.range.getObjectByName(MeshName.toeKick),
      this.range.getObjectByName(MeshName.rangeBase),

      this.classiqueTrim.getObjectByName(MeshName.towelBar),
      this.classiqueTrim.getObjectByName(MeshName.classiqueAerationVent),

      this.moderneTrim.getObjectByName(MeshName.moderneAerationVent),
    ];
  }

  /**
   * All the parts of the range that are galvanized steel
   * @returns {Object3D[]}
   */
  galvanizedSteelParts() {
    return [
      this.range.getObjectByName(MeshName.rangeBackPanel),
      // this.range.getObjectByName(MeshName.electricOvenBackPanel),
    ];
  }

  /**
   * All the parts of the range that receive shadows
   * @returns {Object3D[]}
   */
  shadowCasters() {
    return [this.classiqueTrim.getObjectByName(MeshName.towelBar)];
  }

  /**
   * All the parts of the range that receive shadows
   * @returns {Object3D[]}
   */
  shadowReceivers() {
    return [
      this.range.getObjectByName(MeshName.controlPanel),
      this.range.getObjectByName(MeshName.rangeTop700),
    ];
  }

  /** Add all the Cormatin meshes to the range Group  */
  async #loadModels() {
    const [range700Data, cormatinData] = await Promise.all([
      this.assets.range700Data,
      this.assets.cormatinData,
    ]);

    range700Data.scene.children.forEach((child) => {
      if (this.#classiqueMeshNames().includes(child.name)) {
        this.classiqueTrim.add(child.clone());
      } else if (this.#moderneMeshNames().includes(child.name)) {
        this.moderneTrim.add(child.clone());
      } else {
        this.range.add(child.clone());
      }
    });

    cormatinData.scene.children.forEach((child) => {
      this.range.add(child.clone());
    });

    this.range.add(this.classiqueTrim);
    this.range.add(...this.rangeParts.cormatinParts());
  }

  /**
   * Create the Lacanche logo to show on the oven door
   * @param {LacancheLogo} sharedParts
   * @returns {Group}
   */
  #createLogo(sharedParts) {
    const logo = sharedParts.logo.clone();
    logo.position.x = -0.35;

    return logo;
  }

  /**
   * 3D meshes in the base range that are only part of the classique line
   * @returns {string[]}
   */
  #classiqueMeshNames() {
    return [
      MeshName.classiqueAerationVent,
      MeshName.aerationVentBlack,
      MeshName.towelBar,
    ];
  }

  /**
   * 3D meshes in the base range that are only part of the moderne line
   * @returns {string[]}
   */
  #moderneMeshNames() {
    return [MeshName.moderneAerationVent];
  }

  /** Set up the user interface to show all the range's configurable features */
  #setUIFeatures() {
    this.features.convection = true;
    this.features.fourFeux = true;
  }

  #setLabelPositions() {
    this.labels.config.leftOven = {
      position: [0, 0.56, LabelPosition.frontZPos],
      width: LabelWidth.sullyOven,
    };
    this.labels.config.leftStorageDrawer = {
      position: [0, LabelPosition.storeDrawerYPos, LabelPosition.frontZPos],
      width: LabelWidth.sullyOven,
    };
  }
}
