import { Group, Object3D } from 'three';

import { Knobs } from './Knobs.js';

import { RangeParts } from '../parts/RangeParts.js';
import { BaseRange } from '../shared/BaseRange.js';
import { KnobModels } from '../shared/KnobModels.js';
import { RangeTopOptions } from '../shared/RangeTopOptions.js';

import { AssetLoader } from '../../shared/AssetLoader.js';
import { Materials } from '../../shared/Materials.js';
import { LacancheLogo } from '../../shared/SharedParts.js';
import { State } from '../../shared/State.js';
import {
  Cupboard,
  LabelPosition,
  LabelWidth,
  MeshName,
  Oven,
  RangeTop,
} from '../../shared/Enums.js';

export class Volnay extends BaseRange {
  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   * @param {RangeParts} rangeParts
   * @param {LacancheLogo} sharedParts
   * @param {RangeTopOptions} rangeTopOptions
   * @param {KnobModels} knobModels
   * @param {State} state
   */
  constructor(
    assets,
    materials,
    rangeParts,
    sharedParts,
    rangeTopOptions,
    knobModels,
    state
  ) {
    super(assets, materials, rangeParts, rangeTopOptions, state);

    this.state.leftCupboard = Cupboard.warming;
    if (!this.state.isConfigured) {
      this.state.leftOven = Oven.convection;
    }
    this.state.rightOven = null;
    this.state.rightCupboard = null;
    this.state.option1 = null;
    this.state.option2 = null;
    this.state.option3 = null;
    this.state.option4 = null;
    this.state.storageDrawers = 1;
    if (![RangeTop.classique, RangeTop.traditional].includes(this.state.top)) {
      this.state.top = RangeTop.classique;
    }

    this.classiqueTrim = rangeParts.volnayClassiqueTrim();
    this.moderneTrim = rangeParts.volnayModerneTrim();
    this.knobs = new Knobs(knobModels, this.range, this.state, this.materials);

    const logo = this.#createLogo(sharedParts);

    this.range.add(this.baseBurners, logo);

    this.#loadFeatures();
    this.#setLabelPositions();
    this.addRangeLabels();
  }

  /**
   * Load the 3D models and apply materials and shadows to them
   */
  async init() {
    await this.#loadModels();
    this.applyMaterials();
    this.setupShadows();
  }

  /**
   * Change the range top style to classique or traditional
   * @param {string} rangeTop
   * @param {boolean} [assembleTop] Ignored parameter (used for other ranges)
   */
  changeRangeTop(rangeTop, assembleTop = true) {
    this.baseBurners.clear();

    this.add11KBurners(-0.508, this.baseBurners);
    this.addGrateRightSpacerTabs(-0.508, this.baseBurners);

    this.add15And5KBurners(0.199, this.baseBurners);
    this.addGrateLeftSpacerTabs(0.199, this.baseBurners);

    switch (rangeTop) {
      case RangeTop.classique: {
        this.add18KBurner(-0.1565, this.baseBurners);
        break;
      }
      case RangeTop.traditional: {
        this.addTradPlate(-0.155, this.baseBurners);
        break;
      }
    }
  }

  /**
   * All the trim parts of the range that can be brass, brushed stainless steel, chrome, or nickel
   * @returns {Object3D[]}
   */
  trimParts() {
    return [
      this.classiqueTrim.getObjectByName(MeshName.towelBarleftSupport),
      this.classiqueTrim.getObjectByName(MeshName.towelBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.cupboardBarLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.cupboardBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.ovenDoorBarLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.ovenDoorBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.drawerLeftPull),
      this.classiqueTrim.getObjectByName(MeshName.drawerRightPull),
      this.range.getObjectByName(MeshName.logoBorder),
    ];
  }

  /**
   * All the parts of the range that are porcelain enamel
   * @returns {Object3D[]}
   */
  colorParts() {
    return [
      this.range.getObjectByName(MeshName.cupboardDoor),
      this.range.getObjectByName(MeshName.ovenDoor),
      this.range.getObjectByName(MeshName.storageDrawer),
    ];
  }

  /**
   * All the parts of the range that are stainless steel
   * @returns {Object3D[]}
   */
  stainlessSteelParts() {
    return [
      this.range.getObjectByName(MeshName.rangeTop1000),
      this.range.getObjectByName(MeshName.backSpacer),
      this.range.getObjectByName(MeshName.toeKick),
      this.range.getObjectByName(MeshName.rangeBase),

      this.classiqueTrim.getObjectByName(MeshName.towelBar),
      this.classiqueTrim.getObjectByName(MeshName.classiqueAerationVent),

      this.moderneTrim.getObjectByName(MeshName.moderneAerationVent),
    ];
  }

  /**
   * All the parts of the range that are galvanized steel
   * @returns {Object3D[]}
   */
  galvanizedSteelParts() {
    return [this.range.getObjectByName(MeshName.rangeBackPanel)];
  }

  /**
   * All the parts of the range that receive shadows
   * @returns {Object3D[]}
   */
  shadowCasters() {
    return [this.classiqueTrim.getObjectByName(MeshName.towelBar)];
  }

  /**
   * All the parts of the range that receive shadows
   * @returns {Object3D[]}
   */
  shadowReceivers() {
    return [
      this.range.getObjectByName(MeshName.controlPanel),
      this.range.getObjectByName(MeshName.rangeTop1000),
    ];
  }

  /** Add all the Volnay meshes to the range Group  */
  async #loadModels() {
    const [range1000Data, volnayData] = await Promise.all([
      this.assets.range1000Data,
      this.assets.volnayData,
    ]);

    range1000Data.scene.children.forEach((child) => {
      if (this.#classiqueMeshNames().includes(child.name)) {
        this.classiqueTrim.add(child.clone());
      } else if (this.#moderneMeshNames().includes(child.name)) {
        this.moderneTrim.add(child.clone());
      } else {
        this.range.add(child.clone());
      }
    });

    volnayData.scene.children.forEach((child) => {
      this.range.add(child.clone());
    });

    this.range.add(this.classiqueTrim);
    this.range.add(...this.rangeParts.volnayParts());
  }

  /**
   * Create the Lacanche logo to show on the oven door
   * @param {LacancheLogo} sharedParts
   * @returns {Group}
   */
  #createLogo(sharedParts) {
    const logo = sharedParts.logo.clone();
    logo.translateX(-0.18);

    return logo;
  }

  /**
   * 3D meshes in the base range that are only part of the classique line
   * @returns {string[]}
   */
  #classiqueMeshNames() {
    return [
      MeshName.classiqueAerationVent,
      MeshName.aerationVentBlack,
      MeshName.towelBar,
    ];
  }

  /**
   * 3D meshes in the base range that are only part of the moderne line
   * @returns {string[]}
   */
  #moderneMeshNames() {
    return [MeshName.moderneAerationVent];
  }

  /** Set up the user interface to show all the range's configurable features */
  #loadFeatures() {
    this.features.convection = true;
  }

  #setLabelPositions() {
    this.labels.config.leftCupboard = {
      position: [-0.31, 0.56, LabelPosition.frontZPos],
      width: LabelWidth.volnayCupboard,
    };
    this.labels.config.leftOven = {
      position: [0.18, 0.56, LabelPosition.frontZPos],
      width: LabelWidth.sullyOven,
    };
    this.labels.config.leftStorageDrawer = {
      position: [0.18, LabelPosition.storeDrawerYPos, LabelPosition.frontZPos],
      width: LabelWidth.clunyStorageDrawer,
    };
  }
}
