import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

export function createControls(camera, canvas) {
  const controls = new OrbitControls(camera, canvas);

  controls.enabled = true;
  controls.enableZoom = false; // Leave this on per Reese's request
  controls.minDistance = 1;
  controls.maxDistance = 10;
  controls.minPolarAngle = 0;
  controls.maxPolarAngle = Math.PI * 0.8;
  // controls.minAzimuthAngle = -Math.PI / 4;
  // controls.maxAzimuthAngle = Math.PI / 4;
  controls.enableDamping = true;
  controls.dampingFactor = 0.1;

  // @ts-ignore
  controls.tick = () => controls.update();

  return controls;
}
