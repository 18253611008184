import { Group, Object3D } from 'three';
import { KnobModels } from '../shared/KnobModels';
import {
  Burner,
  Cupboard,
  Line,
  MeshName,
  Oven,
  Position,
  Region,
  Trim,
} from '../../shared/Enums';
import { Materials } from '../../shared/Materials';
import { State } from '../../shared/State';

export class BaseKnobs {
  /**
   * The knobs that are currently being displayed on the range
   * @type {Object.<string, Group>}
   */
  activeKnobs = {
    farLeft: new Group(),
    left: new Group(),
    center: new Group(),
    right: new Group(),
    farRight: new Group(),
  };

  /**
   * The knob dials that are currently being displayed on the range
   * @type {Object.<string, Group>}
   */
  activeKnobDials = {
    farLeft: new Group(),
    left: new Group(),
    center: new Group(),
    right: new Group(),
    farRight: new Group(),
  };

  /**
   * The knobs that selected but not necessarily displayed (yet) on the range
   * @type {Object.<string, Object.<string, Object.<string, ?string>>>}
   */
  selectedKnobs = {
    farLeft: {},
    left: {},
    center: {},
    right: {},
    farRight: {},
  };

  /**
   * The default knob model which will be cloned and added to the range
   * @type {Group}
   */
  knob;

  /**
   * The models for the knobs and dials
   * @type {KnobModels}
   */
  knobModels;

  /**
   * The Lacanche range that the knobs will be added to
   * @type {Group}
   */
  range;

  /**
   * The state of the range
   * @type {State}
   */
  state;

  /**
   * Applies trims to the knobs and colors to the dials
   * @type {Materials}
   */
  materials;

  /**
   * @param {KnobModels} knobModels
   * @param {Group} range
   * @param {State} state
   * @param {Materials} materials
   */
  constructor(knobModels, range, state, materials) {
    this.knobModels = knobModels;
    this.range = range;
    this.state = state;
    this.materials = materials;
    this.knob = knobModels.classiqueKnob;

    this.#initializeKnobGroups();
  }

  /**
   * Display two burner knobs for a two burner range top option
   * @param {string} region
   */
  add2Burners(region) {
    switch (region) {
      case Region.farLeft:
        this.selectedKnobs.farLeft.optionCenter.type = null;
        this.selectedKnobs.farLeft.optionBack.type = Burner.gasBurner;
        this.selectedKnobs.farLeft.optionFront.type = Burner.gasBurner;
        this.updateFarLeftKnobs();
        break;

      case Region.left:
        this.selectedKnobs.left.optionCenter.type = null;
        this.selectedKnobs.left.optionBack.type = Burner.gasBurner;
        this.selectedKnobs.left.optionFront.type = Burner.gasBurner;
        this.updateLeftKnobs();
        break;

      case Region.right:
        this.selectedKnobs.right.optionCenter.type = null;
        this.selectedKnobs.right.optionBack.type = Burner.gasBurner;
        this.selectedKnobs.right.optionFront.type = Burner.gasBurner;
        this.updateRightKnobs();
        break;

      case Region.farRight:
        this.selectedKnobs.farRight.optionCenter.type = null;
        this.selectedKnobs.farRight.optionBack.type = Burner.gasBurner;
        this.selectedKnobs.farRight.optionFront.type = Burner.gasBurner;
        this.updateFarRightKnobs();
        break;
    }
  }

  /**
   * Display one burner knob for a one burner range top option
   * @param {string} region
   */
  add1Burner(region) {
    switch (region) {
      case Region.farLeft:
        this.selectedKnobs.farLeft.optionCenter.type = Burner.gasBurner;
        this.selectedKnobs.farLeft.optionBack.type = null;
        this.selectedKnobs.farLeft.optionFront.type = null;
        this.updateFarLeftKnobs();
        break;

      case Region.left:
        this.selectedKnobs.left.optionCenter.type = Burner.gasBurner;
        this.selectedKnobs.left.optionBack.type = null;
        this.selectedKnobs.left.optionFront.type = null;
        this.updateLeftKnobs();
        break;

      case Region.right:
        this.selectedKnobs.right.optionCenter.type = Burner.gasBurner;
        this.selectedKnobs.right.optionBack.type = null;
        this.selectedKnobs.right.optionFront.type = null;
        this.updateRightKnobs();
        break;

      case Region.farRight:
        this.selectedKnobs.farRight.optionCenter.type = Burner.gasBurner;
        this.selectedKnobs.farRight.optionBack.type = null;
        this.selectedKnobs.farRight.optionFront.type = null;
        this.updateFarRightKnobs();
        break;
    }
  }

  /**
   * Display one burner knob for a flame grill range top option
   * @param {string} region
   */
  addFlameGrill(region) {
    switch (region) {
      case Region.farLeft:
        this.selectedKnobs.farLeft.optionCenter.type = Burner.flameGrill;
        this.selectedKnobs.farLeft.optionBack.type = null;
        this.selectedKnobs.farLeft.optionFront.type = null;
        this.updateFarLeftKnobs();
        break;

      case Region.farRight:
        this.selectedKnobs.farRight.optionCenter.type = Burner.flameGrill;
        this.selectedKnobs.farRight.optionBack.type = null;
        this.selectedKnobs.farRight.optionFront.type = null;
        this.updateFarRightKnobs();
        break;
    }
  }

  /**
   * Display one plancha knob for an electric plancha range top option
   * @param {string} region
   */
  addPlancha(region) {
    switch (region) {
      case Region.farLeft:
        this.selectedKnobs.farLeft.optionCenter.type = Burner.electricPlancha;
        this.selectedKnobs.farLeft.optionBack.type = null;
        this.selectedKnobs.farLeft.optionFront.type = null;
        this.updateFarLeftKnobs();
        break;

      case Region.left:
        this.selectedKnobs.left.optionCenter.type = Burner.electricPlancha;
        this.selectedKnobs.left.optionBack.type = null;
        this.selectedKnobs.left.optionFront.type = null;
        this.updateLeftKnobs();
        break;

      case Region.right:
        this.selectedKnobs.right.optionCenter.type = Burner.electricPlancha;
        this.selectedKnobs.right.optionBack.type = null;
        this.selectedKnobs.right.optionFront.type = null;
        this.updateRightKnobs();
        break;

      case Region.farRight:
        this.selectedKnobs.farRight.optionCenter.type = Burner.electricPlancha;
        this.selectedKnobs.farRight.optionBack.type = null;
        this.selectedKnobs.farRight.optionFront.type = null;
        this.updateFarRightKnobs();
        break;
    }
  }

  /**
   * Display a multicooker knob, switch and lights for a multi-cooker option
   * @param {string} region
   */
  addMultiCooker(region) {
    switch (region) {
      case Region.farLeft:
        this.selectedKnobs.farLeft.optionCenter.type = Burner.multiCooker;
        this.selectedKnobs.farLeft.optionBack.type = null;
        this.selectedKnobs.farLeft.optionFront.type = null;
        this.updateFarLeftKnobs();
        break;

      case Region.right:
        this.selectedKnobs.right.optionCenter.type = Burner.multiCooker;
        this.selectedKnobs.right.optionBack.type = null;
        this.selectedKnobs.right.optionFront.type = null;
        this.updateRightKnobs();
        break;

      case Region.farRight:
        this.selectedKnobs.farRight.optionCenter.type = Burner.multiCooker;
        this.selectedKnobs.farRight.optionBack.type = null;
        this.selectedKnobs.farRight.optionFront.type = null;
        this.updateFarRightKnobs();
        break;
    }
  }

  /**
   * Display two induction knobs for a two induction rings range top option
   * @param {string} region
   */
  addInductionRings(region) {
    switch (region) {
      case Region.farLeft:
        this.selectedKnobs.farLeft.optionCenter.type = null;
        this.selectedKnobs.farLeft.optionBack.type = Burner.twoInductionRings;
        this.selectedKnobs.farLeft.optionFront.type = Burner.twoInductionRings;
        this.updateFarLeftKnobs();
        break;

      case Region.left:
        this.selectedKnobs.left.optionCenter.type = null;
        this.selectedKnobs.left.optionBack.type = Burner.twoInductionRings;
        this.selectedKnobs.left.optionFront.type = Burner.twoInductionRings;
        this.updateLeftKnobs();
        break;

      case Region.right:
        this.selectedKnobs.right.optionCenter.type = null;
        this.selectedKnobs.right.optionBack.type = Burner.twoInductionRings;
        this.selectedKnobs.right.optionFront.type = Burner.twoInductionRings;
        this.updateRightKnobs();
        break;

      case Region.farRight:
        this.selectedKnobs.farRight.optionCenter.type = null;
        this.selectedKnobs.farRight.optionBack.type = Burner.twoInductionRings;
        this.selectedKnobs.farRight.optionFront.type = Burner.twoInductionRings;
        this.updateFarRightKnobs();
        break;
    }
  }

  /**
   * Remove any optional burner knobs from the selected region
   * @param {string} region
   */
  addSSWorkstation(region) {
    switch (region) {
      case Region.farLeft:
        this.selectedKnobs.farLeft.optionCenter.type = null;
        this.selectedKnobs.farLeft.optionBack.type = null;
        this.selectedKnobs.farLeft.optionFront.type = null;
        this.updateFarLeftKnobs();
        break;

      case Region.left:
        this.selectedKnobs.left.optionCenter.type = null;
        this.selectedKnobs.left.optionBack.type = null;
        this.selectedKnobs.left.optionFront.type = null;
        this.updateLeftKnobs();
        break;

      case Region.right:
        this.selectedKnobs.right.optionCenter.type = null;
        this.selectedKnobs.right.optionBack.type = null;
        this.selectedKnobs.right.optionFront.type = null;
        this.updateRightKnobs();
        break;

      case Region.farRight:
        this.selectedKnobs.farRight.optionCenter.type = null;
        this.selectedKnobs.farRight.optionBack.type = null;
        this.selectedKnobs.farRight.optionFront.type = null;
        this.updateFarRightKnobs();
        break;
    }
  }

  /**
   * Update all the knobs in a region
   * @param {string} region - The region of the range the needs to be updated
   * @param {Object} options
   * @param {number} options.maxKnobs - The maximum number of knobs
   * @param {number} options.offset - The distance from the leftmost knob region
   * @param {number} options.gap - The distance between each knob
   */
  updateKnobRegion(region, options) {
    const knobGroup = this.range.getObjectByName(this.activeKnobs[region].name);
    knobGroup.clear();

    const dialGroup = this.range.getObjectByName(
      this.activeKnobDials[region].name
    );
    dialGroup.clear();

    const selectedKnobs = this.selectedKnobs[region];

    const knobsNeeded = Object.keys(selectedKnobs).filter(
      (key) => selectedKnobs[key].type
    );

    const xPosOffset =
      options.offset +
      ((options.maxKnobs - knobsNeeded.length) * options.gap) / 2;

    knobsNeeded.forEach((key, index) => {
      const knob = this.knob.clone();
      const xAdjust = options.gap * index + xPosOffset;
      let newDial = null;
      knob.translateX(xAdjust);
      knobGroup.add(knob);

      switch (selectedKnobs[key].type) {
        case Burner.electricPlancha:
          const planchaDial = this.knobModels.planchaDial.clone();
          planchaDial.translateX(xAdjust);
          newDial = planchaDial;
          break;

        case Burner.flameGrill:
          const flameGrillDial = this.knobModels.knobBurnerDial.clone();
          flameGrillDial.translateX(xAdjust);
          newDial = flameGrillDial;
          break;

        case Burner.gasBurner:
          const burnerDial = this.knobModels.knobBurnerDial.clone();
          burnerDial.translateX(xAdjust);
          newDial = burnerDial;
          break;

        case Burner.multiCooker:
          const multiCookerDial = this.knobModels.multiCookerDial.clone();
          multiCookerDial.translateX(xAdjust);
          newDial = multiCookerDial;
          break;

        case Burner.twoInductionRings:
          const inductionDial = this.knobModels.inductionDial.clone();
          inductionDial.translateX(xAdjust);
          newDial = inductionDial;
          break;

        case Oven.convection:
          const convectionOvenDial = this.knobModels.convectionOvenDial.clone();
          convectionOvenDial.translateX(xAdjust);
          newDial = convectionOvenDial;
          break;

        case Oven.convectionAlt:
          const convectionOvenDialAlt =
            this.knobModels.convectionOvenDialAlt.clone();
          convectionOvenDialAlt.translateX(xAdjust);
          newDial = convectionOvenDialAlt;
          break;

        case Oven.gas:
          const gasOvenDial = this.knobModels.gasOvenDial.clone();
          gasOvenDial.translateX(xAdjust);
          newDial = gasOvenDial;
          break;

        case Oven.electric:
          const electricOvenDial = this.knobModels.electricOvenDial.clone();
          electricOvenDial.translateX(xAdjust);
          newDial = electricOvenDial;
          break;

        case Oven.leftVertConv:
          const leftVertConvOvenDial =
            this.knobModels.leftVertConvOvenDial.clone();
          leftVertConvOvenDial.translateX(xAdjust);
          newDial = leftVertConvOvenDial;
          break;

        case Oven.petite:
          const petiteOvenDial = this.knobModels.petiteOvenDial.clone();
          petiteOvenDial.translateX(xAdjust);
          newDial = petiteOvenDial;
          break;

        case Oven.rightVertConv:
          const rightVertConvOvenDial =
            this.knobModels.rightVertConvOvenDial.clone();
          rightVertConvOvenDial.translateX(xAdjust);
          newDial = rightVertConvOvenDial;
          break;

        case Cupboard.warming:
          let warmingCupboardDial;
          switch (region) {
            case Region.farLeft:
            case Region.center:
              warmingCupboardDial =
                this.knobModels.warmingCupboardDialLeft.clone();
              break;
            case Region.farRight:
              warmingCupboardDial =
                this.knobModels.warmingCupboardDialRight.clone();
              break;
          }
          warmingCupboardDial.translateX(xAdjust);
          newDial = warmingCupboardDial;
          break;
      }

      // Place the burner (or oven) position dot
      if (selectedKnobs[key].position) {
        this.#addPositionDotToDial(newDial, selectedKnobs[key].position);
      }

      dialGroup.add(newDial);
    });
  }

  /**
   * Add a position dot to the dial to indicate burner or oven position
   * @param {Group} newDial
   * @param {string} position
   */
  #addPositionDotToDial(newDial, position) {
    const adjustX = 0.0028;
    const adjustY = 0.0017;
    const positionDot = this.knobModels.positionDot.clone();

    switch (position) {
      case Position.back:
        positionDot.translateY(adjustY);
        break;
      case Position.center:
        // Do nothing
        break;
      case Position.front:
        positionDot.translateY(-adjustY);
        break;
      case Position.left:
        positionDot.translateX(-adjustX);
        break;
      case Position.leftBack:
        positionDot.translateX(-adjustX).translateY(adjustY);
        break;
      case Position.leftFront:
        positionDot.translateX(-adjustX).translateY(-adjustY);
        break;
      case Position.right:
        positionDot.translateX(adjustX);
        break;
      case Position.rightBack:
        positionDot.translateX(adjustX).translateY(adjustY);
        break;
      case Position.rightFront:
        positionDot.translateX(adjustX).translateY(-adjustY);
        break;
    }
    newDial.add(positionDot);
  }

  /**
   * Change the style of the knobs to classique or moderne
   */
  changeLine() {
    const line = this.state.line;

    if (line === Line.classique) {
      this.knob = this.knobModels.classiqueKnob;
    } else if (line === Line.moderne) {
      this.knob = this.knobModels.moderneKnob;
    }

    this.changeKnobDialColor();
    this.updateFarLeftKnobs();
    this.updateLeftKnobs();
    this.updateCenterKnobs();
    this.updateRightKnobs();
    this.updateFarRightKnobs();
  }

  /**
   * Change the trim of the knobs to brass, brushed stainless steel, chrome, or
   * nickel
   * @param {string} trim
   */
  changeTrim(trim) {
    const knobGroups = [
      this.activeKnobs.farLeft.name,
      this.activeKnobs.left.name,
      this.activeKnobs.center.name,
      this.activeKnobs.right.name,
      this.activeKnobs.farRight.name,
    ];

    knobGroups.forEach((group) => {
      const knobGroup = this.range.getObjectByName(group);

      knobGroup.children.forEach((knob) => {
        const knobMetalPart = this.knobMetalPart(knob);
        this.materials.changeTrim(trim, [knobMetalPart]);
      });
    });

    this.changeBaseKnobsTrim(trim);
  }

  /**
   * Change the LED bezels to brass or chrome
   * @param {string} trim
   */
  changeLEDBezels(trim) {
    if (trim !== Trim.brass) trim = Trim.chrome;

    const dialGroups = [
      this.activeKnobDials.farLeft.name,
      this.activeKnobDials.left.name,
      this.activeKnobDials.center.name,
      this.activeKnobDials.right.name,
      this.activeKnobDials.farRight.name,
    ];

    // Update the colors for the active dials
    dialGroups.forEach((section) => {
      const rangeSection = this.range.getObjectByName(section);

      rangeSection.children.forEach((dialGroup) => {
        dialGroup.children.forEach((dialElement) => {
          // @ts-ignore
          if (!dialElement.isGroup) return;
          const ledBezel = dialElement.getObjectByName('bezel');
          if (!ledBezel) return;

          this.materials.changeTrim(trim, [ledBezel]);
        });
      });
    });

    // Ensure the base LED bezels are also updated
    this.knobModels.changeLEDBezels(trim);
  }

  /**
   * Change the color of the knob dials to complement the range color
   */
  changeKnobDialColor() {
    const rangeColor = this.state.color;

    const dialGroups = [
      this.activeKnobDials.farLeft.name,
      this.activeKnobDials.left.name,
      this.activeKnobDials.center.name,
      this.activeKnobDials.right.name,
      this.activeKnobDials.farRight.name,
    ];

    // Update the colors for the active dials
    dialGroups.forEach((section) => {
      const rangeSection = this.range.getObjectByName(section);

      rangeSection.children.forEach((dialGroup) => {
        this.materials.changeKnobDialColor(rangeColor, this.state.line, [
          ...dialGroup.children,
        ]);
      });
    });

    // Ensure the base knob dials are also updated
    this.materials.changeKnobDialColor(rangeColor, this.state.line, [
      ...this.knobModels.convectionOvenDial.children,
      ...this.knobModels.convectionOvenDialAlt.children,
      ...this.knobModels.electricOvenDial.children,
      ...this.knobModels.gasOvenDial.children,
      ...this.knobModels.inductionDial.children,
      ...this.knobModels.knobBurnerDial.children,
      ...this.knobModels.leftVertConvOvenDial.children,
      ...this.knobModels.multiCookerDial.children,
      ...this.knobModels.petiteOvenDial.children,
      ...this.knobModels.planchaDial.children,
      this.knobModels.positionBox,
      this.knobModels.positionDot,
      ...this.knobModels.rightVertConvOvenDial.children,
      ...this.knobModels.warmingCupboardDialLeft.children,
    ]);
  }

  /**
   * Change the base knobs to have the correct trim. This is for when a new
   * knob is added to the range
   * @param {string} trim
   */
  changeBaseKnobsTrim(trim) {
    const classiqueMetal = this.knobModels.classiqueKnob.getObjectByName(
      MeshName.classiqueKnob
    );
    const moderneMetal = this.knobModels.moderneKnob.getObjectByName(
      MeshName.moderneKnob
    );

    this.materials.changeTrim(trim, [classiqueMetal, moderneMetal]);
  }

  /**
   * Each knob is make up a metal part and a black plastic back. This retrieves
   * the metal part.
   * @param {Object3D} knob
   * @returns {Object3D}
   */
  knobMetalPart(knob) {
    if (this.state.line === Line.classique) {
      return knob.getObjectByName(MeshName.classiqueKnob);
    } else if (this.state.line === Line.moderne) {
      return knob.getObjectByName(MeshName.moderneKnob);
    }
  }

  /**
   * Change the selected cupboard to have a knob for a warming cupboard
   * @abstract
   * @param {string} region
   */
  selectWarmingCupboard(region) {
    throw new Error('This method must be implemented by subclass!');
  }

  /**
   * Update the knobs on the range's far left
   * @abstract
   */
  updateFarLeftKnobs() {}

  /**
   * Update the knobs on the range's left
   * @abstract
   */
  updateLeftKnobs() {}

  /**
   * Update the knobs in the range's center
   * @abstract
   */
  updateCenterKnobs() {}

  /**
   * Update the knobs on the range's right
   * @abstract
   */
  updateRightKnobs() {}

  /**
   * Update the knobs on the range's far right
   * @abstract
   */
  updateFarRightKnobs() {}

  /**
   * Update the knob for the left oven
   * @param {string} ovenType
   * @abstract
   */
  changeLeftOven(ovenType) {}

  /**
   * Update the knob for the right oven
   * @param {string} ovenType
   * @abstract
   */
  changeRightOven(ovenType) {}

  #initializeKnobGroups() {
    this.activeKnobs.farLeft.name = 'far-left-knobs';
    this.activeKnobs.left.name = 'left-knobs';
    this.activeKnobs.center.name = 'center-knobs';
    this.activeKnobs.right.name = 'right-knobs';
    this.activeKnobs.farRight.name = 'far-right-knobs';

    this.activeKnobDials.farLeft.name = 'far-left-knob-dials';
    this.activeKnobDials.left.name = 'left-knob-dials';
    this.activeKnobDials.center.name = 'center-knob-dials';
    this.activeKnobDials.right.name = 'right-knob-dials';
    this.activeKnobDials.farRight.name = 'far-right-knob-dials';

    this.range.add(this.activeKnobs.farLeft);
    this.range.add(this.activeKnobs.left);
    this.range.add(this.activeKnobs.center);
    this.range.add(this.activeKnobs.right);
    this.range.add(this.activeKnobs.farRight);

    this.range.add(this.activeKnobDials.farLeft);
    this.range.add(this.activeKnobDials.left);
    this.range.add(this.activeKnobDials.center);
    this.range.add(this.activeKnobDials.right);
    this.range.add(this.activeKnobDials.farRight);
  }
}
