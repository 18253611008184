import { Object3D } from 'three';
import { AssetLoader } from '../shared/AssetLoader';
import { Materials } from '../shared/Materials';
import { LacancheLogo } from '../shared/SharedParts';
import { BaseHood } from './BaseHood';
import { MeshName } from '../shared/Enums';

export class Hood2400 extends BaseHood {
  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   * @param {LacancheLogo} sharedParts
   * @param {string} color
   */
  constructor(assets, materials, sharedParts, color) {
    super(assets, materials, sharedParts);
    this.bodyColor = color;
    this.hood.name = 'hood-2400';
  }

  async init() {
    await this.loadModels(this.assets.hood2400Data);
    this.applyMaterials();
    this.hideHighDuctCovers();
    this.changeColor(this.bodyColor);

    this.#addMAVBaffles();
    this.#addMAVLights();
    this.#addMAVKnob();

    this.addVAHLights();
  }

  #addMAVBaffles() {
    const mavBaffle = this.mavInsert.getObjectByName(MeshName.mavBaffleFilter);

    // Add left baffle plates
    for (let i = 1; i <= 3; i++) {
      const baffle = mavBaffle.clone();
      baffle.translateX(i * -0.254375);
      this.mavInsert.add(baffle);
    }

    // Add right baffle plates
    for (let i = 1; i <= 3; i++) {
      const baffle = mavBaffle.clone();
      baffle.translateX(i * 0.254375);
      this.mavInsert.add(baffle);
    }
  }

  #addMAVLights() {
    const mavLight = this.mavInsert.getObjectByName(MeshName.mavLedLens);

    // Add left lights
    for (let i = 1; i <= 2; i++) {
      const light = mavLight.clone();
      light.translateX(i * -0.367);
      this.mavInsert.add(light);
    }

    // Add right lights
    for (let i = 1; i <= 2; i++) {
      const light = mavLight.clone();
      light.translateX(i * 0.367);
      this.mavInsert.add(light);
    }
  }

  #addMAVKnob() {
    const mavKnob = this.mavInsert.getObjectByName(MeshName.mavKnob);
    const rightKnob = mavKnob.clone();
    rightKnob.translateX(0.24);

    this.mavInsert.add(rightKnob);
  }
}
