import { Group, Object3D } from 'three';
import { AssetLoader } from '../shared/AssetLoader';
import { MeshName } from '../shared/Enums';
import { Materials } from '../shared/Materials';

export class BaseBacksplash {
  /** @typedef {import("three/examples/jsm/loaders/GLTFLoader.js").GLTF} GLTF */

  /** All backsplash meshes should be added to (or removed from) this group */
  backsplash = new Group();

  /** @type {AssetLoader} */
  assets;

  /** @type {Materials} */
  materials;

  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   */
  constructor(assets, materials) {
    this.assets = assets;
    this.materials = materials;
  }

  /**
   * Load the hood and insert 3D models
   * @param {Promise<GLTF>} asyncBacksplashData
   */
  async loadModels(asyncBacksplashData) {
    const backsplashData = await asyncBacksplashData;

    backsplashData.scene.children.forEach((child) => {
      this.backsplash.add(child.clone());
    });
  }

  /** Apply the appropriate materials to the correct meshes */
  applyMaterials() {
    this.materials.applyStainlessSteelMaterial(...this.stainlessSteelParts());
    this.materials.applyGalvanizedSteelMaterial(...this.galvanizedSteelParts());
  }

  /**
   * All the parts of the backsplash that are stainless steel
   * @returns {Object3D[]}
   */
  stainlessSteelParts() {
    return [this.backsplash.getObjectByName(MeshName.backsplash)];
  }

  /**
   * All the parts of the backsplash that are galvanized steel
   * @returns {Object3D[]}
   */
  galvanizedSteelParts() {
    return [
      this.backsplash.getObjectByName(MeshName.backsplashPotRailBar),
      this.backsplash.getObjectByName(MeshName.backsplashZHook1),
      this.backsplash.getObjectByName(MeshName.backsplashZHook2),
    ];
  }

  /**
   * Finish initializing the backsplash
   * @abstract
   */
  async init() {
    // This should be implemented by subclasses that need to load 3D models asynchronously
  }
}
