import { Object3D } from 'three';
import { AssetLoader } from '../shared/AssetLoader';
import { Materials } from '../shared/Materials';
import { LacancheLogo } from '../shared/SharedParts';
import { BaseHood } from './BaseHood';
import { MeshName } from '../shared/Enums';

export class Hood900 extends BaseHood {
  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   * @param {LacancheLogo} sharedParts
   * @param {string} color
   */
  constructor(assets, materials, sharedParts, color) {
    super(assets, materials, sharedParts);
    this.bodyColor = color;
    this.hood.name = 'hood-900';
  }

  async init() {
    await this.loadModels(this.assets.hood900Data);
    this.applyMaterials();

    this.#addMAVBaffles();
    this.#addMAVLights();
    this.#addMAVKnob();

    this.#addVAHLights();

    this.hideHighDuctCovers();
    this.changeColor(this.bodyColor);
  }

  /**
   * All the parts of the hood that are stainless steel
   * @returns {Object3D[]}
   */
  stainlessSteelParts() {
    return [
      this.hood.getObjectByName(MeshName.hoodDuctCover250),
      this.hood.getObjectByName(MeshName.hoodDuctCover500),
      this.hood.getObjectByName(MeshName.hoodDuctCover750),
      this.hood.getObjectByName(MeshName.hoodDuctCover1000),
      this.hood.getObjectByName(MeshName.hoodLowerBeam),

      this.mavInsert.getObjectByName(MeshName.mavBaffleFilter),
      this.mavInsert.getObjectByName(MeshName.mavInsertHousing),
    ];
  }

  #addMAVBaffles() {
    // Only one baffle plate is needed, and it is already in the correct position
  }

  #addMAVLights() {
    const offsetX = 0.188;

    // Add lights from left to right
    const leftLight = this.mavInsert.getObjectByName(MeshName.mavLedLens);
    leftLight.translateX(-offsetX).translateZ(-0.001);

    const rightLight = leftLight.clone();
    rightLight.translateX(offsetX * 2);

    this.mavInsert.add(rightLight);
  }

  #addMAVKnob() {
    const leftKnob = this.mavInsert.getObjectByName(MeshName.mavKnob);
    leftKnob.position.z += -0.001;
    leftKnob.translateX(0.07);

    const rightKnob = leftKnob.clone();
    rightKnob.translateX(0.1);

    this.mavInsert.add(rightKnob);
  }

  #addVAHLights() {}
}
