import { OrthographicCamera, PerspectiveCamera } from 'three';
import { Url } from '../shared/Url';

/**
 * Set up the perspective camera. Adjust the camera position to get the desired view.
 * @param {Url} url
 * @returns {PerspectiveCamera}
 */
export function createCamera(url) {
  const camera = new PerspectiveCamera(
    25, // fov = Field Of View
    1, // aspect ratio (dummy value)
    0.1, // near clipping plane
    12 // far clipping plane
  );

  if (url.isScreenshot()) {
    camera.position.set(0, 1.6, 4.2); // For taking email screenshots
  } else {
    camera.position.set(0, 0.135, 4.5); // Adjusted for Carmen taking screenshots
  }
  // camera.position.set(0, 1.5, 4.5);

  // const camera = new OrthographicCamera(
  //   -1, // left
  //   1, // right
  //   1, // top
  //   -1, // bottom
  //   0.1, // near
  //   10 // far
  // );
  // camera.position.set(0, 0.08, 4.5);

  return camera;
}
