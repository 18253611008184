/**
 * Optional burners
 * @readonly
 * @enum {string}
 */
const Burner = {
  eighteenK: '18k',
  electricPlancha: 'LAE010TPK',
  elevenK: '11k',
  fifteenK: '15k',
  fiveK: '5k',
  flameGrill: 'LAG010CK',
  gasBurner: 'burner',
  multiCooker: 'LAE010CVA',
  one18kBurner: 'LAG010UR',
  stainlessSteelWorkstation: 'ss',
  traditionalSimmerPlate: 'LAG010CF',
  two11kBurners: 'LAG020UR',
  two15kBurners: 'LAG024UR',
  twoInductionRings: 'LAE020I',
};
Object.freeze(Burner);

/**
 * Range and hood colors
 * @readonly
 * @enum {string}
 */
const Color = {
  anthracite: 'an',
  ardoise: 'ard',
  armor: 'ar',
  britishRacingGreen: 'brg',
  burgundyRed: 'br',
  chantilly: 'ct',
  chocolat: 'ch',
  coralBlue: 'cb',
  customColor: 'cc',
  delftBlue: 'db',
  englishCreme: 'ec',
  faience: 'fa',
  frangipane: 'fr',
  frenchBlue: 'fb',
  griotte: 'gr',
  ivory: 'iv',
  mandarine: 'ma',
  marronGlace: 'mg',
  matteBlack: 'mb',
  olive: 'ol',
  plum: 'pl',
  portugueseBlue: 'pb',
  provenceYellow: 'py',
  roseQuartz: 'rq',
  shinyBlack: 'sb',
  stainlessSteel: 'ss',
  terracotta: 'tc',
  tilleul: 'ti',
  vertSilice: 'vsi',
  vertSologne: 'vs',
  white: 'wh',
};
Object.freeze(Color);

/**
 * Cupboard types
 * @readonly
 * @enum {string}
 */
const Cupboard = {
  warming: 'warm',
  storage: 'store',
};
Object.freeze(Cupboard);

/**
 * Hood duct cover finishes
 * @readonly
 * @enum {string}
 */
const DuctCover = {
  color: 'color',
  stainlessSteel: 'stainlessSteel',
};
Object.freeze(DuctCover);

/**
 * Gas type
 * @readonly
 * @enum {string}
 */
const Gas = {
  propane: 'lpg',
  naturalGas: 'ng',
  notSure: 'unsure',
};
Object.freeze(Gas);

/**
 * Range height options
 * @readonly
 * @enum {string}
 */
const Height = {
  '35_38': '35_38',
  36: '36',
  '36_58': '36_58',
  '37_316': '37_316',
  '37_1316': '37_1316',
  '38_38': '38_38',
  39: '39',
  '39_916': '39_916',
  '40_316': '40_316',
  notSure: 'unsure',
  other: 'other',
};
Object.freeze(Height);

/**
 * Hood insert types
 * @readonly
 * @enum {string}
 */
const HoodInsert = {
  modernAire: 'modern-aire',
  ventAHood: 'vent-a-hood',
};
Object.freeze(Height);

/**
 * Label names for range parts
 * @readonly
 * @enum {string}
 */
const LabelName = {
  leftCupboard: 'left-cupboard-label',
  leftOven: 'left-oven-label',
  rightCupboard: 'right-cupboard-label',
  rightOven: 'right-oven-label',
  storageDrawer: 'storage-drawer-label',
  verticalConvection: 'vertical-convection-label',
};
Object.freeze(LabelName);

/**
 * Maximum label widths for range parts
 * @readonly
 * @enum {number}
 */
const LabelWidth = {
  clunyOven: 0.45,
  clunyStorageDrawer: 0.29,
  cupboard: 0.35,
  sullyOven: 0.62,
  volnayCupboard: 0.3,
  volnayOven: 0.54,
};
Object.freeze(LabelWidth);

/**
 * Various coordinates for label positions
 * @readonly
 * @enum {number}
 */
const LabelPosition = {
  frontZPos: 0.33,
  storeDrawerYPos: 0.23,
};
Object.freeze(LabelPosition);

/**
 * Range lines
 * @readonly
 * @enum {string}
 */
const Line = {
  classique: 'clas',
  moderne: 'mod',
};
Object.freeze(Line);

/**
 * Three.js mesh names for important 3D objects
 * @readonly
 * @enum {string}
 */
const MeshName = {
  aerationVentBlack: 'Aeration_Vent_Black',
  backSpacer: 'Back_Spacer',
  backSpacerBlack: 'Back_Spacer_Black',
  backsplash: 'Backsplash',
  backsplashBarSupport1: 'Backsplash_Bar_Support_1',
  backsplashBarSupport2: 'Backsplash_Bar_Support_2',
  backsplashBarSupport3: 'Backsplash_Bar_Support_3',
  backsplashPotRailBar: 'Backsplash_Pot_Rail_Bar',
  backsplashZHook1: 'Backsplash_Z_Hook_1',
  backsplashZHook2: 'Backsplash_Z_Hook_2',
  classiqueAerationVent: 'Aeration_Vent_Classique',
  classiqueCupboardDoorBar: 'Cupboard_Door_Bar_Classique',
  classiqueClunyOvenDoorBar: 'Oven_Door_Bar_Classique_Cluny',
  classiqueSullyOvenDoorBar: 'Oven_Door_Bar_Classique_Sully',
  classiqueVolnayOvenDoorBar: 'Oven_Door_Bar_Classique_Volnay',
  classiqueKnob: 'Knob_Classique',
  clunyOvenDoor: 'Oven_Door_Cluny',
  clunyStorageDrawer: 'Storage_Drawer_Cluny',
  controlPanel: 'Control_Panel',
  cupboardDoor: 'Cupboard_Door',
  cupboardDoorBar: 'Cupboard_Door_Bar',
  cupboardBarLeftSupport: 'Cupboard_Bar_Support_Left',
  cupboardBarRightSupport: 'Cupboard_Bar_Support_Right',
  cupboardVentCover: 'Cupboard_Vent_Cover',
  cupboardVentCoverBlack: 'Cupboard_Vent_Cover_Black',
  cupboardVentCoverGroup: 'cupboard-vent-cover-group',
  doorBarLeftSupport: 'Door_Bar_Support_Left',
  doorBarRightSupport: 'Door_Bar_Support_Right',
  drawerLeftPull: 'Drawer_Pull_Left',
  drawerPull: 'Drawer_Pull',
  drawerRightPull: 'Drawer_Pull_Right',
  electricOvenBackPanel: 'Back_Panel_Electric_Oven',
  gasOvenBackPanel: 'Back_Panel_Gas_Oven',
  gasOvenChimneyBackPanel: 'Back_Panel_Gas_Oven_Chimney',
  hoodBackPanel: 'Hood_Back_Panel',
  hoodBody: 'Hood_Body',
  hoodDuctCover: 'Duct_Cover',
  hoodDuctCover250: 'Duct_Cover_250',
  hoodDuctCover500: 'Duct_Cover_500',
  hoodDuctCover750: 'Duct_Cover_750',
  hoodDuctCover1000: 'Duct_Cover_1000',
  hoodLowerBeam: 'Hood_Lower_Beam',
  leftCooktopHingePlate: 'Cooktop_Hinge_Plate_Left',
  leftCupboardDoor: 'Left_Cupboard_Door',
  leftCupboardBarLeftSupport: 'Left_Cupboard_Bar_Support_Left',
  leftCupboardBarRightSupport: 'Left_Cupboard_Bar_Support_Right',
  leftCupboardVentCover: 'Left_Cupboard_Vent_Cover',
  leftDrawerLeftPull: 'Left_Drawer_Pull_Left',
  leftDrawerRightPull: 'Left_Drawer_Pull_Right',
  leftOvenDoor: 'Left_Oven_Door',
  leftOvenDoorBarLeftSupport: 'Left_Oven_Door_Bar_Support_Left',
  leftOvenDoorBarRightSupport: 'Left_Oven_Door_Bar_Support_Right',
  leftOvenMiniVentCover: 'Left_Oven_Mini_Vent_Cover',
  leftOvenVentCover: 'Left_Oven_Vent_Cover',
  leftStorageDrawer: 'Left_Storage_Drawer',
  logoBorder: 'Logo_Border',
  logoPlate: 'Logo_Plate',
  mavBaffleFilter: 'MAV_Baffle_Filter',
  mavInsertHousing: 'MAV_Insert_Housing',
  mavKnob: 'MAV_Knob',
  mavLedLens: 'MAV_LED_Lens',
  moderneAerationVent: 'Aeration_Vent_Moderne',
  moderneClunyDrawerBottomLip: 'Drawer_Bottom_Lip_Moderne_Cluny',
  moderneClunyOvenDoorBar: 'Oven_Door_Bar_Moderne_Cluny',
  moderneCupboardDoorBar: 'Cupboard_Door_Bar_Moderne',
  moderneDoorBarSupport: 'Door_Bar_Support_Moderne',
  moderneSullyDrawerBottomLip: 'Drawer_Bottom_Lip_Moderne_Sully',
  moderneSullyOvenDoorBar: 'Oven_Door_Bar_Moderne_Sully',
  moderneVolnayCupboardDoorBar: 'Cupboard_Door_Bar_Moderne_Volnay',
  moderneVolnayDrawerBottomLip: 'Drawer_Bottom_Lip_Moderne_Volnay',
  moderneVolnayOvenDoorBar: 'Oven_Door_Bar_Moderne_Volnay',
  moderneKnob: 'Knob_Moderne',
  ovenDoor: 'Oven_Door',
  ovenDoorBar: 'Oven_Door_Bar',
  ovenDoorBarLeftSupport: 'Oven_Door_Bar_Support_Left',
  ovenDoorBarRightSupport: 'Oven_Door_Bar_Support_Right',
  ovenVentCover: 'Oven_Vent_Cover',
  ovenVentCoverBlack: 'Oven_Vent_Cover_Black',
  ovenVentCoverGroup: 'oven-vent-cover-group',
  petiteOvenDoor: 'Petite_Oven_Door',
  petiteOvenDoorBar: 'Petite_Oven_Door_Bar',
  petiteOvenDoorBarLeftSupport: 'Petite_Oven_Door_Bar_Support_Left',
  petiteOvenDoorBarRightSupport: 'Petite_Oven_Door_Bar_Support_Right',
  rangeBackPanel: 'Back_Panel_Range',
  rangeBase: 'Range_Base',
  rangeSides: 'Range_Sides',
  rangeTop: 'range-top',
  rangeTop700: '700_Range_Top',
  rangeTop900: '900_Range_Top',
  rangeTop1000: '1000_Range_Top',
  rangeTopRim1100: '1100_Range_Top_Rim',
  rangeTopRim1400: '1400_Range_Top_Rim',
  rangeTopRim1500: '1500_Range_Top_Rim',
  rangeTopRim1800: '1800_Range_Top_Rim',
  rangeTopRim2200: '2200_Range_Top_Rim',
  rightCooktopHingePlate: 'Cooktop_Hinge_Plate_Right',
  rightCupboardDoor: 'Right_Cupboard_Door',
  rightCupboardBarLeftSupport: 'Right_Cupboard_Bar_Support_Left',
  rightCupboardBarRightSupport: 'Right_Cupboard_Bar_Support_Right',
  rightCupboardVentCover: 'Right_Cupboard_Vent_Cover',
  rightDrawerLeftPull: 'Right_Drawer_Pull_Left',
  rightDrawerRightPull: 'Right_Drawer_Pull_Right',
  rightOvenDoor: 'Right_Oven_Door',
  rightOvenDoorBarLeftSupport: 'Right_Oven_Door_Bar_Support_Left',
  rightOvenDoorBarRightSupport: 'Right_Oven_Door_Bar_Support_Right',
  rightOvenMiniVentCover: 'Right_Oven_Mini_Vent_Cover',
  rightOvenVentCover: 'Right_Oven_Vent_Cover',
  rightStorageDrawer: 'Right_Storage_Drawer',
  storageDrawer: 'Storage_Drawer',
  sullyOvenDoor: 'Oven_Door_Sully',
  sullyStorageDrawer: 'Storage_Drawer_Sully',
  toeKick: 'Toe_Kick',
  towelBar: 'Towel_Bar',
  towelBarCenterSupport: 'Towel_Bar_Support_Center',
  towelBarleftSupport: 'Towel_Bar_Support_Left',
  towelBarMidLeftSupport: 'Towel_Bar_Support_Mid_Left',
  towelBarMidRightSupport: 'Towel_Bar_Support_Mid_Right',
  towelBarRightSupport: 'Towel_Bar_Support_Right',
  vahDecoupeSpacer: 'VAH_Decoupe_Spacer',
  vahFanShield: 'VAH_Fan_Shield',
  vahInsertHousing: 'VAH_Insert_Housing',
  vahKnobBand: 'VAH_Knob_Band',
  vahKnobFanIcon: 'VAH_Knob_Fan_Icon',
  vahKnobFan: 'VAH-fan-knob',
  vahKnobLight: 'VAH_Knob_Light',
  vahLightBase: 'VAH_Light_Base',
  vahLightLens: 'VAH_Light_Lens',
  vctBackPanel: 'Back_Panel_VCT',
  vctBackPanelTop: 'Back_Panel_VCT_Top',
  volnayCupboardDoor: 'Cupboard_Door_Volnay',
  volnayOvenDoor: 'Oven_Door_Volnay',
  volnayStorageDrawer: 'Storage_Drawer_Volnay',
};
Object.freeze(MeshName);

/**
 * Lacanche range model names
 * @readonly
 * @enum {string}
 */
const Model = {
  beaune: 'beaune',
  bussy: 'bussy',
  chagny: 'chagny',
  chagny1400left: 'chagny1400G',
  chagny1400right: 'chagny1400D',
  chagny1800: 'chagny1800',
  chambertin: 'chambertin',
  chassagne: 'chassagne',
  citeaux: 'citeaux',
  cluny: 'cluny',
  cluny1400left: 'cluny1400G',
  cluny1400right: 'cluny1400D',
  cluny1800: 'cluny1800',
  cormatin: 'cormatin',
  fontenay: 'fontenay',
  rully: 'rully',
  saulieu: 'saulieu',
  savigny: 'savigny',
  sully: 'sully',
  sully1800left: 'sully1800G',
  sully1800right: 'sully1800D',
  sully2200: 'sully2200',
  volnay: 'volnay',
  vougeot: 'vougeot',
};
Object.freeze(Model);

/**
 * Spaces for optional burners
 * @readonly
 * @enum {string}
 */
const OptionSpace = {
  option1: 'option1',
  option2: 'option2',
  option3: 'option3',
  option4: 'option4',
};
Object.freeze(OptionSpace);

/**
 * Oven types
 * @readonly
 * @enum {string}
 */
const Oven = {
  convection: 'elcon',
  convectionAlt: 'elconAlt',
  electric: 'elec',
  gas: 'gas',
  grande: 'grande',
  leftVertConv: 'leftConv',
  petite: 'petite',
  rightVertConv: 'rightConv',
  vertConv: 'conv',
};
Object.freeze(Oven);

/**
 * Placment for optional burners
 * @readonly
 * @enum {string}
 */
const Placement = {
  l1: 'l1',
  l2: 'l2',
  r1: 'r1',
  r2: 'r2',
};
Object.freeze(Placement);

/**
 * Oven types
 * @readonly
 * @enum {string}
 */
const Position = {
  back: 'back',
  center: 'center',
  front: 'front',
  left: 'left',
  leftBack: 'left-back',
  leftFront: 'left-front',
  right: 'right',
  rightBack: 'right-back',
  rightFront: 'right-front',
};
Object.freeze(Position);

/**
 * Range top styles
 * @readonly
 * @enum {string}
 */
const RangeTop = {
  classique: 'clas',
  traditional: 'trad',
  fourFeux: '4feux',
  classiqueRev: 'rev',
};
Object.freeze(RangeTop);

/**
 * Range regions
 * @readonly
 * @enum {string}
 */
const Region = {
  farLeft: 'farLeft',
  left: 'left',
  center: 'center',
  right: 'right',
  farRight: 'farRight',
};

/**
 * Back Spacer
 * @readonly
 * @enum {string}
 */
const Spacer = {
  raised: 'raised',
  flush: 'flush',
};
Object.freeze(Spacer);

/**
 * Range trims
 * @readonly
 * @enum {string}
 */
const Trim = {
  brass: 'br',
  brushedSS: 'ss',
  chrome: 'chr',
  nickel: 'ni',
};
Object.freeze(Trim);

export {
  Burner,
  Color,
  Cupboard,
  DuctCover,
  Gas,
  Height,
  HoodInsert,
  LabelName,
  LabelPosition,
  LabelWidth,
  Line,
  MeshName,
  Model,
  OptionSpace,
  Oven,
  Placement,
  Position,
  RangeTop,
  Region,
  Spacer,
  Trim,
};
