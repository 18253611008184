import {
  Burner,
  Color,
  Cupboard,
  Gas,
  Height,
  Line,
  Model,
  Oven,
  RangeTop,
  Trim,
} from './Enums';

export const ampsLookup = {
  [Oven.electric]: 9.1, // Cluny sized oven
  [Oven.convection]: 12, // Sully sized oven
  [Oven.vertConv]: 11, // Vertical convection oven
  [Oven.grande]: 12, // Rully (large) pizza oven
  [Oven.petite]: 10, // Chagny (small) pizza oven
  [Cupboard.warming]: 3.9, // Warming cupboard
  [Oven.gas]: 0, // Gas oven
  [Cupboard.storage]: 0, // Storage cupboard
  [Burner.electricPlancha]: 9.1, // Electric Plancha
  [Burner.traditionalSimmerPlate]: 0, // Traditional French plate
  [Burner.one18kBurner]: 0, // 18,000 BTU burner
  [Burner.two11kBurners]: 0, // 2 - 11,000 BTU burner
  [Burner.two15kBurners]: 0, // 2 - 15,000 BTU burner
  [Burner.flameGrill]: 0, // Flame Grill
  [Burner.multiCooker]: 14.6, // Multi-Cooker
  [Burner.twoInductionRings]: 16.2, // 2 - Induction rings
  [Burner.stainlessSteelWorkstation]: 0, // Stainless Steel Work Station
  null: 0, // Oven not present
};

export const btusLookup = {
  [Gas.propane]: {
    [Oven.gas]: 11000,
    [Burner.fiveK]: 5000,
    [Burner.elevenK]: 10000,
    [Burner.fifteenK]: 13000,
    [Burner.eighteenK]: 17000,
    [Burner.electricPlancha]: 0,
    [Burner.traditionalSimmerPlate]: 17000, // Traditional French plate
    [Burner.one18kBurner]: 17000,
    [Burner.two11kBurners]: 10000 * 2,
    [Burner.two15kBurners]: 13000 * 2,
    [Burner.flameGrill]: 12500,
    [Burner.multiCooker]: 0,
    [Burner.twoInductionRings]: 0,
    [Burner.stainlessSteelWorkstation]: 0,
    [Oven.electric]: 0,
    [Oven.convection]: 0,
    null: 0, // Burner not present
  },

  [Gas.naturalGas]: {
    [Oven.gas]: 13500,
    [Burner.fiveK]: 5000,
    [Burner.elevenK]: 11000,
    [Burner.fifteenK]: 15000,
    [Burner.eighteenK]: 18000,
    [Burner.electricPlancha]: 0,
    [Burner.traditionalSimmerPlate]: 18000, // Traditional French plate
    [Burner.one18kBurner]: 18000,
    [Burner.two11kBurners]: 11000 * 2,
    [Burner.two15kBurners]: 15000 * 2,
    [Burner.flameGrill]: 12500,
    [Burner.multiCooker]: 0,
    [Burner.twoInductionRings]: 0,
    [Burner.stainlessSteelWorkstation]: 0,
    [Oven.electric]: 0,
    [Oven.convection]: 0,
    null: 0, // Burner not present
  },

  [Gas.notSure]: {
    [Oven.gas]: 0,
    [Burner.fiveK]: 0,
    [Burner.elevenK]: 0,
    [Burner.fifteenK]: 0,
    [Burner.eighteenK]: 0,
    [Burner.electricPlancha]: 0,
    [Burner.traditionalSimmerPlate]: 0, // Traditional French plate
    [Burner.one18kBurner]: 0,
    [Burner.two11kBurners]: 0,
    [Burner.two15kBurners]: 0,
    [Burner.flameGrill]: 0,
    [Burner.multiCooker]: 0,
    [Burner.twoInductionRings]: 0,
    [Burner.stainlessSteelWorkstation]: 0,
    [Oven.electric]: 0,
    [Oven.convection]: 0,
    null: 0, // Burner not present
  },
};

export const burnerLookup = {
  [Burner.eighteenK]: '18,000 BTU burner',
  [Burner.one18kBurner]: '18,000 BTU burner',
  [Burner.fifteenK]: '15,000 BTU burner',
  [Burner.two15kBurners]: '15,000 BTU burner',
  [Burner.elevenK]: '11,000 BTU burner',
  [Burner.two11kBurners]: '11,000 BTU burner',
  [Burner.fiveK]: '5,000 BTU burner',
  [Burner.traditionalSimmerPlate]:
    'Traditional French Plate with 18,000 BTU burner',
  [Burner.flameGrill]: 'Flame Grill (includes ceramic briquettes)',
  [Burner.electricPlancha]: 'Electric Plancha',
  [Burner.multiCooker]: 'Multi-Cooker (requires water source and drainage)',
  [Burner.twoInductionRings]: 'Induction ring',
  [Burner.stainlessSteelWorkstation]: 'Stainless Steel Work Station',
};

export const colorLookup = {
  [Color.anthracite]: 'Anthracite',
  [Color.ardoise]: 'Ardoise',
  [Color.armor]: 'Armor',
  [Color.britishRacingGreen]: 'British Racing Green',
  [Color.burgundyRed]: 'Burgundy Red',
  [Color.chantilly]: 'Chantilly',
  [Color.chocolat]: 'Chocolat',
  [Color.coralBlue]: 'Coral Blue',
  [Color.delftBlue]: 'Delft Blue',
  [Color.englishCreme]: 'English Creme',
  [Color.faience]: 'Faience',
  [Color.frangipane]: 'Frangipane',
  [Color.frenchBlue]: 'French Blue',
  [Color.griotte]: 'Griotte',
  [Color.ivory]: 'Ivory',
  [Color.mandarine]: 'Mandarine',
  [Color.marronGlace]: 'Marron Glace',
  [Color.matteBlack]: 'Matte Black',
  [Color.olive]: 'Olive',
  [Color.plum]: 'Plum',
  [Color.portugueseBlue]: 'Portuguese Blue',
  [Color.provenceYellow]: 'Provence Yellow',
  [Color.roseQuartz]: 'Rose Quartz',
  [Color.shinyBlack]: 'Shiny Black',
  [Color.stainlessSteel]: 'Stainless Steel',
  [Color.terracotta]: 'Terracotta',
  [Color.tilleul]: 'Tilleul',
  [Color.vertSilice]: 'Vert Silice',
  [Color.vertSologne]: 'Vert Sologne',
  [Color.white]: 'White',
};

export const cupboardLookup = {
  [Cupboard.storage]: 'Storage |SIDE| Cupboard',
  [Cupboard.warming]: 'Warming |SIDE| Cupboard',
  [Oven.vertConv]: 'Vertical Electric Convection Oven (with broiler)',
};

export const gasLookup = {
  [Gas.naturalGas]: 'Natural Gas',
  [Gas.propane]: 'Propane',
  [Gas.notSure]: 'Not sure which gas type',
};

export const heightLookup = {
  [Height['35_38']]: '35 ⅜" range height',
  [Height['36']]: '36" range height',
  [Height['36_58']]: '36 ⅝" range height',
  [Height['37_316']]: '37 3/16" range height',
  [Height['37_1316']]: '37 13/16" range height',
  [Height['38_38']]: '38 ⅜" range height',
  [Height['39']]: '39" range height',
  [Height['39_916']]: '39 9/16" range height',
  [Height['40_316']]: '40 3/16" range height',
  [Height.notSure]: 'Not sure which range height',
  [Height.other]:
    'Range height options are: 35 ⅜", 36", 36 ⅝", 37 3/16", 37 13/16", 38 ⅜", 39", 39 9/16", and 40 3/16"',
};

export const lineLookup = {
  [Line.classique]: 'Classique',
  [Line.moderne]: 'Moderne',
};

export const modelLookup = {
  [Model.beaune]: 'Beaune',
  [Model.bussy]: 'Bussy',
  [Model.chagny]: 'Chagny',
  [Model.chagny1400left]: 'Chagny 1400 (Left)',
  [Model.chagny1400right]: 'Chagny 1400 (Right)',
  [Model.chagny1800]: 'Chagny 1800',
  [Model.chambertin]: 'Chambertin',
  [Model.chassagne]: 'Chassagne',
  [Model.citeaux]: 'Citeaux',
  [Model.cluny]: 'Cluny',
  [Model.cluny1400left]: 'Cluny 1400 (Left)',
  [Model.cluny1400right]: 'Cluny 1400 (Right)',
  [Model.cluny1800]: 'Cluny 1800',
  [Model.cormatin]: 'Cormatin',
  [Model.fontenay]: 'Fontenay',
  [Model.rully]: 'Rully',
  [Model.saulieu]: 'Saulieu',
  [Model.savigny]: 'Savigny',
  [Model.sully]: 'Sully',
  [Model.sully1800left]: 'Sully 1800 (Left)',
  [Model.sully1800right]: 'Sully 1800 (Right)',
  [Model.sully2200]: 'Sully 2200',
  [Model.volnay]: 'Volnay',
  [Model.vougeot]: 'Vougeot',
};

export const ovenLookup = {
  [Oven.convection]: 'Electric Convection',
  [Oven.electric]: 'Electric',
  [Oven.gas]: 'Gas',
};

export const rangeTopLookup = {
  [RangeTop.classique]: 'Classique',
  [RangeTop.traditional]: 'Traditional',
  [RangeTop.fourFeux]: '4 Feux',
  [RangeTop.classiqueRev]: 'Classique Reverse',
};

export const trimLookup = {
  [Trim.brass]: 'Brass',
  [Trim.brushedSS]: 'Stainless Steel',
  [Trim.chrome]: 'Chrome',
  [Trim.nickel]: 'Nickel',
};

export const widthLookup = {
  [Model.beaune]: '35 7/16"',
  [Model.bussy]: '35 7/16"',
  [Model.chagny]: '39 3/8"',
  [Model.chagny1400left]: '55 5/16"',
  [Model.chagny1400right]: '55 5/16"',
  [Model.chagny1800]: '71 1/16"',
  [Model.chambertin]: '43 1/2"',
  [Model.chassagne]: '43 1/2"',
  [Model.citeaux]: '59 1/4"',
  [Model.cluny]: '39 3/8"',
  [Model.cluny1400left]: '55 5/16"',
  [Model.cluny1400right]: '55 5/16"',
  [Model.cluny1800]: '71 1/16"',
  [Model.cormatin]: '27 9/16"',
  [Model.fontenay]: '59 1/4"',
  [Model.rully]: '27 9/16"',
  [Model.saulieu]: '43 1/2"',
  [Model.savigny]: '43 1/2"',
  [Model.sully]: '55 5/16"',
  [Model.sully1800left]: '71 1/16"',
  [Model.sully1800right]: '71 1/16"',
  [Model.sully2200]: '86 13/16"',
  [Model.volnay]: '39 3/8"',
  [Model.vougeot]: '39 3/8"',
};
