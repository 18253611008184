import { AssetLoader } from '../shared/AssetLoader';
import { Materials } from '../shared/Materials';
import { BaseBacksplash } from './BaseBacksplash';

export class Backsplash2200 extends BaseBacksplash {
  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   */
  constructor(assets, materials) {
    super(assets, materials);
    this.backsplash.name = 'backsplash-2200';
  }

  async init() {
    await this.loadModels(this.assets.backsplash2200Data);
    this.applyMaterials();
  }
}
