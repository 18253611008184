import { MeshStandardMaterial } from 'three';
import { AssetLoader } from './AssetLoader';

export class Metals {
  // Trims
  brassTrim;
  brushedSSTrim;
  chromeTrim;
  nickelTrim;

  // Other Metals
  brassCap;
  castAluminum;
  galvanizedSteel;
  galvanizedSteel2;
  stainlessSteel;
  stainlessSteelRough;
  ssRangeTop;

  // Logo
  logoBorder;
  logoPlate;

  // Enamel Cast Iron
  enamelCastIron;

  /** @param {AssetLoader} assets */
  constructor(assets) {
    /******************   Trims   ******************/

    this.brassTrim = new MeshStandardMaterial({
      color: 0xffeda0,
      metalness: 1,
      roughness: 0,
    });

    this.brushedSSTrim = new MeshStandardMaterial({
      color: 0xdbe2e9,
      metalness: 0.91,
      roughness: 0.1,
    });

    this.chromeTrim = new MeshStandardMaterial({
      map: assets.ssTexture,
      metalness: 1,
      roughness: 0.001,
    });

    this.nickelTrim = new MeshStandardMaterial({
      map: assets.nickelTexture,
      metalness: 1,
      roughness: 0.001,
    });

    /**************   Other Metals   ***************/

    this.brassCap = new MeshStandardMaterial({
      map: assets.brassCapTexture,
      metalness: 0.9,
      roughness: 0.1,
    });

    this.castAluminum = new MeshStandardMaterial({
      color: 0x808080,
      metalness: 0.5,
      roughness: 0.7,
    });

    this.galvanizedSteel = new MeshStandardMaterial({
      map: assets.backTexture,
      metalness: 1,
      roughness: 0.1,
    });

    // this.galvanizedSteel2 = new MeshStandardMaterial({
    //   map: assets.galvanizedColorTexture,
    //   aoMap: assets.galvanizedAmbientOcclusionTexture,
    //   roughnessMap: assets.galvanizedRoughnessTexture,
    //   metalnessMap: assets.galvanizedMetalicTexture,
    //   normalMap: assets.galvanizedNormalTexture,
    // });

    this.stainlessSteel = new MeshStandardMaterial({
      map: assets.ssTexture,
      metalness: 1,
      roughness: 0.1,
    });

    this.stainlessSteelRough = new MeshStandardMaterial({
      map: assets.ssTexture,
      metalness: 1,
      roughness: 0.1,
    });

    this.ssRangeTop = new MeshStandardMaterial({
      map: assets.ssTexture,
      metalness: 1,
      roughness: 0.1,
    });

    /******************   Logo   *******************/

    this.logoBorder = new MeshStandardMaterial({
      color: 0xe5cd9e,
      metalness: 0.9,
      roughness: 0.1,
    });

    // this.logoPlate = new MeshStandardMaterial({
    //   map: assets.lacancheLogoTexture,
    //   aoMap: assets.lacancheLogoAOTexture,
    //   aoMapIntensity: 3,
    //   metalness: 0.7,
    //   roughness: 0.1,
    // });

    this.logoPlate = new MeshStandardMaterial({
      map: assets.lacancheLogoAOTexture,
      aoMap: assets.lacancheLogoAOTexture,
      aoMapIntensity: 5,
      metalness: 1,
      roughness: 0.5,
    });

    /*************  Enamel Cast Iron  **************/

    this.enamelCastIron = new MeshStandardMaterial({
      map: assets.blackTexture,
      color: 0x5c5b5b,
      metalness: 0.5,
      roughness: 0.4,
    });
  }
}
