import {
  Camera,
  CameraHelper,
  DirectionalLight,
  DirectionalLightHelper,
  Light,
} from 'three';
import { gsap } from 'gsap';

import { Beaune } from './ranges/beaune/Beaune.js';
import { Bussy } from './ranges/bussy/Bussy.js';
import { Chagny } from './ranges/chagny/Chagny.js';
import { Chagny1400Left } from './ranges/chagny1400left/Chagny1400Left.js';
import { Chagny1400Right } from './ranges/chagny1400right/Chagny1400Right.js';
import { Chagny1800 } from './ranges/chagny1800/Chagny1800.js';
import { Chambertin } from './ranges/chambertin/Chambertin.js';
import { Chassagne } from './ranges/chassagne/Chassagne.js';
import { Citeaux } from './ranges/citeaux/Citeaux.js';
import { Cluny } from './ranges/cluny/Cluny.js';
import { Cluny1400Left } from './ranges/cluny1400left/Cluny1400Left.js';
import { Cluny1400Right } from './ranges/cluny1400right/Cluny1400Right.js';
import { Cluny1800 } from './ranges/cluny1800/Cluny1800.js';
import { Cormatin } from './ranges/cormatin/Cormatin.js';
import { Fontenay } from './ranges/fontenay/Fontenay.js';
import { Rully } from './ranges/rully/Rully.js';
import { Saulieu } from './ranges/saulieu/Saulieu.js';
import { Savigny } from './ranges/savigny/Savigny.js';
import { Sully } from './ranges/sully/Sully.js';
import { Sully2200 } from './ranges/sully2200/Sully2200.js';
import { Sully1800Left } from './ranges/sully1800left/Sully1800Left.js';
import { Sully1800Right } from './ranges/sully1800right/Sully1800Right.js';
import { Volnay } from './ranges/volnay/Volnay.js';
import { Vougeot } from './ranges/vougeot/Vougeot.js';
import { BaseRange } from './ranges/shared/BaseRange.js';

import { BaseHood } from './hoods/BaseHood.js';
import { Hood900 } from './hoods/Hood900.js';
import { Hood1100 } from './hoods/Hood1100.js';
import { Hood1200 } from './hoods/Hood1200.js';
import { Hood1300 } from './hoods/Hood1300.js';
import { Hood1600 } from './hoods/Hood1600.js';
import { Hood1700 } from './hoods/Hood1700.js';
import { Hood2000 } from './hoods/Hood2000.js';
import { Hood2400 } from './hoods/Hood2400.js';

import { BaseBacksplash } from './backsplashes/BaseBacksplash.js';
import { Backsplash700 } from './backsplashes/Backsplash700.js';
import { Backsplash900 } from './backsplashes/Backsplash900.js';
import { Backsplash1000 } from './backsplashes/Backsplash1000.js';
import { Backsplash1100 } from './backsplashes/Backsplash1100.js';
import { Backsplash1400 } from './backsplashes/Backsplash1400.js';
import { Backsplash1500 } from './backsplashes/Backsplash1500.js';
import { Backsplash1800 } from './backsplashes/Backsplash1800.js';
import { Backsplash2200 } from './backsplashes/Backsplash2200.js';

import { KnobModels } from './ranges/shared/KnobModels.js';
import { RangeParts } from './ranges/parts/RangeParts.js';
import { RangeTopOptions } from './ranges/shared/RangeTopOptions.js';
import { AssetLoader } from './shared/AssetLoader.js';
import {
  Burner,
  Color,
  Cupboard,
  Gas,
  Height,
  Line,
  Model,
  Oven,
  RangeTop,
  Spacer,
  Trim,
} from './shared/Enums.js';
import { loadEnvMap } from './shared/loadEnvMap.js';
import { Materials } from './shared/Materials.js';
import { LacancheLogo } from './shared/SharedParts.js';
import { State } from './shared/State.js';
import { Summary } from './shared/Summary.js';
import { Url } from './shared/Url.js';

import { createCamera } from './components/camera.js';
import { createLights } from './components/lights.js';
import { createScene } from './components/scene.js';

import { createControls } from './systems/controls.js';
import { createRenderer } from './systems/renderer.js';
import { HelperGUI } from './systems/HelperGUI.js';
import { Loop } from './systems/Loop.js';
import { Resizer } from './systems/Resizer.js';

export class World {
  /** @type {AssetLoader} */
  #assets;

  /** @type {BaseBacksplash} */
  #backsplash;

  /** @type {BaseHood} */
  #hood;

  /** @type {KnobModels} */
  #knobModels;

  /** @type {Materials} */
  #materials;

  /** @type {BaseRange} */
  #range;

  /** @type {RangeTopOptions} */
  #rangeTopOptions;

  /** @type {RangeParts} */
  #rangeParts;

  /** @type {LacancheLogo} */
  #sharedParts;

  /** @type {State} */
  #state;

  /** @type {Summary} */
  #summary;

  /** @type {Url} */
  #url;

  #camera;
  #controls;
  #loop;
  #renderer;
  #scene;

  #hoodCreator;
  #backsplashCreator;

  #showing = {
    hood: false,
    backsplash: false,
  };

  // @ts-ignore
  #gui;

  constructor(container) {
    this.#url = new Url();
    this.#assets = new AssetLoader();
    this.#camera = createCamera(this.#url);
    this.#renderer = createRenderer();
    this.#scene = createScene(this.#url);
    this.#scene.position.y = -0.5;
    this.#loop = new Loop(this.#camera, this.#scene, this.#renderer);
    this.#gui = new HelperGUI();
    // this.#gui.gui.hide();

    container.appendChild(this.#renderer.domElement);
    this.#controls = createControls(this.#camera, this.#renderer.domElement);

    const { frontLeftLight, frontRightLight, hemisphereLight } = createLights();

    if (!this.#url.isScreenshot()) {
      this.#loop.updatables.push(this.#controls, this.#gui);
    }

    this.#scene.add(frontLeftLight, frontRightLight, hemisphereLight);

    // @ts-ignore
    const resizer = new Resizer(container, this.#camera, this.#renderer);

    this.#gui.takeScreenshot();
    this.#gui.addCamera(this.#camera);
    // this.#gui.addHemiLight(hemisphereLight);
    // this.#gui.addLeftLight(frontLeftLight);
    // this.#gui.addRightLight(frontRightLight);
    // this.#gui.exportGLB(this.#scene);

    // this.#addCameraHelper(this.#camera);
    // this.#addDirectionalLightHelper(leftLight);
    // this.#addShadowHelper(rightLight);
  }

  async init() {
    let configuredRange, line, model, requestedRange;

    await this.#assets.loadMinimum();

    this.#materials = new Materials(this.#assets);
    this.#rangeTopOptions = new RangeTopOptions(this.#assets, this.#materials);
    this.#knobModels = new KnobModels(this.#assets, this.#materials);
    this.#rangeParts = new RangeParts(this.#assets, this.#materials);
    this.#sharedParts = new LacancheLogo(this.#assets, this.#materials);
    this.#state = new State();

    if ((requestedRange = this.#url.requestedRange())) {
      model = requestedRange;
    } else if ((configuredRange = this.#url.configuredRange())) {
      model = configuredRange.model;
      this.#state.setState(configuredRange);
    } else {
      model = Model.sully;
    }

    if ((line = this.#url.requestedLine())) {
      this.#state.line = line;
      this.#state.trim = line === Line.moderne ? Trim.chrome : Trim.brass;
      this.#updateTrimOptions(line);
    }

    [this.#range, this.#hoodCreator, this.#backsplashCreator] =
      await this.#switchRange(model);

    this.#loop.updatables.push(this.#range.labels);

    // TODO: Clean this up
    this.#state.camera = this.#camera;

    // this.#gui.addMaterial(this.#range);
    // this.#gui.addLogo(this.#range);

    if (!this.#url.isScreenshot()) {
      this.initializeEventListeners();
    }

    loadEnvMap(this.#scene, () => {
      this.#gui.addEnvironmentMap(this.#scene);
    });

    // Use the following DIV to notify Browsershot that the range is loaded.
    if (this.#url.isScreenshot()) {
      setTimeout(() => {
        this.#renderer.render(this.#scene, this.#camera);
        const rangeLoadedDiv = document.createElement('div');
        rangeLoadedDiv.id = 'range-loaded';
        document.body.appendChild(rangeLoadedDiv);
      }, 5_000);
    }
  }

  initializeEventListeners() {
    document.getElementById('model').addEventListener('change', async (e) => {
      // @ts-ignore
      const model = e.target.value;

      this.#state.isConfigured = false;

      // Remove the old range and its labels from the scene
      this.#scene.remove(
        this.#range.range,
        this.#range.labels.front,
        this.#range.labels.top
      );

      [this.#range, this.#hoodCreator, this.#backsplashCreator] =
        await this.#switchRange(model);

      if (this.#hood) {
        this.#removeHood();
      }

      if (this.#backsplash) {
        this.#removeBacksplash();
      }

      // Reset the URL
      history.replaceState(null, '', '/');
    });

    // Change the line
    document.getElementById('line').addEventListener('change', (e) => {
      // @ts-ignore
      const line = e.target.value;

      this.#state.line = line;
      this.#state.isConfigured = false;
      this.#range.changeLine(line);

      if (
        line === Line.moderne &&
        ![Trim.brushedSS, Trim.chrome].includes(this.#state.trim)
      ) {
        this.#range.changeTrim(Trim.chrome);
        this.#state.trim = Trim.chrome;
      }

      this.#updateTrimOptions(line);
      this.#updateSummary();
    });

    // Change the base range top
    document.getElementById('top').addEventListener('change', (e) => {
      // @ts-ignore
      const top = e.target.value;
      const leftOptions = document.getElementById('left-option');
      // @ts-ignore
      const selectedOption = leftOptions.value;
      // @ts-ignore
      const options = leftOptions.options;

      this.#state.top = top;
      this.#state.isConfigured = false;
      this.#range.changeRangeTop(top);

      if (top === RangeTop.fourFeux) {
        // Enable the 18k burner, plancha and induction rings options
        options[3].disabled = false;
        options[4].disabled = false;
        options[5].disabled = false;
      } else {
        // Disable the 18k burner, plancha and induction rings options
        options[3].disabled = true;
        options[4].disabled = true;
        options[5].disabled = true;

        if (
          [
            Burner.one18kBurner,
            Burner.electricPlancha,
            Burner.twoInductionRings,
          ].includes(selectedOption)
        ) {
          // Select stainless steel workstation
          options[6].selected = true;
          this.#range.changeOption2(Burner.stainlessSteelWorkstation);
        }
      }

      this.#updateSummary();
    });

    // Change the trim
    document.getElementById('trim').addEventListener('change', (e) => {
      // @ts-ignore
      const trim = e.target.value;

      this.#state.trim = trim;
      this.#state.isConfigured = false;
      this.#range.changeTrim(trim);

      this.#updateSummary();
    });

    // Change the color
    document.getElementById('color').addEventListener('change', (e) => {
      // @ts-ignore
      const color = e.target.value;

      this.#state.color = color;
      this.#state.isConfigured = false;

      this.#range.changeColor(color);
      if (this.#hood) {
        this.#hood.changeColor(color);
      }

      this.#updateSummary();

      // this.#gui.changeMaterial(this.#range);
    });

    // Change the left oven
    document.getElementById('left-oven').addEventListener('change', (e) => {
      // @ts-ignore
      const ovenType = e.target.value;

      this.#state.leftOven = ovenType;
      this.#state.isConfigured = false;
      this.#range.changeLeftOven(ovenType);

      this.#updateSummary();
    });

    // Change the right oven
    document.getElementById('right-oven').addEventListener('change', (e) => {
      // @ts-ignore
      const ovenType = e.target.value;

      this.#state.rightOven = ovenType;
      this.#state.isConfigured = false;
      this.#range.changeRightOven(ovenType);

      this.#updateSummary();
    });

    // Change the back spacer
    document.getElementById('back-spacer').addEventListener('change', (e) => {
      // @ts-ignore
      const spacerElevation = e.target.value;

      this.#state.isConfigured = false;

      this.#range.changeSpacer(spacerElevation);
      this.#state.spacer = spacerElevation;
    });

    // Change the far left range top option
    document
      .getElementById('far-left-option')
      .addEventListener('change', (e) => {
        // @ts-ignore
        const farLeftOption = e.target.value;
        const leftCupboardOptions = // @ts-ignore
          document.getElementById('left-cupboard').options;

        this.#state.option1 = farLeftOption;
        this.#state.isConfigured = false;
        this.#range.changeOption1(farLeftOption);

        // A multi-cooker needs to be over a storage cupboard
        if (farLeftOption === Burner.multiCooker) {
          leftCupboardOptions[1].selected = true;
          this.#range.changeLeftCupboard(Cupboard.storage);
        }

        this.#updateSummary();
      });

    // Change the left range top option
    document.getElementById('left-option').addEventListener('change', (e) => {
      // @ts-ignore
      const leftOption = e.target.value;
      // @ts-ignore
      const model = document.getElementById('model').value;

      switch (model) {
        case Model.cluny1800:
        case Model.sully1800left:
        case Model.sully2200:
          this.#state.option2 = leftOption;
          this.#range.changeOption2(leftOption);
          break;

        case Model.sully:
        case Model.sully1800right:
          this.#state.option1 = leftOption;
          this.#range.changeOption1(leftOption);
          break;
      }

      this.#state.isConfigured = false;

      this.#updateSummary();
    });

    // Change the right range top option
    document.getElementById('right-option').addEventListener('change', (e) => {
      // @ts-ignore
      const rightOption = e.target.value;
      // @ts-ignore
      const model = document.getElementById('model').value;

      switch (model) {
        case Model.sully1800left:
        case Model.sully2200:
          this.#state.option3 = rightOption;
          this.#range.changeOption3(rightOption);
          break;

        case Model.sully:
        case Model.sully1800right:
          this.#state.option2 = rightOption;
          this.#range.changeOption2(rightOption);
          break;
      }

      this.#state.isConfigured = false;

      this.#updateSummary();
    });

    // Change the far right range top option
    document
      .getElementById('far-right-option')
      .addEventListener('change', (e) => {
        // @ts-ignore
        const farRightOption = e.target.value;
        const rightCupboard = document.getElementById('right-cupboard');
        // @ts-ignore
        const model = document.getElementById('model').value;

        switch (model) {
          case Model.chagny1400right:
          case Model.chambertin:
          case Model.cluny1400right:
          case Model.savigny:
            this.#state.option1 = farRightOption;
            this.#range.changeOption1(farRightOption);
            break;
          case Model.chagny1800:
          case Model.citeaux:
          case Model.cluny1800:
          case Model.fontenay:
            this.#state.option2 = farRightOption;
            this.#range.changeOption2(farRightOption);
            break;
          case Model.sully1800right:
            this.#state.option3 = farRightOption;
            this.#range.changeOption3(farRightOption);
            break;
          case Model.sully2200:
            this.#state.option4 = farRightOption;
            this.#range.changeOption4(farRightOption);
            break;
        }

        // A multi-cooker needs to be over a storage cupboard
        if (farRightOption === Burner.multiCooker) {
          // @ts-ignore
          rightCupboard.options[1].selected = true;
          this.#range.changeRightCupboard(Cupboard.storage);
        }

        this.#state.isConfigured = false;

        this.#updateSummary();
      });

    // Change the left cupboard
    document.getElementById('left-cupboard').addEventListener('change', (e) => {
      // @ts-ignore
      const leftCupboard = e.target.value;

      this.#state.leftCupboard = leftCupboard;
      this.#state.isConfigured = false;
      this.#range.changeLeftCupboard(leftCupboard);

      this.#updateSummary();
    });

    // Change the right cupboard
    document
      .getElementById('right-cupboard')
      .addEventListener('change', (e) => {
        // @ts-ignore
        const rightCupboard = e.target.value;

        this.#state.rightCupboard = rightCupboard;
        this.#state.isConfigured = false;
        this.#range.changeRightCupboard(rightCupboard);

        this.#updateSummary();
      });

    // Change the gas type
    document.getElementById('gas-type').addEventListener('change', (e) => {
      // @ts-ignore
      const gasType = e.target.value;

      this.#state.gas = gasType;
      this.#state.isConfigured = false;

      this.#updateSummary();
    });

    // Change the range height
    document.getElementById('height').addEventListener('change', (e) => {
      // @ts-ignore
      const rangeHeight = e.target.value;

      this.#state.height = rangeHeight;
      this.#state.isConfigured = false;

      this.#updateSummary();
    });

    // Rotate the range to the side view
    document.getElementById('side-view-btn').addEventListener('click', (e) => {
      const frontViewBtn = document.getElementById('front-view-btn');
      const topViewBtn = document.getElementById('top-view-btn');

      // @ts-ignore
      e.target.classList.add('border-2', 'border-[#203b44]');
      frontViewBtn.classList.remove('border-2', 'border-[#203b44]');
      topViewBtn.classList.remove('border-2', 'border-[#203b44]');

      gsap.to(this.#camera.position, {
        duration: 0.5,
        x: -2.7,
        y: 1.5,
        z: 3.6,
      });

      gsap.to(this.#camera, {
        duration: 0.5,
        zoom: 1,
        onUpdate: () => {
          this.#camera.updateProjectionMatrix();
        },
      });
    });

    // Rotate the range to the front view
    document.getElementById('front-view-btn').addEventListener('click', (e) => {
      const sideViewBtn = document.getElementById('side-view-btn');
      const topViewBtn = document.getElementById('top-view-btn');

      // @ts-ignore
      e.target.classList.add('border-2', 'border-[#203b44]');
      sideViewBtn.classList.remove('border-2', 'border-[#203b44]');
      topViewBtn.classList.remove('border-2', 'border-[#203b44]');

      gsap.to(this.#camera.position, {
        duration: 0.5,
        x: 0,
        y: 0.4,
        z: 4.7,
      });

      gsap.to(this.#camera, {
        duration: 0.5,
        zoom: 1,
        onUpdate: () => {
          this.#camera.updateProjectionMatrix();
        },
      });
    });

    // Rotate the range to the top view
    document.getElementById('top-view-btn').addEventListener('click', (e) => {
      const sideViewBtn = document.getElementById('side-view-btn');
      const frontViewBtn = document.getElementById('front-view-btn');

      // @ts-ignore
      e.target.classList.add('border-2', 'border-[#203b44]');
      sideViewBtn.classList.remove('border-2', 'border-[#203b44]');
      frontViewBtn.classList.remove('border-2', 'border-[#203b44]');

      gsap.to(this.#camera.position, {
        duration: 0.75,
        x: 0,
        y: 5,
        z: 0.2,
      });

      gsap.to(this.#camera, {
        duration: 0.75,
        zoom: 1,
        onUpdate: () => {
          this.#camera.updateProjectionMatrix();
        },
      });
    });

    // Zoom in to the range
    document.getElementById('zoom-in-btn').addEventListener('click', () => {
      const newZoom = Math.min(this.#camera.zoom + 0.25, 5);

      gsap.to(this.#camera, {
        duration: 0.5,
        zoom: newZoom,
        onUpdate: () => {
          this.#camera.updateProjectionMatrix();
        },
      });
    });

    // Zoom out from the range
    document.getElementById('zoom-out-btn').addEventListener('click', () => {
      const newZoom = Math.max(this.#camera.zoom - 0.25, 1);

      gsap.to(this.#camera, {
        duration: 0.5,
        zoom: newZoom,
        onUpdate: () => {
          this.#camera.updateProjectionMatrix();
        },
      });
    });

    // Hide/Show the range labels
    document.getElementById('show-labels').addEventListener('change', (e) => {
      // @ts-ignore
      if (e.target.checked) {
        this.#range.labels.front.visible = true;
        this.#range.labels.top.visible = true;
      } else {
        this.#range.labels.front.visible = false;
        this.#range.labels.top.visible = false;
      }
    });

    // Show the price request form
    document.getElementById('next-step-btn').addEventListener('click', (e) => {
      document.getElementById('req-form-block').style.display = 'flex';
      document.getElementById('req-form-intro').scrollIntoView();
      // @ts-ignore
      e.target.style.display = 'none'; // Hide the 'Next Step' button
    });
  }

  async init2() {
    if (this.#url.isScreenshot()) {
      this.#loop.stop();
      return;
    }

    // Show/Hide the hood
    document.getElementById('hood').addEventListener('change', async (e) => {
      /** @type HTMLInputElement */
      // @ts-ignore
      const checkbox = e.target;
      // @ts-ignore
      const hoodInsertOptions = document.getElementById('hood-insert').options;

      if (checkbox.checked && this.#hoodCreator) {
        // Create the hood
        this.#hood = this.#hoodCreator();
        await this.#hood.init();
        // Add the hood and zoom the camera out to show the range and hood
        this.#scene.add(this.#hood.hood);
        this.#camera.position.set(0, 1, 7);
        this.#scene.position.y = -1.4;
        this.#showing.hood = true;
        this.#showHoodOptions();
      } else if (!checkbox.checked && this.#showing.backsplash) {
        // Remove the hood and zoom the camera in on the range and backsplash
        this.#scene.remove(this.#scene.getObjectByName(this.#hood.hood.name));
        this.#hood = null;
        this.#camera.position.set(0, 1, 5);
        this.#scene.position.y = -0.9;
        this.#showing.hood = false;
        this.#hideHoodOptions();
      } else if (!checkbox.checked && this.#showing.hood) {
        // Remove the hood and zoom the camera in on the range
        this.#scene.remove(this.#scene.getObjectByName(this.#hood.hood.name));
        this.#hood = null;
        this.#camera.position.set(0, 1.5, 4.5);
        this.#scene.position.y = -0.5;
        this.#showing.hood = false;
        this.#hideHoodOptions();
      }

      if (
        this.#hood?.hood.name === 'hood-900' ||
        this.#hood?.hood.name === 'hood-1100'
      ) {
        hoodInsertOptions[0].selected = true;
        hoodInsertOptions[1].disabled = true;
      } else {
        hoodInsertOptions[1].disabled = false;
      }
    });

    // Change the hood insert
    document.getElementById('hood-insert').addEventListener('change', (e) => {
      // @ts-ignore
      this.#hood.changeInsert(e.target.value);
    });

    // Change the hood duct cover finish
    document
      .getElementById('duct-cover-finish')
      .addEventListener('change', (e) => {
        // @ts-ignore
        const ductCoverFinish = e.target.value;

        this.#hood.changeDuctCoverFinish(ductCoverFinish);
      });

    // Change the hood duct cover height
    document
      .getElementById('duct-cover-height')
      .addEventListener('change', (e) => {
        // @ts-ignore
        const ductCoverHeight = e.target.value;

        this.#hood.changeDuctCoverHeight(ductCoverHeight);
      });

    // Show/Hide the backsplash
    document
      .getElementById('backsplash')
      .addEventListener('change', async (e) => {
        /** @type HTMLInputElement */
        // @ts-ignore
        const checkbox = e.target;

        if (checkbox.checked && this.#backsplashCreator) {
          this.#backsplash = this.#backsplashCreator();
          await this.#backsplash.init();
          // Add the backsplash
          this.#scene.add(this.#backsplash.backsplash);
          this.#showing.backsplash = true;
          if (!this.#showing.hood) {
            // Zoom the camera out to show the range and backsplash
            this.#camera.position.set(0, 1, 5);
            this.#scene.position.y = -0.9;
          }
        } else if (!checkbox.checked && this.#showing.backsplash) {
          // Remove the backsplash
          this.#scene.remove(
            this.#scene.getObjectByName(this.#backsplash.backsplash.name)
          );
          this.#showing.backsplash = false;
          if (!this.#showing.hood) {
            // Zoom the camera in on the range
            this.#camera.position.set(0, 1.5, 4.5);
            this.#scene.position.y = -0.5;
          }
        }
      });

    const priceRequestForm = document.getElementById('price-quote-form');
    priceRequestForm.addEventListener('submit', (e) => {
      e.preventDefault();
      // @ts-ignore
      const priceRequestData = new FormData(e.target);
      priceRequestData.set('model', this.#state.model);
      priceRequestData.set('line', this.#state.line);
      priceRequestData.set('top', this.#state.top);
      priceRequestData.set('trim', this.#state.trim);
      priceRequestData.set('color', this.#state.color);
      priceRequestData.set('leftOven', this.#state.leftOven);
      if (this.#state.rightOven !== null) {
        priceRequestData.set('rightOven', this.#state.rightOven);
      }
      if (this.#state.petiteOven !== null) {
        priceRequestData.set('petiteOven', this.#state.petiteOven);
      }
      if (this.#state.leftCupboard !== null) {
        priceRequestData.set('leftCupboard', this.#state.leftCupboard);
      }
      if (this.#state.rightCupboard !== null) {
        priceRequestData.set('rightCupboard', this.#state.rightCupboard);
      }
      if (this.#state.option1 !== null) {
        priceRequestData.set('option1', this.#state.option1);
      }
      if (this.#state.option2 !== null) {
        priceRequestData.set('option2', this.#state.option2);
      }
      if (this.#state.option3 !== null) {
        priceRequestData.set('option3', this.#state.option3);
      }
      if (this.#state.option4 !== null) {
        priceRequestData.set('option4', this.#state.option4);
      }
      priceRequestData.set('gas', this.#state.gas);
      priceRequestData.set('height', this.#state.height);

      fetch('https://configure.frenchranges.com/api/price-quote', {
        method: 'POST',
        body: priceRequestData,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.success) {
            const successMessage = document.getElementById(
              'price-quote-success'
            );
            successMessage.style.display = 'block';
            successMessage.innerText = json.success;

            // @ts-ignore
            priceRequestForm.reset();

            setTimeout(() => {
              successMessage.style.display = 'none';
            }, 30_000);
          }
        })
        .catch((error) => console.error(error));
    });
  }

  render() {
    this.#renderer.render(this.#scene, this.#camera);
  }

  start() {
    this.#loop.start();
  }

  stop() {
    this.#loop.stop();
  }

  /**
   * For a given range model, return the range, hood creator and backsplash
   * creator. Update the UI for the new range model.
   * @param {?string} model
   * @returns {Promise<[BaseRange, Function, Function]>}
   */
  async #switchRange(model) {
    const [range, hoodCreator, backsplashCreator] =
      this.#get3DModelCreatorsForRangeModel(model);

    await range.init();

    range.changeLine(this.#state.line);
    range.changeRangeTop(this.#state.top);
    range.changeTrim(this.#state.trim);
    range.changeColor(this.#state.color);
    range.changeSpacer(this.#state.spacer);

    this.#scene.add(range.range, range.labels.front, range.labels.top);

    this.#resetBurnerOptionSelections();
    this.#showRangeOptions(range);
    this.#assignBurnerOptions(range);
    this.#updateModelSelection();
    this.#updateLineSelection();
    this.#updateRangeTopSelection();
    this.#updateTrimSelection();
    this.#updateColorSelection();
    this.#updateFarLeftOptionSelection();
    this.#updateLeftOptionSelection();
    this.#updateRightOptionSelection();
    this.#updateFarRightOptionSelection();
    this.#updateLeftOvenSelection();
    this.#updateRightOvenSelection();
    this.#updateLeftCupboardSelection();
    this.#updateRightCupboardSelection();
    this.#updateHeightSelection();
    this.#updateGasTypeSelection();
    this.#updateBackSpacerSelection();

    this.#summary = new Summary(this.#state);
    this.#updateSummary();

    return [range, hoodCreator, backsplashCreator];
  }

  /**
   * For a given range model, return the range, hood creator and backsplash creator
   * @param {?string} model
   * @returns {[BaseRange, Function, Function]}
   */
  #get3DModelCreatorsForRangeModel(model) {
    let range, hoodCreator, backsplashCreator;

    switch (model) {
      case Model.beaune:
        range = this.#createBeaune();
        hoodCreator = this.#createHood1100;
        backsplashCreator = this.#createBacksplash900;
        break;
      case Model.bussy:
        range = this.#createBussy();
        hoodCreator = this.#createHood1100;
        backsplashCreator = this.#createBacksplash900;
        break;
      case Model.chagny:
        range = this.#createChagny();
        hoodCreator = this.#createHood1200;
        backsplashCreator = this.#createBacksplash1000;
        break;
      case Model.chagny1400left:
        range = this.#createChagny1400Left();
        hoodCreator = this.#createHood1600;
        backsplashCreator = this.#createBacksplash1400;
        break;
      case Model.chagny1400right:
        range = this.#createChagny1400Right();
        hoodCreator = this.#createHood1600;
        backsplashCreator = this.#createBacksplash1400;
        break;
      case Model.chagny1800:
        range = this.#createChagny1800();
        hoodCreator = this.#createHood2000;
        backsplashCreator = this.#createBacksplash1800;
        break;
      case Model.chambertin:
        range = this.#createChambertin();
        hoodCreator = this.#createHood1300;
        backsplashCreator = this.#createBacksplash1100;
        break;
      case Model.chassagne:
        range = this.#createChassagne();
        hoodCreator = this.#createHood1300;
        backsplashCreator = this.#createBacksplash1100;
        break;
      case Model.citeaux:
        range = this.#createCiteaux();
        hoodCreator = this.#createHood1700;
        backsplashCreator = this.#createBacksplash1500;
        break;
      case Model.cluny:
        range = this.#createCluny();
        hoodCreator = this.#createHood1200;
        backsplashCreator = this.#createBacksplash1000;
        break;
      case Model.cluny1400left:
        range = this.#createCluny1400Left();
        hoodCreator = this.#createHood1600;
        backsplashCreator = this.#createBacksplash1400;
        break;
      case Model.cluny1400right:
        range = this.#createCluny1400Right();
        hoodCreator = this.#createHood1600;
        backsplashCreator = this.#createBacksplash1400;
        break;
      case Model.cluny1800:
        range = this.#createCluny1800();
        hoodCreator = this.#createHood2000;
        backsplashCreator = this.#createBacksplash1800;
        break;
      case Model.cormatin:
        range = this.#createCormatin();
        hoodCreator = this.#createHood900;
        backsplashCreator = this.#createBacksplash700;
        break;
      case Model.fontenay:
        range = this.#createFontenay();
        hoodCreator = this.#createHood1700;
        backsplashCreator = this.#createBacksplash1500;
        break;
      case Model.rully:
        range = this.#createRully();
        hoodCreator = this.#createHood900;
        backsplashCreator = this.#createBacksplash700;
        break;
      case Model.saulieu:
        range = this.#createSaulieu();
        hoodCreator = this.#createHood1300;
        backsplashCreator = this.#createBacksplash1100;
        break;
      case Model.savigny:
        range = this.#createSavigny();
        hoodCreator = this.#createHood1300;
        backsplashCreator = this.#createBacksplash1100;
        break;
      case Model.sully:
        range = this.#createSully();
        hoodCreator = this.#createHood1600;
        backsplashCreator = this.#createBacksplash1400;
        break;
      case Model.sully1800left:
        range = this.#createSully1800Left();
        hoodCreator = this.#createHood2000;
        backsplashCreator = this.#createBacksplash1800;
        break;
      case Model.sully1800right:
        range = this.#createSully1800Right();
        hoodCreator = this.#createHood2000;
        backsplashCreator = this.#createBacksplash1800;
        break;
      case Model.sully2200:
        range = this.#createSully2200();
        hoodCreator = this.#createHood2400;
        backsplashCreator = this.#createBacksplash2200;
        break;
      case Model.volnay:
        range = this.#createVolnay();
        hoodCreator = this.#createHood1200;
        backsplashCreator = this.#createBacksplash1000;
        break;
      case Model.vougeot:
        range = this.#createVougeot();
        hoodCreator = this.#createHood1200;
        backsplashCreator = this.#createBacksplash1000;
        break;
      default:
        range = this.#createSully();
        hoodCreator = this.#createHood1600;
        backsplashCreator = this.#createBacksplash1400;
        break;
    }

    return [range, hoodCreator, backsplashCreator];
  }

  /***************        Create Ranges        ***************/

  /**
   * Create a Beaune range
   * @returns {Beaune}
   */
  #createBeaune() {
    this.#state.model = Model.beaune;

    return new Beaune(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Bussy range
   * @returns {Bussy}
   */
  #createBussy() {
    this.#state.model = Model.bussy;

    return new Bussy(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Chagny range
   * @returns {Chagny}
   */
  #createChagny() {
    this.#state.model = Model.chagny;

    return new Chagny(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Chagny 1400 (left) range
   * @returns {Chagny1400Left}
   */
  #createChagny1400Left() {
    this.#state.model = Model.chagny1400left;

    return new Chagny1400Left(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Chagny 1400 (right) range
   * @returns {Chagny1400Right}
   */
  #createChagny1400Right() {
    this.#state.model = Model.chagny1400right;

    return new Chagny1400Right(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Chagny range
   * @returns {Chagny1800}
   */
  #createChagny1800() {
    this.#state.model = Model.chagny1800;

    return new Chagny1800(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Chambertin range
   * @returns {Chambertin}
   */
  #createChambertin() {
    this.#state.model = Model.chambertin;

    return new Chambertin(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Chassagne range
   * @returns {Chassagne}
   */
  #createChassagne() {
    this.#state.model = Model.chassagne;

    return new Chassagne(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Citeaux range
   * @returns {Citeaux}
   */
  #createCiteaux() {
    this.#state.model = Model.citeaux;

    return new Citeaux(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Cluny range
   * @returns {Cluny}
   */
  #createCluny() {
    this.#state.model = Model.cluny;

    return new Cluny(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Cluny 1400 (left) range
   * @returns {Cluny1400Left}
   */
  #createCluny1400Left() {
    this.#state.model = Model.cluny1400left;

    return new Cluny1400Left(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Cluny 1400 (right) range
   * @returns {Cluny1400Right}
   */
  #createCluny1400Right() {
    this.#state.model = Model.cluny1400right;

    return new Cluny1400Right(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Cluny 1800 range
   * @returns {Cluny1800}
   */
  #createCluny1800() {
    this.#state.model = Model.cluny1800;

    return new Cluny1800(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Cormatin range
   * @returns {Cormatin}
   */
  #createCormatin() {
    this.#state.model = Model.cormatin;

    return new Cormatin(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Fontenay range
   * @returns {Fontenay}
   */
  #createFontenay() {
    this.#state.model = Model.fontenay;

    return new Fontenay(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Rully range
   * @returns {Rully}
   */
  #createRully() {
    this.#state.model = Model.rully;

    return new Rully(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Saulieu range
   * @returns {Saulieu}
   */
  #createSaulieu() {
    this.#state.model = Model.saulieu;

    return new Saulieu(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Savigny range
   * @returns {Savigny}
   */
  #createSavigny() {
    this.#state.model = Model.savigny;

    return new Savigny(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Sully range
   * @returns {Sully}
   */
  #createSully() {
    this.#state.model = Model.sully;

    return new Sully(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Sully 1800 (left) range
   * @returns {Sully1800Left}
   */
  #createSully1800Left() {
    this.#state.model = Model.sully1800left;

    return new Sully1800Left(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Sully 1800 (right) range
   * @returns {Sully1800Right}
   */
  #createSully1800Right() {
    this.#state.model = Model.sully1800right;

    return new Sully1800Right(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Sully 2200 range
   * @returns {Sully2200}
   */
  #createSully2200() {
    this.#state.model = Model.sully2200;

    return new Sully2200(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Volnay range
   * @returns {Volnay}
   */
  #createVolnay() {
    this.#state.model = Model.volnay;

    return new Volnay(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /**
   * Create a Vougeot range
   * @returns {Vougeot}
   */
  #createVougeot() {
    this.#state.model = Model.vougeot;

    return new Vougeot(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels,
      this.#state
    );
  }

  /***************           Create Hoods            ***************/

  #createHood900() {
    // @ts-ignore
    const color = document.getElementById('color').value;

    return new Hood900(this.#assets, this.#materials, this.#sharedParts, color);
  }

  #createHood1100() {
    // @ts-ignore
    const color = document.getElementById('color').value;

    return new Hood1100(
      this.#assets,
      this.#materials,
      this.#sharedParts,
      color
    );
  }

  #createHood1200() {
    // @ts-ignore
    const color = document.getElementById('color').value;

    return new Hood1200(
      this.#assets,
      this.#materials,
      this.#sharedParts,
      color
    );
  }

  #createHood1300() {
    // @ts-ignore
    const color = document.getElementById('color').value;

    return new Hood1300(
      this.#assets,
      this.#materials,
      this.#sharedParts,
      color
    );
  }

  #createHood1600() {
    // @ts-ignore
    const color = document.getElementById('color').value;

    return new Hood1600(
      this.#assets,
      this.#materials,
      this.#sharedParts,
      color
    );
  }

  #createHood1700() {
    // @ts-ignore
    const color = document.getElementById('color').value;

    return new Hood1700(
      this.#assets,
      this.#materials,
      this.#sharedParts,
      color
    );
  }

  #createHood2000() {
    // @ts-ignore
    const color = document.getElementById('color').value;

    return new Hood2000(
      this.#assets,
      this.#materials,
      this.#sharedParts,
      color
    );
  }

  #createHood2400() {
    // @ts-ignore
    const color = document.getElementById('color').value;

    return new Hood2400(
      this.#assets,
      this.#materials,
      this.#sharedParts,
      color
    );
  }

  /***************        Create Backsplashes        ***************/

  #createBacksplash700() {
    return new Backsplash700(this.#assets, this.#materials);
  }

  #createBacksplash900() {
    return new Backsplash900(this.#assets, this.#materials);
  }

  #createBacksplash1000() {
    return new Backsplash1000(this.#assets, this.#materials);
  }

  #createBacksplash1100() {
    return new Backsplash1100(this.#assets, this.#materials);
  }

  #createBacksplash1400() {
    return new Backsplash1400(this.#assets, this.#materials);
  }

  #createBacksplash1500() {
    return new Backsplash1500(this.#assets, this.#materials);
  }

  #createBacksplash1800() {
    return new Backsplash1800(this.#assets, this.#materials);
  }

  #createBacksplash2200() {
    return new Backsplash2200(this.#assets, this.#materials);
  }

  /**
   * Disable the options of brass and nickel for moderne ranges
   * @param {string} line - classique or moderne
   */
  #updateTrimOptions(line) {
    const trimSelect = document.getElementById('trim');
    // @ts-ignore
    const trim = trimSelect.value;
    // @ts-ignore
    const options = trimSelect.options;

    if (line === Line.moderne) {
      // Disable the brass and nickel options
      options[0].disabled = true;
      options[3].disabled = true;

      if (![Trim.brushedSS, Trim.chrome].includes(trim)) {
        // Select chrome
        options[2].selected = true;
      }
    } else if (line === Line.classique) {
      // Enable the brass and nickel options
      options[0].disabled = false;
      options[3].disabled = false;
    }
  }

  /**
   * Hide or show the optional burner choices and cupboard choices depending on
   * what a range has. Disable the 4 feux when not supported
   * @param {BaseRange} range
   */
  #showRangeOptions(range) {
    // @ts-ignore
    const rangeTopOptions = document.getElementById('top').options;
    const farLeftOptionBlock = document.getElementById('far-left-option-block');
    const leftOptionBlock = document.getElementById('left-option-block');
    const rightOptionBlock = document.getElementById('right-option-block');
    const farRightOptionBlock = document.getElementById(
      'far-right-option-block'
    );
    const leftOvenLabel = document.getElementById('left-oven-label');
    const leftOvenOptions = document.getElementById('left-oven');
    const rightOvenBlock = document.getElementById('right-oven-block');
    const rightOvenOptions = document.getElementById('right-oven');
    const leftCupboardBlock = document.getElementById('left-cupboard-block');
    const rightCupboardBlock = document.getElementById('right-cupboard-block');

    farLeftOptionBlock.style.display = range.features.farLeftOption
      ? 'block'
      : 'none';
    leftOptionBlock.style.display = range.features.leftOption
      ? 'block'
      : 'none';
    rightOptionBlock.style.display = range.features.rightOption
      ? 'block'
      : 'none';
    farRightOptionBlock.style.display = range.features.farRightOption
      ? 'block'
      : 'none';
    leftOvenLabel.innerText = range.features.rightOven ? 'Left Oven' : 'Oven';
    rightOvenBlock.style.display = range.features.rightOven ? 'block' : 'none';
    leftCupboardBlock.style.display = range.features.leftCupboard
      ? 'block'
      : 'none';
    rightCupboardBlock.style.display = range.features.rightCupboard
      ? 'block'
      : 'none';
    rangeTopOptions[1].style.display = range.features.traditional
      ? 'block'
      : 'none';
    rangeTopOptions[2].style.display = range.features.fourFeux
      ? 'block'
      : 'none';
    rangeTopOptions[3].style.display = range.features.classiqueRev
      ? 'block'
      : 'none';
    leftOvenOptions[0].style.display = range.features.noGasOven
      ? 'none'
      : 'block';
    leftOvenOptions[1].style.display = range.features.convection
      ? 'block'
      : 'none';
    leftOvenOptions[2].style.display = range.features.convection
      ? 'none'
      : 'block';
    rightOvenOptions[1].style.display = range.features.convection
      ? 'block'
      : 'none';
    rightOvenOptions[2].style.display = range.features.convection
      ? 'none'
      : 'block';

    // For one oven ranges, make electric (or convection) the default
    leftOvenOptions[1].selected =
      !range.features.rightOven && range.features.convection;
    leftOvenOptions[2].selected =
      !range.features.rightOven && !range.features.convection;

    // For two oven ranges, select right electric if not convection
    rightOvenOptions[2].selected =
      range.features.rightOven && !range.features.convection;

    // For "no gas" left ovens, select electric if not convection
    if (range.features.noGasOven && !range.features.convection) {
      leftOvenOptions[2].selected = true;
      rightOvenOptions[0].selected = true; // Select gas for right oven
    }
  }

  /**
   * Select the proper burner option for "assigned" optional burners
   * @param {BaseRange} range
   */
  #assignBurnerOptions(range) {
    const farLeftOption = document.getElementById('far-left-option');
    const leftOption = document.getElementById('left-option');

    switch (this.#state.model) {
      case Model.sully:
      case Model.sully1800left:
      case Model.sully1800right:
      case Model.sully2200:
        leftOption[2].selected = true; // Select the 15k BTU burners
        break;
      case Model.fontenay:
      case Model.citeaux:
        farLeftOption[2].selected = true; // Select the 15k BTU burners
        break;
    }
  }

  #showHoodOptions() {
    const hoodInsertDiv = document.getElementById('hood-insert-div');
    hoodInsertDiv.style.display = 'block';

    const ductCoverFinishDiv = document.getElementById('duct-cover-finish-div');
    ductCoverFinishDiv.style.display = 'block';

    const ductCoverHeightDiv = document.getElementById('duct-cover-height-div');
    ductCoverHeightDiv.style.display = 'block';
  }

  #hideHoodOptions() {
    const hoodInsertDiv = document.getElementById('hood-insert-div');
    hoodInsertDiv.style.display = 'none';

    const ductCoverFinishDiv = document.getElementById('duct-cover-finish-div');
    ductCoverFinishDiv.style.display = 'none';

    const ductCoverHeightDiv = document.getElementById('duct-cover-height-div');
    ductCoverHeightDiv.style.display = 'none';
  }

  #removeHood() {
    // @ts-ignore
    document.getElementById('hood').checked = false;
    this.#scene.remove(this.#scene.getObjectByName(this.#hood.hood.name));
    this.#hood = null;
    this.#camera.position.set(0, 1.5, 4.5);
    this.#scene.position.y = -0.5;
    this.#showing.hood = false;
    this.#hideHoodOptions();

    if (this.#showing.backsplash) {
      this.#camera.position.set(0, 1, 5);
      this.#scene.position.y = -0.9;
    } else {
      this.#camera.position.set(0, 1.5, 4.5);
      this.#scene.position.y = -0.5;
    }
  }

  #removeBacksplash() {
    // @ts-ignore
    document.getElementById('backsplash').checked = false;
    this.#scene.remove(
      this.#scene.getObjectByName(this.#backsplash.backsplash.name)
    );
    this.#backsplash = null;
    this.#showing.backsplash = false;
  }

  #updateModelSelection() {
    const model = document.getElementById('model');
    // @ts-ignore
    const modelOptions = model.options;
    // @ts-ignore
    const modelValue = model.value;

    if (modelValue === this.#state.model) return;

    switch (this.#state.model) {
      case Model.cormatin:
        modelOptions[0].selected = true;
        break;
      case Model.rully:
        modelOptions[1].selected = true;
        break;
      case Model.beaune:
        modelOptions[2].selected = true;
        break;
      case Model.bussy:
        modelOptions[3].selected = true;
        break;
      case Model.cluny:
        modelOptions[4].selected = true;
        break;
      case Model.chagny:
        modelOptions[5].selected = true;
        break;
      case Model.volnay:
        modelOptions[6].selected = true;
        break;
      case Model.vougeot:
        modelOptions[7].selected = true;
        break;
      case Model.chambertin:
        modelOptions[8].selected = true;
        break;
      case Model.chassagne:
        modelOptions[9].selected = true;
        break;
      case Model.savigny:
        modelOptions[10].selected = true;
        break;
      case Model.saulieu:
        modelOptions[11].selected = true;
        break;
      case Model.sully:
        modelOptions[12].selected = true;
        break;
      case Model.cluny1400left:
        modelOptions[13].selected = true;
        break;
      case Model.cluny1400right:
        modelOptions[14].selected = true;
        break;
      case Model.chagny1400left:
        modelOptions[15].selected = true;
        break;
      case Model.chagny1400right:
        modelOptions[16].selected = true;
        break;
      case Model.fontenay:
        modelOptions[17].selected = true;
        break;
      case Model.citeaux:
        modelOptions[18].selected = true;
        break;
      case Model.cluny1800:
        modelOptions[19].selected = true;
        break;
      case Model.chagny1800:
        modelOptions[20].selected = true;
        break;
      case Model.sully1800left:
        modelOptions[21].selected = true;
        break;
      case Model.sully1800right:
        modelOptions[22].selected = true;
        break;
      case Model.sully2200:
        modelOptions[23].selected = true;
        break;
    }
  }

  #updateLineSelection() {
    // @ts-ignore
    const line = document.getElementById('line').options;

    switch (this.#state.line) {
      case Line.classique:
        line[0].selected = true;
        break;
      case Line.moderne:
        line[1].selected = true;
        break;
    }
  }

  #updateRangeTopSelection() {
    // @ts-ignore
    const topOptions = document.getElementById('top').options;

    switch (this.#state.top) {
      case RangeTop.classique:
        topOptions[0].selected = true;
        break;
      case RangeTop.traditional:
        topOptions[1].selected = true;
        break;
      case RangeTop.fourFeux:
        topOptions[2].selected = true;
        break;
    }
  }

  #updateTrimSelection() {
    // @ts-ignore
    const trim = document.getElementById('trim').options;

    switch (this.#state.trim) {
      case Trim.brass:
        trim[0].selected = true;
        break;
      case Trim.brushedSS:
        trim[1].selected = true;
        break;
      case Trim.chrome:
        trim[2].selected = true;
        break;
      case Trim.nickel:
        trim[3].selected = true;
        break;
    }
  }

  #updateColorSelection() {
    // @ts-ignore
    const color = document.getElementById('color').options;

    switch (this.#state.color) {
      case Color.anthracite:
        color[0].selected = true;
        break;
      case Color.ardoise:
        color[1].selected = true;
        break;
      case Color.armor:
        color[2].selected = true;
        break;
      case Color.britishRacingGreen:
        color[3].selected = true;
        break;
      case Color.burgundyRed:
        color[4].selected = true;
        break;
      case Color.chantilly:
        color[5].selected = true;
        break;
      case Color.chocolat:
        color[6].selected = true;
        break;
      case Color.coralBlue:
        color[7].selected = true;
        break;
      case Color.delftBlue:
        color[8].selected = true;
        break;
      case Color.englishCreme:
        color[9].selected = true;
        break;
      case Color.faience:
        color[10].selected = true;
        break;
      case Color.frangipane:
        color[11].selected = true;
        break;
      case Color.frenchBlue:
        color[12].selected = true;
        break;
      case Color.griotte:
        color[13].selected = true;
        break;
      case Color.ivory:
        color[14].selected = true;
        break;
      case Color.mandarine:
        color[15].selected = true;
        break;
      case Color.marronGlace:
        color[16].selected = true;
        break;
      case Color.matteBlack:
        color[17].selected = true;
        break;
      case Color.olive:
        color[18].selected = true;
        break;
      case Color.plum:
        color[19].selected = true;
        break;
      case Color.portugueseBlue:
        color[20].selected = true;
        break;
      case Color.provenceYellow:
        color[21].selected = true;
        break;
      case Color.roseQuartz:
        color[22].selected = true;
        break;
      case Color.shinyBlack:
        color[23].selected = true;
        break;
      case Color.stainlessSteel:
        color[24].selected = true;
        break;
      case Color.terracotta:
        color[25].selected = true;
        break;
      case Color.tilleul:
        color[26].selected = true;
        break;
      case Color.vertSilice:
        color[27].selected = true;
        break;
      case Color.vertSologne:
        color[28].selected = true;
        break;
      case Color.white:
        color[29].selected = true;
        break;
    }
  }

  #updateFarLeftOptionSelection() {
    // @ts-ignore
    const farLeftOption = document.getElementById('far-left-option').options;

    if (
      this.#state.model === Model.chagny1400left ||
      this.#state.model === Model.chagny1800 ||
      this.#state.model === Model.chassagne ||
      this.#state.model === Model.citeaux ||
      this.#state.model === Model.cluny1400left ||
      this.#state.model === Model.cluny1800 ||
      this.#state.model === Model.fontenay ||
      this.#state.model === Model.saulieu ||
      this.#state.model === Model.sully1800left ||
      this.#state.model === Model.sully2200
    ) {
      switch (this.#state.option1) {
        case Burner.stainlessSteelWorkstation:
          farLeftOption[0].selected = true;
          break;
        case Burner.two11kBurners:
          farLeftOption[1].selected = true;
          break;
        case Burner.two15kBurners:
          farLeftOption[2].selected = true;
          break;
        case Burner.one18kBurner:
          farLeftOption[3].selected = true;
          break;
        case Burner.flameGrill:
          farLeftOption[4].selected = true;
          break;
        case Burner.electricPlancha:
          farLeftOption[5].selected = true;
          break;
        case Burner.traditionalSimmerPlate:
          farLeftOption[6].selected = true;
          break;
        case Burner.multiCooker:
          farLeftOption[7].selected = true;
          break;
        case Burner.twoInductionRings:
          farLeftOption[8].selected = true;
          break;
      }
    }
  }

  #updateLeftOptionSelection() {
    // @ts-ignore
    const leftOption = document.getElementById('left-option').options;
    let targetOption = null;

    if (
      this.#state.model === Model.sully ||
      this.#state.model === Model.sully1800right
    ) {
      targetOption = this.#state.option1;
    } else if (
      this.#state.model === Model.sully1800left ||
      this.#state.model === Model.sully2200
    ) {
      targetOption = this.#state.option2;
    }

    if (targetOption === null) return;

    switch (targetOption) {
      case Burner.stainlessSteelWorkstation:
        leftOption[0].selected = true;
        break;
      case Burner.two11kBurners:
        leftOption[1].selected = true;
        break;
      case Burner.two15kBurners:
        leftOption[2].selected = true;
        break;
      case Burner.one18kBurner:
        leftOption[3].selected = true;
        break;
      case Burner.electricPlancha:
        leftOption[4].selected = true;
        break;
      case Burner.twoInductionRings:
        leftOption[5].selected = true;
        break;
    }
  }

  #updateRightOptionSelection() {
    // @ts-ignore
    const rightOption = document.getElementById('right-option').options;
    let targetOption = null;

    if (
      this.#state.model === Model.sully ||
      this.#state.model === Model.sully1800right
    ) {
      targetOption = this.#state.option2;
    } else if (
      this.#state.model === Model.sully1800left ||
      this.#state.model === Model.sully2200
    ) {
      targetOption = this.#state.option3;
    }

    if (targetOption === null) return;

    switch (targetOption) {
      case Burner.stainlessSteelWorkstation:
        rightOption[0].selected = true;
        break;
      case Burner.two11kBurners:
        rightOption[1].selected = true;
        break;
      case Burner.two15kBurners:
        rightOption[2].selected = true;
        break;
      case Burner.one18kBurner:
        rightOption[3].selected = true;
        break;
      case Burner.electricPlancha:
        rightOption[4].selected = true;
        break;
      case Burner.traditionalSimmerPlate:
        rightOption[5].selected = true;
        break;
      case Burner.twoInductionRings:
        rightOption[6].selected = true;
        break;
    }
  }

  #updateFarRightOptionSelection() {
    // @ts-ignore
    const farRightOption = document.getElementById('far-right-option').options;
    let targetOption = null;

    if (
      this.#state.model === Model.chagny1400right ||
      this.#state.model === Model.chambertin ||
      this.#state.model === Model.cluny1400right ||
      this.#state.model === Model.savigny
    ) {
      targetOption = this.#state.option1;
    } else if (
      this.#state.model === Model.chagny1800 ||
      this.#state.model === Model.citeaux ||
      this.#state.model === Model.cluny1800 ||
      this.#state.model === Model.fontenay
    ) {
      targetOption = this.#state.option2;
    } else if (this.#state.model === Model.sully1800right) {
      targetOption = this.#state.option3;
    } else if (this.#state.model === Model.sully2200) {
      targetOption = this.#state.option4;
    }

    if (targetOption === null) return;

    switch (targetOption) {
      case Burner.stainlessSteelWorkstation:
        farRightOption[0].selected = true;
        break;
      case Burner.two11kBurners:
        farRightOption[1].selected = true;
        break;
      case Burner.two15kBurners:
        farRightOption[2].selected = true;
        break;
      case Burner.one18kBurner:
        farRightOption[3].selected = true;
        break;
      case Burner.flameGrill:
        farRightOption[4].selected = true;
        break;
      case Burner.electricPlancha:
        farRightOption[5].selected = true;
        break;
      case Burner.traditionalSimmerPlate:
        farRightOption[6].selected = true;
        break;
      case Burner.multiCooker:
        farRightOption[7].selected = true;
        break;
      case Burner.twoInductionRings:
        farRightOption[8].selected = true;
        break;
    }
  }

  #updateLeftOvenSelection() {
    // @ts-ignore
    const leftOven = document.getElementById('left-oven').options;

    switch (this.#state.leftOven) {
      case Oven.gas:
        leftOven[0].selected = true;
        break;
      case Oven.convection:
        leftOven[1].selected = true;
        break;
      case Oven.electric:
        leftOven[2].selected = true;
        break;
    }
  }

  #updateRightOvenSelection() {
    // @ts-ignore
    const rightOven = document.getElementById('right-oven').options;

    switch (this.#state.rightOven) {
      case Oven.gas:
        rightOven[0].selected = true;
        break;
      case Oven.convection:
        rightOven[1].selected = true;
        break;
      case Oven.electric:
        rightOven[2].selected = true;
        break;
    }
  }

  #updateLeftCupboardSelection() {
    // @ts-ignore
    const rightOven = document.getElementById('left-cupboard').options;

    switch (this.#state.leftCupboard) {
      case Cupboard.warming:
        rightOven[0].selected = true;
        break;
      case Cupboard.storage:
        rightOven[1].selected = true;
        break;
    }
  }

  #updateRightCupboardSelection() {
    // @ts-ignore
    const rightOven = document.getElementById('right-cupboard').options;

    switch (this.#state.rightCupboard) {
      case Cupboard.warming:
        rightOven[0].selected = true;
        break;
      case Cupboard.storage:
        rightOven[1].selected = true;
        break;
    }
  }

  #updateHeightSelection() {
    // @ts-ignore
    const height = document.getElementById('height').options;

    switch (this.#state.height) {
      case Height[36]:
        height[0].selected = true;
        break;
      case Height['36_58']:
        height[1].selected = true;
        break;
      case Height.notSure:
        height[2].selected = true;
        break;
      case Height['35_38']:
      case Height['37_316']:
      case Height['37_1316']:
      case Height['38_38']:
      case Height[39]:
      case Height['39_916']:
      case Height['40_316']:
      case Height.other:
        height[3].selected = true;
        break;
    }
  }

  #updateGasTypeSelection() {
    // @ts-ignore
    const gasType = document.getElementById('gas-type').options;

    switch (this.#state.gas) {
      case Gas.naturalGas:
        gasType[0].selected = true;
        break;
      case Gas.propane:
        gasType[1].selected = true;
        break;
      case Gas.notSure:
        gasType[2].selected = true;
        break;
    }
  }

  #updateBackSpacerSelection() {
    // @ts-ignore
    const backSpacer = document.getElementById('back-spacer').options;

    switch (this.#state.spacer) {
      case Spacer.raised:
        backSpacer[0].selected = true;
        break;
      case Spacer.flush:
        backSpacer[1].selected = true;
        break;
    }
  }

  #resetBurnerOptionSelections() {
    // @ts-ignore
    const farLeftOption = document.getElementById('far-left-option').options;
    // @ts-ignore
    const leftOption = document.getElementById('left-option').options;
    // @ts-ignore
    const rightOption = document.getElementById('right-option').options;
    // @ts-ignore
    const farRightOption = document.getElementById('far-right-option').options;

    farLeftOption[0].selected = true;
    leftOption[0].selected = true;
    rightOption[0].selected = true;
    farRightOption[0].selected = true;
  }

  #updateSummary() {
    const rangeModel = document.getElementById('range-model');
    const rangeLine = document.getElementById('range-line');
    const rangeTop = document.getElementById('range-top');
    const rangeModelNumber = document.getElementById('range-model-number');
    const rangeTrim = document.getElementById('range-trim');
    const rangeColor = document.getElementById('range-color');
    const rangeLeftCupboardItem = document.getElementById(
      'range-left-cupboard-item'
    );
    const rangeLeftCupboard = document.getElementById('range-left-cupboard');
    const rangePetiteOvenItem = document.getElementById(
      'range-petite-oven-item'
    );
    const rangePetiteOven = document.getElementById('range-petite-oven');
    const rangeLeftOvenItem = document.getElementById('range-left-oven-item');
    const rangeLeftOven = document.getElementById('range-left-oven');
    const rangeRightOvenItem = document.getElementById('range-right-oven-item');
    const rangeRightOven = document.getElementById('range-right-oven');
    const rangeRightCupboardItem = document.getElementById(
      'range-right-cupboard-item'
    );
    const rangeRightCupboard = document.getElementById('range-right-cupboard');
    const rangeStorageDrawersItem = document.getElementById(
      'range-storage-drawers-item'
    );
    const rangeStorageDrawers = document.getElementById(
      'range-storage-drawers'
    );
    const rangeStandardBurners = document.getElementById(
      'range-standard-burners'
    );
    const l1OptionBurnerItem = document.getElementById('l1-option-burner-item');
    const l1OptionBurner = document.getElementById('l1-option-burner');
    const l2OptionBurnerItem = document.getElementById('l2-option-burner-item');
    const l2OptionBurner = document.getElementById('l2-option-burner');
    const r1OptionBurnerItem = document.getElementById('r1-option-burner-item');
    const r1OptionBurner = document.getElementById('r1-option-burner');
    const r2OptionBurnerItem = document.getElementById('r2-option-burner-item');
    const r2OptionBurner = document.getElementById('r2-option-burner');
    const rangeGasType = document.getElementById('range-gas-type');
    const rangeHeight = document.getElementById('range-height');
    const rangeWidth = document.getElementById('range-width');
    const rangeAmps = document.getElementById('range-amps');
    const rangeWatts = document.getElementById('range-watts');
    const rangeBtusItem = document.getElementById('range-btus-item');
    const rangeBtus = document.getElementById('range-btus');

    rangeLeftCupboardItem.style.display = this.#state.leftCupboard
      ? 'flex'
      : 'none';
    rangePetiteOvenItem.style.display = this.#state.petiteOven
      ? 'flex'
      : 'none';
    rangeLeftOvenItem.style.display = this.#state.leftOven ? 'flex' : 'none';
    rangeRightOvenItem.style.display = this.#state.rightOven ? 'flex' : 'none';
    rangeRightCupboardItem.style.display = this.#state.rightCupboard
      ? 'flex'
      : 'none';
    rangeStorageDrawersItem.style.display =
      this.#state.storageDrawers > 0 ? 'flex' : 'none';
    l1OptionBurnerItem.style.display =
      this.#summary.l1Option() !== null ? 'flex' : 'none';
    l2OptionBurnerItem.style.display =
      this.#summary.l2Option() !== null ? 'flex' : 'none';
    r1OptionBurnerItem.style.display =
      this.#summary.r1Option() !== null ? 'flex' : 'none';
    r2OptionBurnerItem.style.display =
      this.#summary.r2Option() !== null ? 'flex' : 'none';
    rangeBtusItem.style.display =
      this.#summary.maxBtus() !== null ? 'flex' : 'none';

    rangeModel.innerText = this.#summary.model();
    rangeLine.innerText = this.#summary.line();
    rangeTop.innerText = this.#summary.top();
    rangeModelNumber.innerText = this.#summary.modelNumber();
    rangeTrim.innerText = this.#summary.trim();
    rangeColor.innerText = this.#summary.color();
    rangeLeftCupboard.innerText = this.#summary.leftCupboard();
    rangePetiteOven.innerText = this.#summary.petiteOven();
    rangeLeftOven.innerText = this.#summary.leftOven();
    rangeRightOven.innerText = this.#summary.rightOven();
    rangeRightCupboard.innerText = this.#summary.rightCupboard();
    rangeStorageDrawers.innerText = this.#summary.storageDrawers();
    rangeStandardBurners.innerText = this.#summary.standardBurners();
    l1OptionBurner.innerText = this.#summary.l1Option();
    l2OptionBurner.innerText = this.#summary.l2Option();
    r1OptionBurner.innerText = this.#summary.r1Option();
    r2OptionBurner.innerText = this.#summary.r2Option();
    rangeGasType.innerText = this.#summary.gasType();
    rangeHeight.innerText = this.#summary.height();
    rangeWidth.innerText = this.#summary.width();
    rangeAmps.innerText = this.#summary.maxAmps();
    rangeWatts.innerText = this.#summary.maxWatts();
    rangeBtus.innerText = this.#summary.maxBtus();
  }

  /**
   * Add a camera helper
   * @param {Camera} camera
   */
  #addCameraHelper(camera) {
    const helper = new CameraHelper(camera);
    this.#scene.add(helper);
  }

  /**
   * Add a directional light helper
   * @param {DirectionalLight} directionalLight
   */
  #addDirectionalLightHelper(directionalLight) {
    const directionalLightHelper = new DirectionalLightHelper(directionalLight);
    this.#scene.add(directionalLightHelper);
  }

  /**
   * Add a shadow helper to a light
   * @param {Light} light
   */
  #addShadowHelper(light) {
    const helper = new CameraHelper(light.shadow.camera);
    this.#scene.add(helper);
  }
}
