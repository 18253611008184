import { Group, Mesh, Object3D, Vector3 } from 'three';

import { Knobs } from './Knobs.js';
import { SSRangeTop } from './SSRangeTop.js';

import { BaseRange } from '../shared/BaseRange.js';
import { KnobModels } from '../shared/KnobModels.js';
import { RangeParts } from '../parts/RangeParts.js';
import { RangeTopOptions } from '../shared/RangeTopOptions.js';

import { AssetLoader } from '../../shared/AssetLoader.js';
import { Materials } from '../../shared/Materials.js';
import { LacancheLogo } from '../../shared/SharedParts.js';
import { State } from '../../shared/State.js';
import {
  Burner,
  LabelPosition,
  LabelWidth,
  MeshName,
  RangeTop,
  Region,
  OptionSpace,
  Oven,
} from '../../shared/Enums.js';

class Sully extends BaseRange {
  /** The range top option on the left of the range */
  #option1 = new Group();

  /** The range top option on the right of the range */
  #option2 = new Group();

  /**
   * A dynamic stainless steel range top that assembles the top based on the
   * base burners and selected options
   * @type {SSRangeTop}
   */
  #ssTop;

  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   * @param {RangeParts} rangeParts
   * @param {LacancheLogo} sharedParts
   * @param {RangeTopOptions} rangeTopOptions
   * @param {KnobModels} knobModels
   * @param {State} state
   */
  constructor(
    assets,
    materials,
    rangeParts,
    sharedParts,
    rangeTopOptions,
    knobModels,
    state
  ) {
    super(assets, materials, rangeParts, rangeTopOptions, state);

    this.state.leftCupboard = null;
    if (!this.state.isConfigured) {
      this.state.leftOven = Oven.gas;
      this.state.rightOven = Oven.convection;
    }
    this.state.rightCupboard = null;
    if (!this.state.isConfigured) {
      this.state.option1 = Burner.two15kBurners;
      this.state.option2 = Burner.stainlessSteelWorkstation;
    }
    this.state.option3 = null;
    this.state.option4 = null;
    this.state.storageDrawers = 2;

    this.classiqueTrim = rangeParts.sullyClassiqueTrim();
    this.moderneTrim = rangeParts.sullyModerneTrim();
    this.knobs = new Knobs(knobModels, this.range, this.state, this.materials);
    this.#loadModels();
    this.applyMaterials();
    this.setupShadows();

    const ssRangeTop = this.#createRangeTop(rangeTopOptions);
    const logo = this.#createLogo(sharedParts);

    this.range.add(
      this.baseBurners,
      this.#option1,
      this.#option2,
      ssRangeTop,
      logo
    );

    this.#loadFeatures();
    this.#setLabelPositions();
    this.addRangeLabels();
  }

  /**
   * Change the range top style to classique, traditional, or 4-feux
   * @param {string} rangeTop
   * @param {boolean} [assembleTop] Whether to assemble a new top and add it to the range
   */
  changeRangeTop(rangeTop, assembleTop = true) {
    this.baseBurners.clear();

    switch (rangeTop) {
      case RangeTop.classique: {
        this.add18KBurner(-0.367, this.baseBurners);
        this.add11And5KBurners(-0.011, this.baseBurners);
        this.knobs.threeFeuxBaseTop();
        this.labels.add3FeuxLabels([0, 1.05, 0]);
        break;
      }

      case RangeTop.traditional: {
        this.addTradPlate(-0.367, this.baseBurners);
        this.add11And5KBurners(-0.011, this.baseBurners);
        this.knobs.threeFeuxBaseTop();
        break;
      }

      case RangeTop.fourFeux:
        this.add11KBurners(-0.311, this.baseBurners);
        this.add15And5KBurners(0, this.baseBurners);
        this.addSully4FeuxGrateSpacerTabs(0, this.baseBurners);
        this.knobs.fourFeuxBaseTop();
        break;
    }

    if (assembleTop) {
      const oldRangeTop = this.range.getObjectByName(MeshName.rangeTop);
      this.range.remove(oldRangeTop);

      const newRangeTop = this.#ssTop.assembleSSRangeTop({
        fourFeux: rangeTop === RangeTop.fourFeux,
      });
      this.range.add(newRangeTop);

      this.#refreshRangeTop();
    }
  }

  /**
   * Change the one of the optional "burners" on the range top
   * @param {string} burnerOption
   * @param {string} optionSpace
   * @param {boolean} [assembleTop]
   */
  changeBurner(burnerOption, optionSpace, assembleTop = true) {
    const region = this.#knobRegion(optionSpace);
    let xOffset, burnerGroup;

    switch (optionSpace) {
      case OptionSpace.option1:
        xOffset = -0.717;
        burnerGroup = this.#option1;
        break;

      case OptionSpace.option2:
        xOffset = 0.34;
        burnerGroup = this.#option2;
        break;
    }

    burnerGroup.clear();

    if (
      this.state.top === RangeTop.fourFeux &&
      optionSpace === OptionSpace.option1
    ) {
      xOffset += 0.064;
    }

    switch (burnerOption) {
      case Burner.two11kBurners:
        this.add11KBurners(xOffset, burnerGroup);
        this.knobs.add2Burners(region);
        break;

      case Burner.two15kBurners:
        this.add15KBurners(xOffset, burnerGroup);
        this.knobs.add2Burners(region);
        break;

      case Burner.one18kBurner:
        this.add18KBurner(xOffset, burnerGroup);
        this.knobs.add1Burner(region);
        break;

      case Burner.flameGrill:
        this.addFlameGrill(xOffset, burnerGroup);
        this.knobs.addFlameGrill(region);
        break;

      case Burner.electricPlancha:
        this.addPlancha(xOffset, burnerGroup);
        this.knobs.addPlancha(region);
        break;

      case Burner.traditionalSimmerPlate:
        this.addTradPlate(xOffset, burnerGroup);
        this.knobs.add1Burner(region);
        break;

      case Burner.multiCooker:
        this.addMultiCooker(xOffset, burnerGroup);
        this.knobs.addMultiCooker(region);
        break;

      case Burner.twoInductionRings:
        this.addInductionRings(xOffset, burnerGroup);
        this.knobs.addInductionRings(region);
        break;

      case Burner.stainlessSteelWorkstation:
        this.knobs.addSSWorkstation(region);
        break;

      default:
        this.knobs.addSSWorkstation(region);
        break;
    }

    this.showCorrectOvenVent(burnerOption, region);

    if (assembleTop) {
      const oldRangeTop = this.range.getObjectByName(MeshName.rangeTop);
      this.range.remove(oldRangeTop);

      const newRangeTop = this.#ssTop.assembleSSRangeTop({
        [optionSpace]: burnerOption,
      });
      this.range.add(newRangeTop);
    }
  }

  /**
   * All the trim parts of the range that can be brass, brushed stainless steel, chrome, or nickel
   * @returns {Object3D[]}
   */
  trimParts() {
    return [
      this.classiqueTrim.getObjectByName(MeshName.towelBarleftSupport),
      this.classiqueTrim.getObjectByName(MeshName.towelBarCenterSupport),
      this.classiqueTrim.getObjectByName(MeshName.towelBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.leftOvenDoorBarLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.leftOvenDoorBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.rightOvenDoorBarLeftSupport),
      this.classiqueTrim.getObjectByName(MeshName.rightOvenDoorBarRightSupport),
      this.classiqueTrim.getObjectByName(MeshName.leftDrawerLeftPull),
      this.classiqueTrim.getObjectByName(MeshName.leftDrawerRightPull),
      this.classiqueTrim.getObjectByName(MeshName.rightDrawerLeftPull),
      this.classiqueTrim.getObjectByName(MeshName.rightDrawerRightPull),
      this.range.getObjectByName(MeshName.logoBorder),
    ];
  }

  /**
   * All the parts of the range that are porcelain enamel
   * @returns {Object3D[]}
   */
  colorParts() {
    return [
      this.range.getObjectByName(MeshName.leftOvenDoor),
      this.range.getObjectByName(MeshName.rightOvenDoor),
      this.range.getObjectByName(MeshName.leftStorageDrawer),
      this.range.getObjectByName(MeshName.rightStorageDrawer),
    ];
  }

  /**
   * All the parts of the range that are stainless steel
   * @returns {Object3D[]}
   */
  stainlessSteelParts() {
    return [
      this.range.getObjectByName(MeshName.backSpacer),
      this.range.getObjectByName(MeshName.toeKick),
      this.range.getObjectByName(MeshName.rangeBase),

      this.classiqueTrim.getObjectByName(MeshName.towelBar),
      this.classiqueTrim.getObjectByName(MeshName.classiqueAerationVent),

      this.moderneTrim.getObjectByName(MeshName.moderneAerationVent),
    ];
  }

  ssRangeTopParts() {
    return [this.range.getObjectByName(MeshName.rangeTopRim1400)];
  }

  /**
   * All the parts of the range that are galvanized steel
   * @returns {Object3D[]}
   */
  galvanizedSteelParts() {
    return [
      this.range.getObjectByName(MeshName.rangeBackPanel),

      // this.range.getObjectByName(MeshName.electricOvenBackPanel),
      // this.range.getObjectByName(MeshName.gasOvenBackPanel),
      // this.range.getObjectByName(MeshName.gasOvenChimneyBackPanel),
    ];
  }

  /**
   * All the parts of the range that receive shadows
   * @returns {Object3D[]}
   */
  shadowCasters() {
    return [this.classiqueTrim.getObjectByName(MeshName.towelBar)];
  }

  /**
   * All the parts of the range that receive shadows
   * @returns {Object3D[]}
   */
  shadowReceivers() {
    return [this.range.getObjectByName(MeshName.controlPanel)];
  }

  /** Set all the optional burners to what they are supposed to be */
  #refreshRangeTop() {
    this.changeOption1(this.state.option1, false);
    this.changeOption2(this.state.option2, false);
  }

  /** Add all the Sully meshes to the range Group  */
  #loadModels() {
    // All meshes from the main Sully model
    this.assets.range1400Data.scene.children.forEach((child) => {
      if (this.#classiqueMeshNames().includes(child.name)) {
        this.classiqueTrim.add(child.clone());
      } else if (this.#moderneMeshNames().includes(child.name)) {
        this.moderneTrim.add(child.clone());
      } else {
        this.range.add(child.clone());
      }
    });

    this.assets.sullyData.scene.children.forEach((child) => {
      this.range.add(child.clone());
    });

    this.range.add(this.classiqueTrim);
    this.range.add(...this.rangeParts.sullyParts());

    // Hide the range top rim. It is only needed to assemble the range top
    this.range.getObjectByName(MeshName.rangeTopRim1400).visible = false;
  }

  /**
   * Create a properly configured stainless steel range top
   * @param {RangeTopOptions} rangeTopOptions
   * @returns {Mesh}
   */
  #createRangeTop(rangeTopOptions) {
    this.#ssTop = new SSRangeTop(this.range, rangeTopOptions);

    // Configure optional burners for initial display
    this.#refreshRangeTop();

    return this.#ssTop.assembleSSRangeTop({
      fourFeux: false,
      option1: this.state.option1,
      option2: this.state.option2,
    });
  }

  /**
   * Create the Lacanche logo to show on the oven door
   * @param {LacancheLogo} sharedParts
   * @returns {Group}
   */
  #createLogo(sharedParts) {
    return sharedParts.logo.clone();
  }

  /**
   * 3D meshes in the base range that are only part of the classique line
   * @returns {string[]}
   */
  #classiqueMeshNames() {
    return [
      MeshName.classiqueAerationVent,
      MeshName.aerationVentBlack,
      MeshName.towelBar,
    ];
  }

  /**
   * 3D meshes in the base range that are only part of the moderne line
   * @returns {string[]}
   */
  #moderneMeshNames() {
    return [MeshName.moderneAerationVent];
  }

  /**
   * Map the burner option space to the correct region of the control panel
   * @param {string} optionSpace
   * @returns {string}
   */
  #knobRegion(optionSpace) {
    switch (optionSpace) {
      case OptionSpace.option1:
        return Region.left;

      case OptionSpace.option2:
        return Region.right;
    }
  }

  /** Set up the user interface to show all the range's configurable features */
  #loadFeatures() {
    this.features.leftOption = true;
    this.features.rightOption = true;
    this.features.rightOven = true;
    this.features.convection = true;
    this.features.fourFeux = true;
  }

  #setLabelPositions() {
    this.labels.config.leftOven = {
      position: [-0.35, 0.56, LabelPosition.frontZPos],
      width: LabelWidth.sullyOven,
    };
    this.labels.config.leftStorageDrawer = {
      position: [-0.35, LabelPosition.storeDrawerYPos, LabelPosition.frontZPos],
      width: LabelWidth.sullyOven,
    };
    this.labels.config.rightOven = {
      position: [0.35, 0.56, LabelPosition.frontZPos],
      width: LabelWidth.sullyOven,
    };
    this.labels.config.rightStorageDrawer = {
      position: [0.35, LabelPosition.storeDrawerYPos, LabelPosition.frontZPos],
      width: LabelWidth.sullyOven,
    };
  }
}

export { Sully };
