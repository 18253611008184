import {
  AmbientLight,
  DirectionalLight,
  HemisphereLight,
  Object3D,
  RectAreaLight,
} from 'three';
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper';

export function createLights() {
  const ambientLight = new AmbientLight('white', 1);

  const hemisphereLight = new HemisphereLight('white', 'white', 1);

  const frontLeftLight = new DirectionalLight('white', 1);
  frontLeftLight.position.set(-3, 3, 4);
  frontLeftLight.castShadow = true;
  frontLeftLight.shadow.mapSize.width = 1024;
  frontLeftLight.shadow.mapSize.height = 1024;
  frontLeftLight.shadow.camera.top = 2;
  frontLeftLight.shadow.camera.right = 2;
  frontLeftLight.shadow.camera.bottom = -2;
  frontLeftLight.shadow.camera.left = -2;
  frontLeftLight.shadow.camera.near = 3;
  frontLeftLight.shadow.camera.far = 7;

  const frontRightLight = new DirectionalLight('white', 1);
  frontRightLight.position.set(3, 3, 4);
  frontRightLight.castShadow = true;
  frontRightLight.shadow.mapSize.width = 1024;
  frontRightLight.shadow.mapSize.height = 1024;
  frontRightLight.shadow.camera.top = 2;
  frontRightLight.shadow.camera.right = 2;
  frontRightLight.shadow.camera.bottom = -2;
  frontRightLight.shadow.camera.left = -2;
  frontRightLight.shadow.camera.near = 3;
  frontRightLight.shadow.camera.far = 7;

  const topLeftLightTarget = new Object3D();
  topLeftLightTarget.position.set(-0.5, 0, 0);

  const topLeftLight = new DirectionalLight('#F4EEE0', 0);
  topLeftLight.position.set(-1, 4, 0);
  topLeftLight.target = topLeftLightTarget;

  const topCenterLight = new DirectionalLight('white', 0);
  topCenterLight.position.set(0, 4, 0);

  const topRightLight = new DirectionalLight('white', 0);
  topRightLight.position.set(0.75, 4, 0);

  return {
    ambientLight,
    frontLeftLight,
    frontRightLight,
    hemisphereLight,
    topLeftLight,
    topCenterLight,
    topRightLight,
  };
}
