import {
  RepeatWrapping,
  SRGBColorSpace,
  TextureLoader,
  Texture,
  Vector2,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

// @ts-ignore
import backImage from '/assets/textures/body_back_texture_final.webp?url';
// @ts-ignore
import blackTextureImage from '/assets/textures/black-plain-concrete-textured.jpg?url';
// @ts-ignore
import brassCapImage from '/assets/textures/texture_1.png?url';
// @ts-ignore
import cherryRedImage from '/assets/textures/cherry-red-lacanche-colour-sample.jpg?url';
// @ts-ignore
import nickelTextureImage from '/assets/textures/nickel-lacanche-sample1.jpg?url';
// @ts-ignore
import ssTextureImage from '/assets/textures/stainless-steel-lacanche-sample.jpg?url';
// @ts-ignore
import lacancheLogoTexture from '/assets/textures/lacanche-logo-plate.jpg?url';
// @ts-ignore
import lacancheLogoAOTexture from '/assets/textures/lacanche-logo-plate-ao.jpg?url';

export class AssetLoader {
  /** @typedef {import("three/examples/jsm/loaders/GLTFLoader.js").GLTF} GLTF */

  /***************        Range 3D Models        ***************/

  /** @type {Promise<GLTF>} */
  get beauneData() {
    return this.#load3DModel('/models/Beaune.glb');
  }

  /** @type {Promise<GLTF>} */
  get bussyData() {
    return this.#load3DModel('/models/Bussy.glb');
  }

  /** @type {Promise<GLTF>} */
  get chagnyData() {
    return this.#load3DModel('/models/Chagny.glb');
  }

  /** @type {Promise<GLTF>} */
  get chagny1400LeftData() {
    return this.#load3DModel('/models/Chagny_1400_left.glb');
  }

  /** @type {Promise<GLTF>} */
  get chagny1400RightData() {
    return this.#load3DModel('/models/Chagny_1400_right.glb');
  }

  /** @type {Promise<GLTF>} */
  get chagny1800Data() {
    return this.#load3DModel('/models/Chagny_1800.glb');
  }

  /** @type {Promise<GLTF>} */
  get chambertinData() {
    return this.#load3DModel('/models/Chambertin.glb');
  }

  /** @type {Promise<GLTF>} */
  get chassagneData() {
    return this.#load3DModel('/models/Chassagne.glb');
  }

  /** @type {Promise<GLTF>} */
  get clunyData() {
    return this.#load3DModel('/models/Cluny.glb');
  }

  /** @type {Promise<GLTF>} */
  get cluny1400LeftData() {
    return this.#load3DModel('/models/Cluny_1400_left.glb');
  }

  /** @type {Promise<GLTF>} */
  get cluny1400RightData() {
    return this.#load3DModel('/models/Cluny_1400_right.glb');
  }

  /** @type {Promise<GLTF>} */
  get cluny1800Data() {
    return this.#load3DModel('/models/Cluny_1800.glb');
  }

  /** @type {Promise<GLTF>} */
  get cormatinData() {
    return this.#load3DModel('/models/Cormatin.glb');
  }

  /** @type {Promise<GLTF>} */
  get fontenayData() {
    return this.#load3DModel('/models/Fontenay.glb');
  }

  /** @type {Promise<GLTF>} */
  get rullyData() {
    return this.#load3DModel('/models/Rully.glb');
  }

  /** @type {GLTF} */
  sullyData;

  /** @type {Promise<GLTF>} */
  get sully1800LeftData() {
    return this.#load3DModel('/models/Sully_1800_left.glb');
  }

  /** @type {Promise<GLTF>} */
  get sully1800RightData() {
    return this.#load3DModel('/models/Sully_1800_right.glb');
  }

  /** @type {Promise<GLTF>} */
  get sully2200Data() {
    return this.#load3DModel('/models/Sully_2200.glb');
  }

  /** @type {Promise<GLTF>} */
  get volnayData() {
    return this.#load3DModel('/models/Volnay.glb');
  }

  /** @type {Promise<GLTF>} */
  get vougeotData() {
    return this.#load3DModel('/models/Vougeot.glb');
  }

  /** @type {Promise<GLTF>} */
  get range700Data() {
    return this.#load3DModel('/models/Range_700.glb');
  }

  /** @type {Promise<GLTF>} */
  get range900Data() {
    return this.#load3DModel('/models/Range_900.glb');
  }

  /** @type {Promise<GLTF>} */
  get range1000Data() {
    return this.#load3DModel('/models/Range_1000.glb');
  }

  /** @type {Promise<GLTF>} */
  get range1100Data() {
    return this.#load3DModel('/models/Range_1100.glb');
  }

  /** @type {GLTF} */
  range1400Data;

  /** @type {Promise<GLTF>} */
  get range1800Data() {
    return this.#load3DModel('/models/Range_1800.glb');
  }

  /** @type {Promise<GLTF>} */
  get flameGrillData() {
    return this.#load3DModel('/models/Gas_grill.glb');
  }

  /** @type {Promise<GLTF>} */
  get fourFeuxSpacerData() {
    return this.#load3DModel('/models/4_feux_spacer.glb');
  }

  /** @type {Promise<GLTF>} */
  get inductionRingsData() {
    return this.#load3DModel('/models/Induction_rings.glb');
  }

  /** @type {Promise<GLTF>} */
  get multiCookerData() {
    return this.#load3DModel('/models/Multi_cooker.glb');
  }

  /** @type {Promise<GLTF>} */
  get planchaData() {
    return this.#load3DModel('/models/Electric_plancha_down.glb');
  }

  /** @type {Promise<GLTF>} */
  get traditionalPlateData() {
    return this.#load3DModel('/models/Simmer_plate.glb');
  }

  /***************           Hood 3D Models           ***************/

  /** @type {Promise<GLTF>} */
  get hood900Data() {
    return this.#load3DModel('/models/Hood_900.glb');
  }

  get hood1100Data() {
    return this.#load3DModel('/models/Hood_1100.glb');
  }

  /** @type {Promise<GLTF>} */
  get hood1200Data() {
    return this.#load3DModel('/models/Hood_1200.glb');
  }

  /** @type {Promise<GLTF>} */
  get hood1300Data() {
    return this.#load3DModel('/models/Hood_1300.glb');
  }

  /** @type {Promise<GLTF>} */
  get hood1600Data() {
    return this.#load3DModel('/models/Hood_1600.glb');
  }

  /** @type {Promise<GLTF>} */
  get hood1700Data() {
    return this.#load3DModel('/models/Hood_1700.glb');
  }

  /** @type {Promise<GLTF>} */
  get hood2000Data() {
    return this.#load3DModel('/models/Hood_2000.glb');
  }

  /** @type {Promise<GLTF>} */
  get hood2400Data() {
    return this.#load3DModel('/models/Hood_2400.glb');
  }

  /** @type {Promise<GLTF>} */
  get hoodInsertMAVData() {
    return this.#load3DModel('/models/Hood_insert_mav.glb');
  }

  /** @type {Promise<GLTF>} */
  get hoodInsertVAHData() {
    return this.#load3DModel('/models/Hood_insert_vah.glb');
  }

  /***************        Backsplash 3D Models        ***************/

  /** @type {Promise<GLTF>} */
  get backsplash700Data() {
    return this.#load3DModel('/models/Backsplash_700.glb');
  }

  /** @type {Promise<GLTF>} */
  get backsplash900Data() {
    return this.#load3DModel('/models/Backsplash_900.glb');
  }

  /** @type {Promise<GLTF>} */
  get backsplash1000Data() {
    return this.#load3DModel('/models/Backsplash_1000.glb');
  }

  /** @type {Promise<GLTF>} */
  get backsplash1100Data() {
    return this.#load3DModel('/models/Backsplash_1100.glb');
  }

  /** @type {Promise<GLTF>} */
  get backsplash1400Data() {
    return this.#load3DModel('/models/Backsplash_1400.glb');
  }

  /** @type {Promise<GLTF>} */
  get backsplash1500Data() {
    return this.#load3DModel('/models/Backsplash_1500.glb');
  }

  /** @type {Promise<GLTF>} */
  get backsplash1800Data() {
    return this.#load3DModel('/models/Backsplash_1800.glb');
  }

  /** @type {Promise<GLTF>} */
  get backsplash2200Data() {
    return this.#load3DModel('/models/Backsplash_2200.glb');
  }

  /** @type {Object.<string, GLTF|null>} */
  #cachedAssets = {};

  /** @type {GLTF} */
  allBurnersData;

  /** @type {GLTF} */
  controlPanelPartsData;

  /** @type {GLTF} */
  eighteenKBurnerGrateData;

  /** @type {GLTF} */
  logoData;

  /** @type {GLTF} */
  rangePartsData;

  /** @type {GLTF} */
  rangetopPiecesData;

  /** @type {GLTF} */
  twoBurnerGrateData;

  // Texture images

  /** @type {Texture} */
  backTexture;

  /** @type {Texture} */
  blackTexture;

  /** @type {Texture} */
  brassCapTexture;

  /** @type {Texture} */
  cherryRedTexture;

  /** @type {Texture} */
  nickelTexture;

  /** @type {Texture} */
  ssTexture;

  /** @type {Texture} */
  ssTextureVert;

  /** @type {Texture} */
  lacancheLogoTexture;

  /** @type {Texture} */
  lacancheLogoAOTexture;

  // Three.js loaders
  #gltfLoader;
  #textureLoader;

  constructor() {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco/');

    this.#gltfLoader = new GLTFLoader();
    this.#gltfLoader.setDRACOLoader(dracoLoader);

    this.#textureLoader = new TextureLoader();
  }

  async loadMinimum() {
    [
      // Base range 3D models
      this.sullyData,
      this.range1400Data,

      // Range components 3D models
      this.twoBurnerGrateData,
      this.eighteenKBurnerGrateData,
      this.allBurnersData,
      this.controlPanelPartsData,
      this.logoData,
      this.rangePartsData,
      this.rangetopPiecesData,

      // Texture images
      this.backTexture,
      this.blackTexture,
      this.brassCapTexture,
      // this.cherryRedTexture,
      this.nickelTexture,
      this.ssTexture,
      this.lacancheLogoTexture,
      this.lacancheLogoAOTexture,
    ] = await Promise.all([
      // 3D range files
      this.#gltfLoader.loadAsync('/models/Sully.glb'),
      this.#gltfLoader.loadAsync('/models/Range_1400.glb'),
      // 3D range component files
      this.#gltfLoader.loadAsync('/models/2_burner_grate.glb'),
      this.#gltfLoader.loadAsync('/models/18k_burner_grate.glb'),
      this.#gltfLoader.loadAsync('/models/Burners.glb'),
      this.#gltfLoader.loadAsync('/models/Control_panel_parts.glb'),
      this.#gltfLoader.loadAsync('/models/Logo.glb'),
      this.#gltfLoader.loadAsync('/models/Range_parts.glb'),
      this.#gltfLoader.loadAsync('/models/Rangetop_pieces.glb'),
      // Image files
      this.#textureLoader.loadAsync(backImage),
      this.#textureLoader.loadAsync(blackTextureImage),
      this.#textureLoader.loadAsync(brassCapImage),
      // this.#textureLoader.loadAsync(cherryRedImage),
      this.#textureLoader.loadAsync(nickelTextureImage),
      this.#textureLoader.loadAsync(ssTextureImage),
      this.#textureLoader.loadAsync(lacancheLogoTexture),
      this.#textureLoader.loadAsync(lacancheLogoAOTexture),
    ]);

    this.lacancheLogoTexture.colorSpace = SRGBColorSpace;

    this.#createVerticalSSTexture();
    this.#setTextureWrapping();
  }

  /**
   * Load the requested 3D model if it hasn't been loaded yet
   * @param {string} path
   * @returns {Promise<GLTF>}
   */
  async #load3DModel(path) {
    if (!this.#cachedAssets[path]) {
      this.#cachedAssets[path] = await this.#gltfLoader.loadAsync(path);
    }

    return this.#cachedAssets[path];
  }

  #createVerticalSSTexture() {
    this.ssTextureVert = this.ssTexture.clone();
    this.ssTextureVert.center = new Vector2(0.5, 0.5);
    this.ssTextureVert.rotation = Math.PI / 2;
  }

  #setTextureWrapping() {
    this.backTexture.repeat.set(1, 1);
    this.backTexture.wrapS = RepeatWrapping;
    this.backTexture.wrapT = RepeatWrapping;

    this.blackTexture.repeat.set(2, 2);
    this.blackTexture.wrapS = RepeatWrapping;
    this.blackTexture.wrapT = RepeatWrapping;

    this.brassCapTexture.repeat.set(1, 1);
    this.brassCapTexture.wrapS = RepeatWrapping;
    this.brassCapTexture.wrapT = RepeatWrapping;

    // this.cherryRedTexture.repeat.set(1, 1);
    // this.cherryRedTexture.wrapS = RepeatWrapping;
    // this.cherryRedTexture.wrapT = RepeatWrapping;

    this.ssTexture.repeat.set(2, 2);
    this.ssTexture.wrapS = RepeatWrapping;
    this.ssTexture.wrapT = RepeatWrapping;

    this.ssTextureVert.repeat.set(2, 2);
    this.ssTextureVert.wrapS = RepeatWrapping;
    this.ssTextureVert.wrapT = RepeatWrapping;
  }
}
