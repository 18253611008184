import {
  optionPlacements,
  stockBurners,
} from '../ranges/shared/burnerListings';

import {
  Burner,
  Color,
  Gas,
  Line,
  Model,
  OptionSpace,
  Oven,
  Placement,
  RangeTop,
  Trim,
} from './Enums';

import {
  ampsLookup,
  btusLookup,
  burnerLookup,
  colorLookup,
  cupboardLookup,
  gasLookup,
  heightLookup,
  lineLookup,
  modelLookup,
  ovenLookup,
  rangeTopLookup,
  trimLookup,
  widthLookup,
} from './lookups';

import { State } from './State';

export class Summary {
  /**
   * The current state of the world.
   * @type {State}
   */
  #state;

  /**
   * @param {State} state
   */
  constructor(state) {
    this.#state = state;
  }

  /**
   * The range model for the summary page.
   * @returns {string}
   */
  model() {
    return `Lacanche ${modelLookup[this.#state.model]}`;
  }

  /**
   * The range line for the summary page.
   * @returns {string}
   */
  line() {
    return `${lineLookup[this.#state.line]} line`;
  }

  /**
   * The range top style for the summary page.
   * @returns {string}
   */
  top() {
    return `${rangeTopLookup[this.#state.top]} range top`;
  }

  /**
   * The range model number for the summary page.
   * @returns {string}
   */
  modelNumber() {
    const line = this.#modelNumbers().line[this.#state.line];
    const top = this.#modelNumbers().top[this.#state.top];
    let leftOven = this.#modelNumbers().oven[this.#state.leftOven];
    let rightOven = this.#modelNumbers().oven[this.#state.rightOven];
    let modelPre = this.#modelNumbers().model[this.#state.model].pre;
    const modelPost = this.#modelNumbers().model[this.#state.model].post;
    let ovens;

    if (this.#state.top === RangeTop.fourFeux) {
      modelPre = modelPre.replace('3', '4');
    }

    if (!leftOven) leftOven = '';
    if (!rightOven) rightOven = '';
    ovens = leftOven + rightOven;
    ovens = ovens.replace('GG', 'G').replace('EE', 'E').replace('CTCT', 'CT');

    return 'Model number: ' + line + top + modelPre + ovens + modelPost;
  }

  /**
   * The range trim for the summary page.
   * @returns {string}
   */
  trim() {
    const isPremium = this.#state.trim !== Trim.brass;
    const trim = trimLookup[this.#state.trim];

    return `${trim} trim (${isPremium ? 'premium' : 'signature'} trim)`;
  }

  /**
   * The range color for the summary page.
   * @returns {string}
   */
  color() {
    const isPremium = this.#state.color !== Color.matteBlack;
    const isNotStainlessSteel = this.#state.color !== Color.stainlessSteel;
    const color = colorLookup[this.#state.color];

    return `${color}${isNotStainlessSteel ? ' porcelain enamel' : ''} (${
      isPremium ? 'premium' : 'signature'
    } color)`;
  }

  /**
   * Description of the left cupboard for the summary page.
   * @returns {string|undefined}
   */
  leftCupboard() {
    return cupboardLookup[this.#state.leftCupboard]?.replace('|SIDE|', 'Left');
  }

  petiteOven() {
    return `${this.#capitalizeFirstLetter(
      this.#state.petiteOven
    )} Broiler Oven`;
  }

  leftOven() {
    const hasBroiler = this.#state.leftOven !== Oven.gas;
    const hasTwoOvens = this.#state.leftOven && this.#state.rightOven;
    const oven = ovenLookup[this.#state.leftOven];

    return `${oven} ${hasTwoOvens ? 'Left' : ''} Oven (${
      hasBroiler ? 'with' : 'without'
    } broiler)`;
  }

  rightOven() {
    const hasBroiler = this.#state.rightOven !== Oven.gas;
    const oven = ovenLookup[this.#state.rightOven];

    return `${oven} Right Oven (${hasBroiler ? 'with' : 'without'} broiler)`;
  }

  /**
   * Description of the right cupboard for the summary page.
   * @returns {string|undefined}
   */
  rightCupboard() {
    return cupboardLookup[this.#state.rightCupboard]?.replace(
      '|SIDE|',
      'Right'
    );
  }

  /**
   * Description of the storage drawers for the summary page.
   * @returns {string}
   */
  storageDrawers() {
    return (
      `${this.#state.storageDrawers} Storage Drawer` +
      (this.#state.storageDrawers !== 1 ? 's' : '')
    );
  }

  /**
   * Description of the first optional burner on the left for the summary page.
   * @returns {?string}
   */
  l1Option() {
    return this.#optionalBurner(Placement.l1);
  }

  /**
   * Description of the second optional burner on the left for the summary page.
   * @returns {?string}
   */
  l2Option() {
    return this.#optionalBurner(Placement.l2);
  }

  /**
   * Description of the standard burner for the summary page.
   * @returns {string}
   */
  standardBurners() {
    const burners = stockBurners[this.#state.model][this.#state.top];
    const burnersText = burners.map((burner) => this.#burnerText(burner));

    return burnersText.join(', ');
  }

  /**
   * Description of the first optional burner on the right for the summary page.
   * @returns {?string}
   */
  r1Option() {
    return this.#optionalBurner(Placement.r1);
  }

  /**
   * Description of the second optional burner on the right for the summary page.
   * @returns {?string}
   */
  r2Option() {
    return this.#optionalBurner(Placement.r2);
  }

  /**
   * Range gas type for the summary page.
   * @returns {string}
   */
  gasType() {
    return gasLookup[this.#state.gas];
  }

  /**
   * Range height for the summary page.
   * @returns {string}
   */
  height() {
    return heightLookup[this.#state.height];
  }

  /**
   * Range width for the summary page.
   * @returns {string}
   */
  width() {
    return `${widthLookup[this.#state.model]} range width`;
  }

  /**
   * Maximum range amperage draw for the summary page.
   * @returns {string}
   */
  maxAmps() {
    return `${this.#amps()} amps (max. @ ${this.#volts()}VAC)`;
  }

  /**
   * Maximum range amperage draw for the summary page.
   * @returns {string}
   */
  maxWatts() {
    const watts = Math.round(this.#amps() * this.#volts());

    return `${watts} watts (max.)`;
  }

  /**
   * Maximum range BTU output for the summary page.
   * @returns {?string}
   */
  maxBtus() {
    if (this.#state.gas === Gas.notSure) return null;

    return `${this.#btus().toLocaleString()} BTU/hr (max.)`;
  }

  /**
   * Maximum range amperage draw.
   * @returns {number}
   */
  #amps() {
    let amps =
      ampsLookup[this.#state.leftCupboard] +
      ampsLookup[this.#state.petiteOven] +
      ampsLookup[this.#state.leftOven] +
      ampsLookup[this.#state.rightOven] +
      ampsLookup[this.#state.rightCupboard];

    // Check for electric rangetop options
    amps += ampsLookup[this.#state.option1];
    amps += ampsLookup[this.#state.option2];
    amps += ampsLookup[this.#state.option3];
    amps += ampsLookup[this.#state.option4];

    // For ranges with only gas, show a little bit of current draw
    if (amps === 0) amps = 0.1;

    // Round to 1 decimal place
    return Math.round(amps * 10) / 10;
  }

  /**
   * Maximum range BTU output.
   * @returns {number}
   */
  #btus() {
    const btusTable = btusLookup[this.#state.gas];
    const standardBurners = stockBurners[this.#state.model][this.#state.top];
    let btus = 0;

    standardBurners.forEach((burnerObj) => {
      for (const burner in burnerObj) {
        const qty = burnerObj[burner];
        btus += btusTable[burner] * qty;
      }
    });

    btus += btusTable[this.#state.leftOven];
    btus += btusTable[this.#state.rightOven];

    btus += btusTable[this.#state.option1];
    btus += btusTable[this.#state.option2];
    btus += btusTable[this.#state.option3];
    btus += btusTable[this.#state.option4];

    return btus;
  }

  /**
   * The range voltage.
   * @returns {number}
   */
  #volts() {
    return this.#amps() < 0.5 ? 120 : 240;
  }

  /**
   * Description of an optional burner for the summary page.
   * @param {string} burnerPlacement
   * @returns {?string}
   */
  #optionalBurner(burnerPlacement) {
    let option;

    for (const placement in optionPlacements[this.#state.model]) {
      if (placement === burnerPlacement) {
        option = optionPlacements[this.#state.model][placement];
        break;
      }
    }

    if (!option) return null;

    switch (option) {
      case OptionSpace.option1:
        return this.#burnerText(this.#state.option1) + ' [option]';
      case OptionSpace.option2:
        return this.#burnerText(this.#state.option2) + ' [option]';
      case OptionSpace.option3:
        return this.#burnerText(this.#state.option3) + ' [option]';
      case OptionSpace.option4:
        return this.#burnerText(this.#state.option4) + ' [option]';
      default:
        return 'I have no idea what is going on here';
    }
  }

  /**
   * The quantity and descriptive text for a given burner.
   * @param {object|string} top
   * @returns {string}
   */
  #burnerText(top) {
    let burner, qty;

    if (typeof top === 'object') {
      burner = Object.keys(top)[0];
      qty = Object.values(top)[0];
    } else {
      burner = top;
      qty = 0;
    }

    if (
      [
        Burner.two11kBurners,
        Burner.two15kBurners,
        Burner.twoInductionRings,
      ].includes(burner)
    ) {
      qty = 2;
    }

    return (
      (qty > 0 ? `${qty} - ` : '') + burnerLookup[burner] + (qty > 1 ? 's' : '')
    );
  }

  /**
   * @param {string} val
   * @returns {string}
   */
  #capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  #modelNumbers() {
    return {
      model: {
        [Model.beaune]: {
          pre: '962',
          post: '-D',
        },
        [Model.bussy]: {
          pre: '962',
          post: '-G',
        },
        [Model.chagny]: {
          pre: '1053',
          post: '',
        },
        [Model.chagny1400left]: {
          pre: '1453',
          post: '-G',
        },
        [Model.chagny1400right]: {
          pre: '1453',
          post: '-D',
        },
        [Model.chagny1800]: {
          pre: '1853',
          post: '-GD',
        },
        [Model.chambertin]: {
          pre: '1131',
          post: '-D',
        },
        [Model.chassagne]: {
          pre: '1131',
          post: '-G',
        },
        [Model.citeaux]: {
          pre: '1532',
          post: '-GD',
        },
        [Model.cluny]: {
          pre: '1052',
          post: '',
        },
        [Model.cluny1400left]: {
          pre: '1452',
          post: '-G',
        },
        [Model.cluny1400right]: {
          pre: '1452',
          post: '-D',
        },
        [Model.cluny1800]: {
          pre: '1852',
          post: '-GD',
        },
        [Model.cormatin]: {
          pre: '731',
          post: '',
        },
        [Model.fontenay]: {
          pre: '1531',
          post: '-GD',
        },
        [Model.rully]: {
          pre: '732',
          post: '',
        },
        [Model.saulieu]: {
          pre: '1132',
          post: '-G',
        },
        [Model.savigny]: {
          pre: '1132',
          post: '-D',
        },
        [Model.sully]: {
          pre: '1432',
          post: '',
        },
        [Model.sully1800left]: {
          pre: '1832',
          post: '-G',
        },
        [Model.sully1800right]: {
          pre: '1832',
          post: '-D',
        },
        [Model.sully2200]: {
          pre: '2232',
          post: '-GD',
        },
        [Model.volnay]: {
          pre: '1051',
          post: '-G',
        },
        [Model.vougeot]: {
          pre: '1051',
          post: '-D',
        },
      },

      top: {
        [RangeTop.classique]: 'ULG',
        [RangeTop.traditional]: 'ULCF',
        [RangeTop.fourFeux]: 'ULG',
        [RangeTop.classiqueRev]: 'RULG',
      },

      oven: {
        [Oven.gas]: 'G',
        [Oven.electric]: 'E',
        [Oven.convection]: 'CT',
      },

      line: {
        [Line.classique]: '',
        [Line.moderne]: 'M',
      },
    };
  }
}
