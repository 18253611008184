import { Object3D } from 'three';
import { AssetLoader } from '../shared/AssetLoader';
import { Materials } from '../shared/Materials';
import { LacancheLogo } from '../shared/SharedParts';
import { BaseHood } from './BaseHood';
import { MeshName } from '../shared/Enums';

export class Hood2000 extends BaseHood {
  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   * @param {LacancheLogo} sharedParts
   * @param {string} color
   */
  constructor(assets, materials, sharedParts, color) {
    super(assets, materials, sharedParts);
    this.bodyColor = color;
    this.hood.name = 'hood-2000';
  }

  async init() {
    await this.loadModels(this.assets.hood2000Data);
    this.applyMaterials();

    this.#addMAVBaffles();
    this.#addMAVLights();
    this.#addMAVKnob();

    this.addVAHLights();

    this.hideHighDuctCovers();
    this.changeColor(this.bodyColor);
  }

  #addMAVBaffles() {
    const offsetX = 0.1271875;

    // Add left baffle plates
    const mavBaffle = this.mavInsert.getObjectByName(MeshName.mavBaffleFilter);
    mavBaffle.translateX(-offsetX);

    for (let i = 1; i <= 2; i++) {
      const baffle = mavBaffle.clone();
      baffle.translateX(i * -0.254375);
      this.mavInsert.add(baffle);
    }

    // Add right baffle plates
    const rightBaffle = mavBaffle.clone();
    rightBaffle.position.x = offsetX;
    this.mavInsert.add(rightBaffle);

    for (let i = 1; i <= 2; i++) {
      const baffle = rightBaffle.clone();
      baffle.translateX(i * 0.254375);
      this.mavInsert.add(baffle);
    }
  }

  #addMAVLights() {
    // Add lights from left to right
    const mavLight = this.mavInsert.getObjectByName(MeshName.mavLedLens);
    mavLight.translateX(-0.7).translateZ(-0.003);

    for (let i = 1; i <= 3; i++) {
      const light = mavLight.clone();
      light.translateX(i * 0.4668);
      this.mavInsert.add(light);
    }
  }

  #addMAVKnob() {
    const offsetX = 0.07;
    const leftKnob = this.mavInsert.getObjectByName(MeshName.mavKnob);
    leftKnob.position.z += -0.003;
    leftKnob.position.x = -offsetX;

    const rightKnob = leftKnob.clone();
    rightKnob.position.x = offsetX;

    this.mavInsert.add(rightKnob);
  }
}
