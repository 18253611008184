import { Group, Object3D } from 'three';
import { AssetLoader } from '../shared/AssetLoader';
import { DuctCover, HoodInsert, MeshName } from '../shared/Enums';
import { Materials } from '../shared/Materials';
import { LacancheLogo } from '../shared/SharedParts';

export class BaseHood {
  /** @typedef {import("three/examples/jsm/loaders/GLTFLoader.js").GLTF} GLTF */

  /** All hood meshes should be added to (or removed from) this group */
  hood = new Group();

  /** The various parts of the Moderne-Aire Ventilation hood insert */
  mavInsert = new Group();

  /** The various parts of the Vent-A-Hood hood insert */
  vahInsert = new Group();

  /**
   * The 3D models for the hood
   * @type {AssetLoader}
   */
  assets;

  /** @type {Materials} */
  materials;

  /**
   * The selected hood color
   * @type {string}
   */
  bodyColor;

  /**
   * The duct cover finish is 'stainlessSteel' or 'color'
   * @type {string}
   */
  ductCoverFinish = DuctCover.stainlessSteel;

  /**
   * The Lacanche logo
   * @type {LacancheLogo}
   */
  #sharedParts;

  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   * @param {LacancheLogo} sharedParts
   */
  constructor(assets, materials, sharedParts) {
    this.assets = assets;
    this.materials = materials;
    this.#sharedParts = sharedParts;
    this.#addLogo();
  }

  /**
   * Load the hood and insert 3D models
   * @param {Promise<GLTF>} asyncHoodData
   */
  async loadModels(asyncHoodData) {
    const [hoodData, mavInsertData, vahInsertData] = await Promise.all([
      asyncHoodData,
      this.assets.hoodInsertMAVData,
      this.assets.hoodInsertVAHData,
    ]);

    hoodData.scene.children.forEach((child) => {
      if (child.name.startsWith('MAV')) {
        this.mavInsert.add(child.clone());
      } else if (child.name.startsWith('VAH')) {
        this.vahInsert.add(child.clone());
      } else {
        this.hood.add(child.clone());
      }
    });

    mavInsertData.scene.children.forEach((child) => {
      this.mavInsert.add(child.clone());
    });

    vahInsertData.scene.children.forEach((child) => {
      this.vahInsert.add(child.clone());
    });

    this.#addVahFanKnob();

    this.hood.add(this.mavInsert);
  }

  /**
   * Change the hood insert
   * @param {string} insert
   */
  changeInsert(insert) {
    if (insert === HoodInsert.modernAire) {
      this.hood.remove(this.vahInsert);
      this.hood.add(this.mavInsert);
    } else if (insert === HoodInsert.ventAHood) {
      this.hood.remove(this.mavInsert);
      this.hood.add(this.vahInsert);
    }
  }

  /**
   * Change the hood color
   * @param {string} color
   */
  changeColor(color) {
    this.bodyColor = color;
    this.materials.changeColor(color, [
      this.hood.getObjectByName(MeshName.hoodBody),
    ]);

    if (this.ductCoverFinish === DuctCover.color) {
      this.materials.changeColor(color, this.#allDuctCovers());
    }
  }

  /**
   * Change the duct cover finish
   * @param {string} finish
   */
  changeDuctCoverFinish(finish) {
    this.ductCoverFinish = finish;

    if (finish === DuctCover.color) {
      this.materials.changeColor(this.bodyColor, this.#allDuctCovers());
    } else {
      this.materials.applyStainlessSteelMaterial(...this.#allDuctCovers());
    }
  }

  /**
   * Change the duct cover height to 250mm, 500mm, 750mm, or 1000mm
   * @param {string} height
   */
  changeDuctCoverHeight(height) {
    const ductCover250 = this.hood.getObjectByName(MeshName.hoodDuctCover250);
    const ductCover500 = this.hood.getObjectByName(MeshName.hoodDuctCover500);
    const ductCover750 = this.hood.getObjectByName(MeshName.hoodDuctCover750);
    const ductCover1000 = this.hood.getObjectByName(MeshName.hoodDuctCover1000);

    ductCover250.visible = height === '250';
    ductCover500.visible = height === '500';
    ductCover750.visible = height === '750';
    ductCover1000.visible = height === '1000';
  }

  /** Apply the appropriate materials to the correct meshes */
  applyMaterials() {
    this.materials.applyStainlessSteelMaterial(...this.stainlessSteelParts());
    this.materials.applyChromeMaterial(...this.#chromeParts());
    this.materials.applyGalvanizedSteelMaterial(
      ...this.#galvanizedSteelParts()
    );
    this.materials.applyWhiteMaterial(...this.#whiteParts());
  }

  /**
   * All the parts of the hood that are stainless steel
   * @returns {Object3D[]}
   */
  stainlessSteelParts() {
    return [
      this.hood.getObjectByName(MeshName.hoodDuctCover250),
      this.hood.getObjectByName(MeshName.hoodDuctCover500),
      this.hood.getObjectByName(MeshName.hoodDuctCover750),
      this.hood.getObjectByName(MeshName.hoodDuctCover1000),
      this.hood.getObjectByName(MeshName.hoodLowerBeam),

      this.mavInsert.getObjectByName(MeshName.mavBaffleFilter),
      this.mavInsert.getObjectByName(MeshName.mavInsertHousing),

      this.vahInsert.getObjectByName(MeshName.vahDecoupeSpacer),
      this.vahInsert.getObjectByName(MeshName.vahFanShield),
      this.vahInsert.getObjectByName(MeshName.vahInsertHousing),
    ];
  }

  addVAHLights(offsetX = 0.35) {
    const vahLightBase = this.vahInsert.getObjectByName(MeshName.vahLightBase);
    const vahLightLens = this.vahInsert.getObjectByName(MeshName.vahLightLens);

    const leftLightBase = vahLightBase.clone();
    leftLightBase.translateX(-offsetX);
    const leftLightLens = vahLightLens.clone();
    leftLightLens.translateX(-offsetX);

    const rightLightBase = vahLightBase.clone();
    rightLightBase.translateX(offsetX);
    const rightLightLens = vahLightLens.clone();
    rightLightLens.translateX(offsetX);

    this.vahInsert.add(
      leftLightBase,
      leftLightLens,
      rightLightBase,
      rightLightLens
    );
  }

  hideHighDuctCovers() {
    this.hood.getObjectByName(MeshName.hoodDuctCover500).visible = false;
    this.hood.getObjectByName(MeshName.hoodDuctCover750).visible = false;
    this.hood.getObjectByName(MeshName.hoodDuctCover1000).visible = false;
  }

  /**
   * Finish initializing the hood
   * @abstract
   */
  async init() {
    // This should be implemented by subclasses that need to load 3D models asynchronously
  }

  /**
   * Create the Vent-A-Hood fan knob and add it to the Vent-A-Hood insert
   */
  #addVahFanKnob() {
    const offsetX = -0.25;
    const fanKnob = this.vahInsert
      .getObjectByName(MeshName.vahKnobLight)
      .clone()
      .translateX(offsetX);
    fanKnob.name = MeshName.vahKnobFan;
    const fanKnobBand = this.vahInsert
      .getObjectByName(MeshName.vahKnobBand)
      .clone()
      .translateX(offsetX);
    this.vahInsert.getObjectByName(MeshName.vahKnobFanIcon).translateX(offsetX);

    this.vahInsert.add(fanKnob, fanKnobBand);
  }

  /**
   * All the parts of the hood that are chrome
   * @returns {Object3D[]}
   */
  #chromeParts() {
    return [
      this.mavInsert.getObjectByName(MeshName.mavKnob),
      this.vahInsert.getObjectByName(MeshName.vahKnobFan),
      this.vahInsert.getObjectByName(MeshName.vahKnobLight),
      this.vahInsert.getObjectByName(MeshName.vahLightBase),
    ];
  }

  /**
   * All the parts of the hood that are galvanized steel
   * @returns {Object3D[]}
   */
  #galvanizedSteelParts() {
    return [this.hood.getObjectByName(MeshName.hoodBackPanel)];
  }

  /**
   * All the parts of the hood that are plastic white
   * @returns {Object3D[]}
   */
  #whiteParts() {
    return [
      this.mavInsert.getObjectByName(MeshName.mavLedLens),
      this.vahInsert.getObjectByName(MeshName.vahLightLens),
    ];
  }

  /** Add the Lacanche logo to the hood */
  #addLogo() {
    const logo = this.#sharedParts.logo.clone();
    logo.rotateX(-0.149);
    logo.translateX(-0.351).translateY(1.55).translateZ(0.25);
    this.hood.add(logo);
  }

  /**
   * All four duct covers (250mm, 500mm, 750mm, 1000mm)
   * @returns {Object3D[]}
   */
  #allDuctCovers() {
    return [
      this.hood.getObjectByName(MeshName.hoodDuctCover250),
      this.hood.getObjectByName(MeshName.hoodDuctCover500),
      this.hood.getObjectByName(MeshName.hoodDuctCover750),
      this.hood.getObjectByName(MeshName.hoodDuctCover1000),
    ];
  }
}
